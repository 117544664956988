import { Button } from 'antd';
import React, { useState } from 'react';
import classes from './PopoverButton.module.scss';

interface IPopoverButton {
  popoverText: string;
  icon?: React.ReactElement;
  classes?: string;
  popoverClasses?: string;
}

export const PopoverButton = (props: IPopoverButton) => {
  const [hide, setHide] = useState(true);
  return (
    <div className={classes.wrapper}>
      <Button
        onClick={() => setHide((state: boolean) => !state)}
        className={`${props.classes} ${classes.button}`}
        icon={props.icon}
      ></Button>
      <span className={`${classes.popover} ${!hide && classes.show}`}>{props.popoverText}</span>
    </div>
  );
};
