import { useState } from 'react';
import { ConfirmRemoveModal } from '../../../../../../../Components/ConfirmRemoveModal/ConfirmRemoveModal';
import { ProductListWrapper } from './ProductListWrapper/ProductListWrapper';
import { IListProducts, IListProduct } from '../Interfaces/IProductList';
import { AddProductsModal } from '../../../../../../../Components/AddProductsModal/AddProductsModal';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import ProductListItem from './ProductListItem/ProductListItem';

export const OptionalItemsTab = (props: IListProducts) => {
  const clearState: IListProduct = {
    name: '',
    id: 0,
  };

  const [confirmRemoveItem, setConfirmRemoveItem] = useState<IListProduct>(clearState);
  const [addProductsModalOpen, setAddProductsModalOpen] = useState(false);

  const removeItem = (id: number) => {
    props.onRemoveItem(id);
    setConfirmRemoveItem(clearState);
  };

  const onAddMultipleItems = (items: IListProduct[]) => {
    props.onAddMultipleItems(items);
    setAddProductsModalOpen(false);
  };

  const addToItemsList = (item: IListProduct) => {
    props.onAddItems(item);
  };

  const handleRLDDChange = (newOrderdArray: IListProduct[]) => {
    props.setNewProductList(newOrderdArray);
    props.changeOrder(newOrderdArray);
  };

  return (
    <>
      <AddProductsModal
        visible={addProductsModalOpen}
        onClose={() => setAddProductsModalOpen(false)}
        onConfirm={(items: IListProduct[]) => onAddMultipleItems(items)}
        selectedProducts={props.products ?? []}
      />
      <ProductListWrapper
        onAddProducts={() => setAddProductsModalOpen(true)}
        addToList={(items: IListProduct) => addToItemsList(items)}
      >
        {props.products && props.products.length > 0 && (
          <RLDD
            items={props.products}
            onChange={handleRLDDChange}
            itemRenderer={(item: IListProduct, index: number) => {
              return (
                <ProductListItem
                  showNumberOfItems={false}
                  item={item}
                  itemId={item.id}
                  itemIndex={index}
                  onRemove={(id, name) => setConfirmRemoveItem({ id, name })}
                />
              );
            }}
          />
        )}
      </ProductListWrapper>
      <ConfirmRemoveModal
        visible={confirmRemoveItem.id ? true : false}
        onConfirm={() => confirmRemoveItem.id && removeItem(confirmRemoveItem.id)}
        onClose={() => setConfirmRemoveItem(clearState)}
        itemName={confirmRemoveItem.name}
      />
    </>
  );
};
