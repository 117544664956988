import { Path, Svg } from '@react-pdf/renderer';

export const Logo = () => {
  return (
    <Svg width="110" height="30" viewBox="0 0 110 30">
      <Path d="M110 0H0V30H110V0Z" fill="#54565A" />
      <Path
        d="M14.1042 8.75293H17.9623V13.1149H21.5805V8.75293H25.4386V21.3023H21.5805V16.3365H17.9623V21.3023H14.1042V8.75293Z"
        fill="#FDC612"
      />
      <Path
        d="M30.2942 16.6765L25.8892 8.75293H30.1538L32.2422 13.3699L34.4155 8.75293H38.645L34.1522 16.6794V21.3023H30.2942V16.6765Z"
        fill="#FDC612"
      />
      <Path
        d="M42.9972 11.7254H44.8078C46.2849 11.7254 46.7266 12.1827 46.7266 12.9742C46.7266 14.0969 45.7408 14.22 45.0184 14.22H42.9972V11.7254ZM39.1304 21.3023H42.9972V16.9228H45.0447C46.5774 16.9228 46.6827 18.1715 46.7997 19.3675C46.8349 20.022 46.9408 20.6707 47.1156 21.3023H50.9678C50.6168 20.716 50.5992 19.2268 50.5466 18.6112C50.4062 17.0459 49.7598 15.885 48.7068 15.4805C49.9879 15.0056 50.5846 13.6337 50.5846 12.3351C50.5846 9.9636 48.6892 8.75293 46.4896 8.75293H39.1304V21.3023Z"
        fill="#FDC612"
      />
      <Path
        d="M52.4392 8.71777H56.2797L56.3119 10.7961H56.3587C56.7098 10.1843 57.2149 9.67548 57.8237 9.32054C58.4324 8.9656 59.1235 8.77697 59.8277 8.77347C61.3487 8.77347 62.7059 9.26595 63.3611 10.7492C64.3176 9.45649 65.5051 8.77347 67.1373 8.77347C70.9982 8.77347 71.4194 11.7049 71.4194 14.05V21.3023H67.4385V14.1526C67.4385 12.8393 66.8097 12.0654 65.7859 12.0654C64.0865 12.0654 63.9227 13.3787 63.9227 15.3457V21.3023H59.9447V14.3842C59.9447 12.9566 59.5264 12.0654 58.453 12.0654C57.0344 12.0654 56.4289 12.8862 56.4289 15.3721V21.3023H52.448L52.4392 8.71777Z"
        fill="white"
      />
      <Path
        d="M76.0498 17.823C76.0498 16.8557 76.7986 16.4805 77.6878 16.2665C78.577 16.0525 79.5861 15.9733 80.1477 15.6069C80.2413 18.1308 79.6095 19.2125 77.7112 19.2125C76.8337 19.2125 76.0498 18.8139 76.0498 17.823V17.823ZM76.2838 12.8748C76.471 11.8342 77.1613 11.3359 78.2728 11.3359C79.0947 11.3359 80.1945 11.6906 80.1945 12.6139C80.1945 13.3702 79.8201 13.6048 79.1415 13.7455C76.3774 14.3112 72.0688 14.0064 72.0688 17.9872C72.0688 20.4994 73.8677 21.4697 76.1843 21.4697C77.6468 21.4697 79.1883 21.2469 80.2179 20.1711C80.2224 20.5473 80.2735 20.9215 80.37 21.285H84.3362C83.8683 20.3206 83.9853 19.2037 83.9853 18.1719V12.8514C83.9853 9.40112 81.0369 8.71811 78.1587 8.71811C76.8712 8.68617 75.5974 8.98916 74.4615 9.59753C73.3851 10.2278 72.6363 11.2538 72.5661 12.8631L76.2838 12.8748Z"
        fill="white"
      />
      <Path
        d="M88.4547 14.9236L84.3216 8.71777H88.7472L90.701 12.0303L92.6403 8.71777H96.8816L92.8304 14.8532L97.136 21.3023H92.7368L90.6309 17.7846L88.6419 21.3023H84.2427L88.4547 14.9236Z"
        fill="white"
      />
      <Path
        d="M98.7593 10.3506H99.122C99.3501 10.3506 99.5198 10.3155 99.5198 10.0575C99.5198 9.79954 99.2858 9.80541 99.1015 9.80541H98.7593V10.3506ZM98.3585 9.49761H99.1249C99.6368 9.49761 99.9234 9.65884 99.9234 10.1015C99.929 10.1737 99.919 10.2464 99.8942 10.3145C99.8694 10.3826 99.8303 10.4445 99.7796 10.4962C99.7289 10.5479 99.6677 10.588 99.6002 10.614C99.5326 10.64 99.4603 10.6511 99.3881 10.6467L99.9322 11.5261H99.511L99.0137 10.6467H98.7593V11.5261H98.3585V9.49761ZM99.081 11.96C99.4604 11.96 99.8242 11.809 100.092 11.5401C100.361 11.2713 100.511 10.9067 100.511 10.5265C100.511 10.1464 100.361 9.78175 100.092 9.51292C99.8242 9.2441 99.4604 9.09307 99.081 9.09307C98.8962 9.09253 98.7132 9.12948 98.5431 9.2017C98.3729 9.27393 98.2191 9.37993 98.091 9.51332C97.9628 9.64671 97.8629 9.80473 97.7973 9.97786C97.7317 10.151 97.7017 10.3356 97.7092 10.5207C97.7013 10.7064 97.7309 10.8917 97.7963 11.0657C97.8616 11.2396 97.9613 11.3986 98.0894 11.533C98.2175 11.6674 98.3714 11.7745 98.5417 11.8479C98.7121 11.9212 98.8956 11.9594 99.081 11.96ZM99.081 8.7032C99.439 8.71243 99.7862 8.82731 100.079 9.03345C100.372 9.23958 100.598 9.52781 100.729 9.86203C100.859 10.1962 100.888 10.5616 100.812 10.9123C100.736 11.263 100.559 11.5834 100.302 11.8335C100.045 12.0836 99.7207 12.2522 99.3687 12.3182C99.0167 12.3842 98.6532 12.3446 98.3236 12.2044C97.9939 12.0642 97.7129 11.8297 97.5157 11.5302C97.3185 11.2307 97.2138 10.8795 97.2149 10.5207C97.2135 10.2771 97.2613 10.0359 97.3553 9.81127C97.4493 9.5867 97.5876 9.38345 97.7618 9.21373C97.9361 9.04401 98.1428 8.91131 98.3695 8.82358C98.5961 8.73585 98.8382 8.6949 99.081 8.7032"
        fill="white"
      />
    </Svg>
  );
};
