import { UserOutlined } from '@ant-design/icons/lib/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { headers } from '../../api';
import { AppConfig } from '../../config';
import classes from './Header.module.scss';
import { ReactComponent as Logo } from '../../logo-hyrmax.svg';

export const onLogout = () => {
  localStorage.removeItem('authenticated');
  window.sessionStorage.clear();

  fetch(`${AppConfig.API_URL}logout`, {
    method: 'POST',
    headers: headers,
    credentials: 'include',
  }).then();

  window.location.href = '/';
};

const goToWebPage = () => {
  window.location.href = '/';
};

const Logotype = () => (
  <li className={classes.logotypeContainer}>
    <Link to="/admin" className={classes.logotype}>
      <Logo />
    </Link>
  </li>
);

const Header = () => {
  const menu = (
    <Menu>
      <Menu.Item onClick={() => goToWebPage()}>Web Page</Menu.Item>
      <Menu.Item onClick={() => onLogout()}>Log out</Menu.Item>
    </Menu>
  );

  return (
    <div className={classes.header}>
      <Logotype />
      <Dropdown className="pointer" overlay={menu} placement="bottomCenter" trigger={['click']}>
        <Avatar size="large" icon={<UserOutlined />} />
      </Dropdown>
    </div>
  );
};

export default Header;
