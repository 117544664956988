import { DeleteOutlined } from '@ant-design/icons/lib/icons';
import { Button, Space, Table } from 'antd';
import { useState } from 'react';
import { ConfirmRemoveModal } from '../../../../../Components/ConfirmRemoveModal/ConfirmRemoveModal';
import { paths } from '../../../../../routes/paths';
import { IListProduct } from './ProductLists/Interfaces/IProductList';

export interface IConnectionTab {
  removeConnection: (removeConnection: IConnectionModalInformation) => void;
  tableData: IConnectionProduct[];
  loading: boolean;
}
export interface IConnectionProduct extends IListProduct {
  connectionType: string;
  connectionUrl: string;
}

export interface IProductInformation {
  productId: number;
  connectionUrl: string;
  productName: string;
}

export interface IConnectionModalInformation {
  productId: number;
  connectionUrl: string;
}

const ConnectionTab = (props: IConnectionTab) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [productInfo, setProductInfo] = useState<IProductInformation>({
    productId: 0,
    connectionUrl: '',
    productName: '',
  });

  const handleDelete = (id: number, connectionUrl: string, productName: string) => {
    setProductInfo({
      productId: id,
      connectionUrl: connectionUrl,
      productName: productName,
    });
    setShowConfirmModal(true);
  };

  const onClose = () => {
    setProductInfo({
      productId: 0,
      connectionUrl: '',
      productName: '',
    });
    setShowConfirmModal(false);
  };

  const onConfirm = (connectedProductId: number, connectionURL: string) => {
    let obj: IConnectionModalInformation = {
      productId: connectedProductId,
      connectionUrl: connectionURL,
    };

    props.removeConnection(obj);
    setShowConfirmModal(false);
  };

  const columns = [
    {
      title: 'Connection type',
      dataIndex: 'connectionType',
      key: 'connectionType',
    },
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => <a href={paths.adminProductBase + record.id}>{record.name}</a>,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right' as 'right',
      render: (text: string, record: any) => (
        <span>
          <Space align="start">
            <Button
              type="default"
              danger
              onClick={() => handleDelete(record.id, record.connectionUrl, record.name)}
              icon={<DeleteOutlined />}
            >
              Remove connection
            </Button>
          </Space>
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="padding-x padding-top">
        <h3>These are all the products that this product is connected to.</h3>
      </div>
      <div className="padding-x padding-top">
        <Table columns={columns} dataSource={props.tableData} loading={props.loading} rowKey="name" />
      </div>
      <ConfirmRemoveModal
        visible={showConfirmModal}
        itemName={productInfo.productName}
        onConfirm={() => onConfirm(productInfo.productId, productInfo.connectionUrl)}
        onClose={() => onClose()}
      />
    </>
  );
};

export default ConnectionTab;
