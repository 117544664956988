import { headers, headersFiles } from '../../../api';
import { onLogout } from '../../../Components/Header/Header';
import { AppConfig } from '../../../config';
import fetchSendError from '../../../Utils/fetchSendError';

export const removeCarouselItem = (id: number, refetchFunction: () => void) => {
  const description = 'remove a carousel item';
  fetch(`${AppConfig.API_URL}carousel/${id}`, {
    method: 'DELETE',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      res.json();
    })
    .then((res) => {
      refetchFunction();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};
export const addCarouselItem = async (page: string, link: string, image: FormData) => {
  let encodedLink = encodeURI(link);
  const description = 'add a carousel item';
  return fetch(`${AppConfig.API_URL}carousel?page=${page}&link=${encodedLink}`, {
    method: 'POST',
    credentials: 'include',
    body: image,
    headers: headersFiles,
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      } else return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};
export const moveCarouselItem = (id: number, afterId: number, page: string) => {
  const description = 'move a carousel item';
  fetch(`${AppConfig.API_URL}carousel-move?id=${id}&afterId=${afterId}&page=${page}`, {
    method: 'PUT',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      res.json();
    })
    .then((res) => {})
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};
