import { View, Text, StyleSheet } from '@react-pdf/renderer';

export const PDFFooter = () => {
  const styles = StyleSheet.create({
    root: {
      padding: '0px 24px 0px 24px',
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1pt solid #D9D9D9',
      fontSize: '8pt',
      justifyContent: 'space-between',
    },
    ghostDate: {
      color: 'white',
    },
  });
  return (
    <View style={styles.root} fixed>
      <Text style={styles.ghostDate}>{new Date().toLocaleDateString('sv-SV').replaceAll('/', '-')}</Text>
      <Text render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
      <Text>{new Date().toLocaleDateString('sv-SV').replaceAll('/', '-')}</Text>
    </View>
  );
};
