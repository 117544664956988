import { useEffect, useState } from 'react';

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const CurrentWidth = () => {
  const [width, setWidth] = useState(getWidth);

  useEffect(() => {
    let timeoutId: any = null;
    const resizeListner = () => {
      window.clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    window.addEventListener('resize', resizeListner);

    return () => {};
  }, []);

  return width;
};

export default CurrentWidth;
