import { Modal } from 'antd';

interface IConfirmPublishModal {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  name?: string;
  unpublish?: boolean;
}

export const ConfirmPublishModal = (props: IConfirmPublishModal) => {
  return props.unpublish ? (
    <Modal
      title="Unpublish"
      visible={props.visible}
      onOk={props.onConfirm}
      onCancel={props.onCancel}
      okText="Unpublish"
      okButtonProps={{ type: 'primary', danger: true }}
    >
      <p>Are you sure you want to unpublish {props.name}?</p>
    </Modal>
  ) : (
    <Modal title="Publish" visible={props.visible} onOk={props.onConfirm} onCancel={props.onCancel} okText="Publish">
      <p>Are you sure you want to publish {props.name}?</p>
    </Modal>
  );
};
