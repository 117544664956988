import classes from './Footer.module.scss';
import { ReactComponent as MainLogo } from '../../../../logo-hyrmax.svg';
import { ReactComponent as SloganLogo } from '../../../../logo-slogan.svg';
import { IconButton } from '../IconButton/IconButton';
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo151 } from '../../../../assets/151-logo.svg';
import logoBudwheels from '../../../../assets/budwheels-logo.png';
import { paths } from '../../../../routes/paths';

const Logotype = () => (
  <div className={classes.logotypeContainer}>
    <MainLogo className={classes.logotype} />
    <SloganLogo className={classes.slogan} />
  </div>
);

export const Footer = () => {
  const data = [
    {
      name: 'Film Equipment',
      id: 50,
    },
    {
      name: 'Audiovisual',
      id: 1,
    },
  ];
  return (
    <footer className={classes.root}>
      <div className={classes.logosContainer}>
        <Logotype />
        <p>&copy; HyrMax Rental AB</p>
      </div>
      <div className={classes.deliveryPartners}>
        <p>24/7 Delivery Partners</p>
        <div className={classes.partnerWrapper}>
          <a href="http://budwheels.com/" target="_blank" rel="noreferrer">
            <img src={logoBudwheels} alt="Budwheels" className={classes.partner} />
          </a>
          <a href="https://www.151transport.se/" target="_blank" rel="noreferrer">
            <Logo151 className={classes.partnerSmall} />
          </a>
        </div>
      </div>
      <div className={classes.linksContainer}>
        <div className={classes.iconLinksContainer}>
          <IconButton icon={<PhoneIcon />} href="tel:+4631212121">
            031 - 21 21 21
          </IconButton>
          <IconButton icon={<InstagramIcon />} href="https://www.instagram.com/hyrmax/">
            @hyrmax
          </IconButton>
        </div>
        <div className={classes.linkList}>
          <h2>Products</h2>
          <nav className={classes.navList}>
            {data.map((x: any) => {
              return <Link to={`${paths.categoryBase}${x.id}`}>{x.name}</Link>;
            })}
          </nav>
        </div>
        <div className={classes.linkList}>
          <h2>Hyrmax</h2>
          <nav className={classes.navList}>
            <Link to={paths.contact}>Contact</Link>
            <Link to={paths.faq}>Faq</Link>
          </nav>
        </div>
      </div>
    </footer>
  );
};
