import React, { createContext, useEffect, useState } from 'react';
import { ICart } from '../../Pages/Shop/Pages/Cart/CartComponent';
import { emptyCart } from '../../Pages/Shop/Pages/Cart/Other/empty-cart';
import { cartKeyWords } from '../../Pages/Shop/Pages/Cart/Other/keywords';

export type CartContextType = {
  cart: ICart;
  setCart: (Cart: ICart) => void;
  itemsInCart: number;
  clearCart: () => void;
};

export const CartContext = createContext<CartContextType | null>(null);

export interface ICartContextProvider {
  children: JSX.Element;
}

export const CartContextProvider = (props: ICartContextProvider) => {
  const cartFromStorage = localStorage.getItem(cartKeyWords.localStorageKey);

  let currentCart;
  if (cartFromStorage) currentCart = JSON.parse(cartFromStorage);

  // Set cart to a empty object if no cart is available
  const [cart, setCart] = useState<ICart>(currentCart ?? emptyCart);

  const [itemsInCart, setItemsInCart] = useState(0);

  useEffect(() => {
    const getNbrOfItemsInCart = () => {
      if (!cart) return 0;
      let items = 0;

      cart.cartGroups.forEach((group) => {
        group.products.forEach((product) => (items += product.numberOfItems ? product.numberOfItems : 0));
      });
      cart.ungroupedProducts.forEach((product) => (items += product.numberOfItems ? product.numberOfItems : 0));

      return items;
    };

    setItemsInCart(getNbrOfItemsInCart());
  }, [cart]);

  const clearCart = () => {
    setCart(emptyCart);
    localStorage.removeItem(cartKeyWords.localStorageKey);
  };

  return (
    <CartContext.Provider value={{ cart, setCart, itemsInCart, clearCart }}>{props.children}</CartContext.Provider>
  );
};
