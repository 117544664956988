import { createContext, Key, useReducer } from 'react';

export enum DrawingAreaTypes {
  UpdateLeft = 'UPDATE_LEFT',
  UpdateRight = 'UPDATE_RIGHT',
  UpdateBoth = 'UPDATE_BOTH',
  UpdateAVKeys = 'UPDATE_AV_KEYS',
  UpdateFilmKeys = 'UPDATE_FILM_KEYS',
  UpdateSelectedTab = 'UPDATE_SELECTED_TAB',
}

type DrawingAreaStateType = {
  left: boolean;
  right: boolean;
  av: {
    selectedKeys: Key[];
    expandedKeys: Key[];
  };
  film: {
    selectedKeys: Key[];
    expandedKeys: Key[];
  };
  selectedTab?: string;
};

const initialState: DrawingAreaStateType = {
  left: false,
  right: false,
  av: {
    selectedKeys: [0],
    expandedKeys: [0],
  },
  film: {
    selectedKeys: [0],
    expandedKeys: [0],
  },
};

const DrawingAreaContext = createContext<{
  state: DrawingAreaStateType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

interface DrawingAreaAction {
  type: DrawingAreaTypes;
  payload: boolean | { expandedKeys: Key[]; selectedKeys: Key[] } | number;
}

function drawingAreaReducer(state: DrawingAreaStateType, action: DrawingAreaAction) {
  switch (action.type) {
    case DrawingAreaTypes.UpdateLeft:
      if (typeof action.payload === 'boolean') {
        return { ...state, left: action.payload };
      } else return state;
    case DrawingAreaTypes.UpdateRight:
      if (typeof action.payload === 'boolean') {
        return { ...state, right: action.payload };
      } else return state;
    case DrawingAreaTypes.UpdateBoth:
      if (typeof action.payload === 'boolean') {
        return { ...state, right: action.payload, left: action.payload };
      } else return state;
    case DrawingAreaTypes.UpdateAVKeys:
      if (typeof action.payload === 'object') {
        return {
          ...state,
          av: { selectedKeys: action.payload.selectedKeys, expandedKeys: action.payload.expandedKeys },
          //film: { selectedKeys: [0], expandedKeys: [0] },
        };
      } else return state;
    case DrawingAreaTypes.UpdateFilmKeys:
      if (typeof action.payload === 'object') {
        return {
          ...state,
          //av: { selectedKeys: [0], expandedKeys: [0] },
          film: {
            selectedKeys: action.payload.selectedKeys,
            expandedKeys: action.payload.expandedKeys,
          },
        };
      } else return state;
    case DrawingAreaTypes.UpdateSelectedTab:
      if (typeof action.payload === 'string') {
        return {
          ...state,
          selectedTab: action.payload,
        };
      } else return state;
    default:
      return state;
  }
}

const DrawingAreaProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(drawingAreaReducer, initialState);

  return <DrawingAreaContext.Provider value={{ state, dispatch }}>{children}</DrawingAreaContext.Provider>;
};

export { DrawingAreaContext, DrawingAreaProvider };
