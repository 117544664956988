import { Col, Divider, Row, Spin } from 'antd';
import { useState } from 'react';
import DocumentUpload, { IDocument } from '../../../../../Components/DocumentUpload/DocumentUpload';
import { ConfirmRemoveModal } from '../../../../../Components/ConfirmRemoveModal/ConfirmRemoveModal';
import { DocumentListItem } from '../../../../../Components/DocumentListItem/DocumentListItem';
import classes from './DocumentsTab.module.scss';

interface IDocumentTab {
  documents: IDocument[];
  removeDocument: (ids: number) => void;
  documentFileList: File[];
  setDocumentFileList: (list: File[]) => void;
  reloadDocumentsList: () => void;
  isDocumentsLoading: boolean;
}

const DocumentsTab = (props: IDocumentTab) => {
  const handleDocumentId = (id: number) => {
    props.removeDocument(id);
  };

  // Modal
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [productItem, setProductItem] = useState<IDocument>();

  const showModal = (productInfo: IDocument) => {
    setIsDeleteModalVisible(true);
    setProductItem(productInfo);
  };

  const handleOk = () => {
    if (productItem) {
      handleDocumentId(productItem.id);
      setIsDeleteModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsDeleteModalVisible(false);
  };

  return (
    <>
      <div className="padding-x">
        <DocumentUpload
          documentFileList={props.documentFileList}
          setDocumentFileList={(list: File[]) => props.setDocumentFileList(list)}
        />
        <Row className={classes.tableHeader}>
          <Col span={8}>Document Name</Col>
          <Col span={8}>File</Col>
        </Row>
        <Divider className={classes.divider} />
        {props.isDocumentsLoading ? (
          <Spin className={classes.spinner} />
        ) : (
          props.documents.map((document) => (
            <DocumentListItem item={document} onRemove={showModal} reloadDocumentsList={props.reloadDocumentsList} />
          ))
        )}
        <ConfirmRemoveModal
          visible={isDeleteModalVisible}
          onConfirm={handleOk}
          onClose={handleCancel}
          itemName={productItem?.name ?? ''}
        />
      </div>
    </>
  );
};

export default DocumentsTab;
