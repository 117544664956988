import { ICart, ICartGroup, IProductInCart} from '../CartComponent';

export const getCartWithMovedProductToGrouped = (cart: ICart, product: IProductInCart, target: string) => {
  // Create new array of cart group
  const updatedCartGroups: ICartGroup[] = cart.cartGroups.map((cartGroup) => {
    return {
      ...cartGroup,
      // Add product to array if the cartGroupName is the same as your target
      products: cartGroup.cartGroupName === target ? [...cartGroup.products, product] : cartGroup.products,
    };
  });

  const cartWithMovedProductToGrouped: ICart = {
    ...cart,
    cartGroups: updatedCartGroups,
  };

  return cartWithMovedProductToGrouped;
};

export const getCartWithMovedProductToUngrouped = (cart: ICart, product: IProductInCart) => {
  // Push the product into ungrouped
  let updateUngroupedProducts: IProductInCart[] = cart.ungroupedProducts;
  updateUngroupedProducts.push(product);

  const updatedCart: ICart = {
    ...cart,
    ungroupedProducts: updateUngroupedProducts,
  };

  return updatedCart;
};
