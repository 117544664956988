export const setDynamicAttr = (obj: any, list: string, property: string, value: any) => {
    if (typeof obj === 'object' && obj.hasOwnProperty(list)) {
      const products = Object.keys(obj[list]).map((product: any) => {
          product[property] = value;
        return { ...product, mainProduct: true };
      });

      if (products) obj[list] = Object.assign(products);
    }

    if (obj.hasOwnProperty('props')) {
      obj.props.children.map((item: any) => {
        return setDynamicAttr(item, list, property, value);
      });
    }
    return obj;
  };