import { Page, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { PDFCustomerInformation } from './Components/PDFCustomerInformation';
import { PDFEquipmentList } from './Components/PDFEquipmentList';
import { PDFFooter } from './Components/PDFFooter';
import { PDFHeader } from './Components/PDFHeader';
import { PDFRentalInformation } from './Components/PDFRentalInformation';

export const CreatePDFComponent = (props: any) => {
  Font.register({
    family: 'Rubik',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/rubik/v19/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UA.ttf',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
      {
        src: 'https://fonts.gstatic.com/s/rubik/v19/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UA.ttf',
        fontStyle: 'normal',
        fontWeight: 'bold',
      },
      {
        src: 'https://fonts.gstatic.com/s/rubik/v19/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1UA.ttf',
        fontStyle: 'normal',
        fontWeight: 'light',
      },
      {
        src: 'https://fonts.gstatic.com/s/rubik/v19/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-2Y-1UA.ttf',
        fontStyle: 'normal',
        fontWeight: 'semibold',
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#FFFFFF',
      padding: '24px',
      fontFamily: 'Rubik',
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PDFHeader />
        <PDFCustomerInformation customerInfo={props.pdfData.customerInformation} />
        <PDFRentalInformation rentalInformation={props.pdfData.rentalInformation} />
        <PDFEquipmentList cart={props.cart} />
        <PDFFooter />
      </Page>
    </Document>
  );
};
