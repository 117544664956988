import { message } from 'antd';
import { ICart, ICartGroup } from '../CartComponent';

export const getCartWithNewGroup = (name: string, cart: ICart) => {
  // If the name of the group already exits, return and show a error message
  if (cart.cartGroups.some((x) => x.cartGroupName === name)) {
    message.error('This name already exits as a group');
    return;
  }

  // If the user is trying to submit a group without a name
  if (name === '' || name === undefined) {
    message.error('Please add at least one character');
    return;
  }

  // Generate a new group with a generated id
  const newGroup: ICartGroup = {
    cartGroupName: name,
    cartId: Math.floor(Math.random() * 1000),
    id: Math.floor(Math.random() * 1000),
    products: [],
  };

  // Return the whole cart with the new group
  const cartWithNewGroup: ICart = {
    ...cart,
    cartGroups: [...cart.cartGroups, newGroup],
  };

  return cartWithNewGroup;
};
