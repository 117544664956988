import { View, Text, StyleSheet } from '@react-pdf/renderer';

interface ICustomerInformation {
  company: string;
  email: string;
  name: string;
  phone: string;
  reference: string;
}

interface IPDFCustomerInformation {
  customerInfo: ICustomerInformation;
}

export const PDFCustomerInformation = (props: IPDFCustomerInformation) => {
  const styles = StyleSheet.create({
    root: {
      padding: '0px 24px 16px 24px',
      borderBottom: '1pt solid #D9D9D9',
      fontSize: '8pt',
    },
    customerInformationContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    title: {
      marginBottom: '8pt',
      fontSize: '15pt',
      fontWeight: 'bold',
    },
    headerNameGroup: {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 'semibold',
      paddingRight: '48px',
    },
    customerInfoGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    listText: {
      fontWeight: 'semibold',
      marginVertical: '6pt',
    },
    listInfo: {
      marginVertical: '6pt',
    },
  });

  return (
    <View style={styles.root}>
      <Text style={styles.title}>Customer Information</Text>
      <View style={styles.customerInformationContainer}>
        <View style={styles.headerNameGroup}>
          <Text style={styles.listText}>Company</Text>
          <Text style={styles.listText}>Name</Text>
          <Text style={styles.listText}>Email</Text>
          <Text style={styles.listText}>Phone</Text>
          <Text style={styles.listText}>Project Reference</Text>
        </View>
        <View style={styles.customerInfoGroup}>
          <Text style={styles.listInfo}>{props.customerInfo.company ?? ''}</Text>
          <Text style={styles.listInfo}>{props.customerInfo.name ?? ''}</Text>
          <Text style={styles.listInfo}>{props.customerInfo.email ?? ''}</Text>
          <Text style={styles.listInfo}>{props.customerInfo.phone ?? ''}</Text>
          <Text style={styles.listInfo}>{props.customerInfo.reference ?? ''}</Text>
        </View>
      </View>
    </View>
  );
};
