import { Button, message } from 'antd';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGet } from 'restful-react';
import { Container } from '../../Components/Container/Container';
import { ICart, ICartGroup, IProductInCart } from '../Cart/CartComponent';
import { CartGroup } from '../Cart/CartGroup/CartGroup';
import { ProductInCartContainer } from '../Cart/CartGroup/ProductInCart/ProductInCartContainer';
import { replaceLocalStorage } from '../Cart/Functions/replace-local-storage';
import { cartKeyWords } from '../Cart/Other/keywords';
import { IGenerateCart, IGenerateCartProducts } from '../Cart/ShareCart/ShareCart';
import { paths } from '../../../../routes/paths';
import componentClasses from '../../../../styles/Components.module.scss';
import classes from './ViewCart.module.scss';
import { CartContext, CartContextType } from '../../../../Utils/Providers/CartContextProvider';

export const ViewCart = () => {
  // Get cart from context
  const { setCart } = useContext(CartContext) as CartContextType;
  let { carturl } = useParams<{ carturl: string }>();
  let navigate = useNavigate();

  const { data } = useGet<IGenerateCart>({
    path: `/shoppingcarts/${carturl}`,
    resolve: (data) => {
      return data.cart ?? [];
    },
  });

  const mapProductToCartType = (products: IGenerateCartProducts[]) => {
    const updatedProducts: IProductInCart[] = products.map((x: IGenerateCartProducts) => {
      return {
        id: x.productId,
        eMaxNumber: x.eMaxNumber,
        uniqueProductId: Math.floor(Math.random() * 10000),
        productName: x.productName,
        productNumber: x.productNumber,
        numberOfItems: x.numberOfItems,
        imagePath: x.image,
        cartGroupId: x.cartGroupId,
        isMainProduct: x.mainProduct,
      };
    });

    return updatedProducts;
  };

  const copyToCart = () => {
    if (data) {
      // Find the ungrouped products
      const ungroupedProductsFromCart: IGenerateCartProducts[] =
        data.cartGroups.find((x) => x.cartGroupName === cartKeyWords.ungrouped)?.products ?? [];

      // Map to correct types
      const ungroupedProducts = mapProductToCartType(ungroupedProductsFromCart);

      // Remove the empty group
      const cartGroupsWithoutUngrouped = data.cartGroups.filter((x) => x.cartGroupName !== cartKeyWords.ungrouped);

      // Map cart groups to cart groups with correct type
      const cartGroups: ICartGroup[] = cartGroupsWithoutUngrouped.map((x) => {
        return {
          id: x.id,
          cartId: x.cartId,
          cartGroupName: x.cartGroupName,
          products: mapProductToCartType(x.products),
        };
      });

      // Creating a cart with the same interface as cart
      const cart: ICart = {
        url: carturl ?? '',
        cartName: data.cartName,
        created: new Date().toString(),
        cartGroups: cartGroups,
        ungroupedProducts: ungroupedProducts,
      };

      // Replacing localStorage and cartContext
      replaceLocalStorage(cart);
      setCart(cart);

      message.success('Your cart is updated');

      // Route to cart
      navigate(paths.cart);
    }
  };

  return (
    <>
      <Container>
        {data ? (
          <>
            <header className={classes.header}>
              <h1 className={classes.title}>{data.cartName}</h1>
              <Button onClick={copyToCart} className={`${componentClasses.button} ${componentClasses.buttonPrimary}`}>
                Copy to cart
              </Button>
            </header>
            {data.cartGroups.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <CartGroup title={x.cartGroupName}>
                    {x.products.map((product, j) => {
                      return (
                        <ProductInCartContainer
                          id={product.productId}
                          uniqueProductId={product.productId}
                          showNumberOfItemsOutsideInput
                          imagePath={product.image}
                          cartGroupId={product.cartGroupId}
                          isMainProduct={product.mainProduct}
                          {...product}
                        />
                      );
                    })}
                  </CartGroup>
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <h1>This cart is empty</h1>
        )}
      </Container>
    </>
  );
};
