import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useState } from 'react';
import classes from '../ImageCarousel.module.scss';
import { addCarouselItem } from '../ImageCarouselFunctions';

export const AddCarouselImageModal = (props: any) => {
  const [form] = Form.useForm();
  const formData = new FormData();
  const [loading, setLoading] = useState<boolean>(false);

  const closeModal = () => {
    form.resetFields();
    props.onCancel();
  };

  const addFileToForm = (file: RcFile) => {
    formData.append('file', file, file.name);
  };

  const formFinish = async (formValues: any) => {
    setLoading(true);
    let response = await addCarouselItem(props.page, formValues.link, formData);

    if (response && response.success) {
      message.success('Image uploaded successsfully!');
      props.showModal(false);
      form.resetFields();
      props.refetch();
    }
    setLoading(false);
  };

  return (
    <Modal visible={props.visible} footer={null} onCancel={closeModal}>
      <Form form={form} layout="vertical" onFinish={formFinish}>
        <Form.Item required name="picture" label="Upload picture">
          <Upload
            beforeUpload={(file) => {
              addFileToForm(file);
              return false;
            }}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item required name="link" label="Link">
          <Input />
        </Form.Item>
        <Form.Item>
          <div className={classes.buttonContainer}>
            <Button onClick={closeModal}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};
