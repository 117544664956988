import { message, Modal, Tabs } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StickySaveFooter from '../../../../Components/StickySaveFooter/StickySaveFooter';
import ConnectionTab, { IConnectionProduct, IConnectionModalInformation } from './Tabs/ConnectionsTab';
import DocumentsTab from './Tabs/DocumentsTab';
import ProductTab, {
  IProductTabForm,
  IProductTabInformation,
  ICategory,
  ISearchTag,
  IFormError,
} from './Tabs/ProductTab';
import { RelatedProductsTab } from './Tabs/ProductLists/Components/RelatedProductsTab';
import { IncludedItemsTab } from './Tabs/ProductLists/Components/IncludedItemsTab';
import TechnicalSpecificationsTab, { IAttribute, IAttributeBulk } from './Tabs/TechnicalSpecificationsTab';
import { IListProduct } from './Tabs/ProductLists/Interfaces/IProductList';
import { OptionalItemsTab } from './Tabs/ProductLists/Components/OptionalItemsTab';
import { headers, headersFiles } from '../../../../api';
import {
  updateAttribute,
  updatedProductTabForm,
  addIncludedItemData,
  addRelatedProductData,
  addOptionalData,
  addProductCategories,
  removeProductCategories,
  uploadDocument,
  removeDocument,
  uploadImage,
  updateOptionalItems,
  addSearchTags,
  deleteSearchTags,
  getBase64,
  uppdateIncludedItems,
  updateRelatedItems,
} from './EditProductFunctions';
import { IImageData, IImageFile } from '../../../../Components/PicturesGrid/types';
import SubHeader from '../../../../Components/SubHeader/SubHeader';
import { IDocument } from '../../../../Components/DocumentUpload/DocumentUpload';
import classes from './EditProduct.module.scss';
import { onLogout } from '../../../../Components/Header/Header';
import { useGet, useMutate } from 'restful-react';
import { AppConfig } from '../../../../config';
import { paths } from '../../../../routes/paths';
import fetchSendError from '../../../../Utils/fetchSendError';
import { LoadingState } from '../../../../Components/LoadingState/LoadingState';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { v4 as uuidv4 } from 'uuid';
import { useCallbackPrompt } from '../../../../Utils/Hooks/useCallbackPrompt';
import React from 'react';

export interface IProduct {
  attribute: IAttribute[];
  category: ICategory[];
  description: string;
  document: any[];
  eMaxNumber: string;
  id: number;
  image: IImageData[];
  isPublished: boolean;
  mainProduct: boolean;
  name: string;
  price: number;
  productNumber: string;
  addOnProduct: IListProduct[];
  includedProduct: IListProduct[];
  relatedProduct: IListProduct[];
  searchTag: ISearchTag[];
  imagePath: string;
  fileName: string;
}

interface IProductPageMode {
  createMode: boolean;
  subHeaderTitle: string;
}

const EditProduct = (props: IProductPageMode) => {
  let { id } = useParams<{ id: string }>();

  //TODO handle id being undefined
  let productId: number = id ? parseInt(id) : -1;

  const [productData, setProductData] = useState<IProduct>();
  // Remove images from here and categories
  const [productTab, setProductTab] = useState<IProductTabInformation>();
  const [imageKeysToRemove, setImageKeysToRemove] = useState<number[]>([]);
  const [images, setImages] = useState<IImageData[]>([]);
  const [originalImages, setOriginalImages] = useState<IImageData[]>([]);
  const [imageFileList, setImageFileList] = useState<IImageFile[]>([]);
  const [unsavedImageUUIDs, setUnsavedImageUUIDs] = useState<string[]>([]);

  const [productTabForm, setProductTabForm] = useState<IProductTabForm>();

  const [relatedItems, setRelatedItems] = useState<IListProduct[]>([]);
  const [includedItems, setIncludedItems] = useState<IListProduct[]>([]);
  const [optionalItems, setOptionalItems] = useState<IListProduct[]>([]);
  const [changedIncludedOrder, setChangedIncludedOrder] = useState<IListProduct[]>([]);
  const [changedOptionalOrder, setChangedOptionalOrder] = useState<IListProduct[]>([]);
  const [changedRelatedOrder, setChangedRelatedOrder] = useState<IListProduct[]>([]);

  const [attributes, setAttributes] = useState<IAttribute[]>();

  //Documents
  const uploadingDocuments = new FormData();
  const [documents, setDocuments] = useState<IDocument[]>();
  const [documentKeysToRemove, setDocumentKeysToRemove] = useState<number[]>([]);
  const [documentFileList, setDocumentFileList] = useState<File[]>([]);

  const [categoriesList, setCategoriesList] = useState<ICategory[]>([]);
  const [checkedCategoryKeys, setCheckedCategoryKeys] = useState<number[]>();
  const [isPublished, setIsPublished] = useState<boolean>(false);

  const [updatedSearchTags, setUpdatedSearchTags] = useState<string[]>();
  const [connections, setConnections] = useState<IConnectionProduct[]>([]);
  const [initialConnections, setInitialConnections] = useState<IConnectionProduct[]>([]);
  const [errorState, setErrorState] = useState<IFormError[]>([]);

  let navigate = useNavigate();
  const { TabPane } = Tabs;

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(showDialog);

  const [saving, setSaving] = useState(false);

  // const goToEditProductPage = (id: number) => {
  //   navigate(paths.adminProductBase + id);
  // };

  interface IDatabaseImage {
    id: number;
    imagePath: string;
    fileName: string;
  }

  const getCategoriesData = () => {
    fetch(`${AppConfig.API_URL}categories`, {
      method: 'GET',
      headers: headers,
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((data) => setCategoriesList(data.categories));
  };

  const { loading } = useGet({
    path: `connections/${productId}`,
    resolve: (data) => {
      let relatedProducts: IConnectionProduct[] = [];
      let includedProducts: IConnectionProduct[] = [];
      let addOnProducts: IConnectionProduct[] = [];

      if (data.relatedProduct) {
        relatedProducts = data.relatedProduct.map((x: IConnectionProduct) => ({
          connectionType: 'Related to',
          connectionUrl: 'relatedProduct',
          name: x.name,
          id: x.id,
        }));
      }

      if (data.includedProduct) {
        includedProducts = data.includedProduct.map((x: IConnectionProduct) => ({
          connectionType: 'Included in',
          connectionUrl: 'includedProduct',
          name: x.name,
          id: x.id,
        }));
      }

      if (data.addOnProduct) {
        addOnProducts = data.addOnProduct.map((x: IConnectionProduct) => ({
          connectionType: 'Is option to',
          connectionUrl: 'addOnProduct',
          name: x.name,
          id: x.id,
        }));
      }

      setConnections([...relatedProducts, ...includedProducts, ...addOnProducts]);
      setInitialConnections([...relatedProducts, ...includedProducts, ...addOnProducts]);
    },
  });
  const { loading: isDocumentsLoading, refetch } = useGet({
    path: `product/${productId}`,
    resolve: (data) => setDocuments(data.document),
  });

  const getProductData = useCallback(() => {
    fetch(AppConfig.API_URL + 'product/' + productId, {
      method: 'GET',
      headers: headers,
      credentials: 'include',
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setProductData(data);
        setProductTab({
          images: data.image,
          categories: data.category,
        });
        setCheckedCategoryKeys(
          data.category.map((x: ICategory) => {
            return x.id;
          }),
        );
        setImages(
          data.image.map((x: IDatabaseImage) => {
            return { name: x.fileName, url: AppConfig.IMAGE_BLOB.concat(x.imagePath), uid: x.id, guid: x.imagePath };
          }),
        );
        setOriginalImages(
          data.image.map((x: IDatabaseImage) => {
            return { name: x.fileName, url: AppConfig.IMAGE_BLOB.concat(x.imagePath), uid: x.id, guid: x.imagePath };
          }),
        );
        setIncludedItems(data.includedProduct ?? []);
        setRelatedItems(data.relatedProduct ?? []);
        setAttributes(data.attribute ?? []);
        setOptionalItems(data.addOnProduct ?? []);
        setProductTabForm({
          description: data.description,
          price: data.price,
          name: data.name,
          productNumber: data.productNumber,
          referenceId: data.id,
          mainProduct: data.mainProduct,
          eMaxNumber: data.eMaxNumber,
          searchTags: data.searchTags,
          isPublished: data.isPublished,
        });
        setIsPublished(data.isPublished);
        setDocuments(data.document ?? []);
      })
      .catch((error) => console.error(error));
  }, [productId]);

  const addMultipleItems = (products: IListProduct[], oldItems: IListProduct[]) => {
    const arrayCopy = [...oldItems];
    products.forEach((x) => {
      if (!arrayCopy.some((z) => z.id === x.id)) {
        arrayCopy?.push(x);
      }
    });
    setShowDialog(true);
    return arrayCopy;
  };
  const onAddMultipleIncludedItems = (items: IListProduct[]) => setIncludedItems((old) => addMultipleItems(items, old));
  const onAddMultipleRelatedItems = (items: IListProduct[]) => setRelatedItems((old) => addMultipleItems(items, old));
  const onAddMultipleOptionalItems = (items: IListProduct[]) => setOptionalItems((old) => addMultipleItems(items, old));

  const onAddItems = (product: IListProduct, oldItems: IListProduct[]) => {
    setShowDialog(true);
    return oldItems && !oldItems.find((element) => element.id === product.id) ? [...oldItems, product] : oldItems;
  };
  const onAddIncludedItems = (item: IListProduct) => setIncludedItems((old) => onAddItems(item, old));
  const onAddRelatedlItems = (item: IListProduct) => setRelatedItems((old) => onAddItems(item, old));
  const onAddOptionalItems = (item: IListProduct) => setOptionalItems((old) => onAddItems(item, old));

  const removeItem = (id: number, oldItems: IListProduct[]) => {
    setShowDialog(true);
    return oldItems?.filter((item: IListProduct) => item.id !== id);
  };
  const removeIncludedItem = (id: number) => setIncludedItems((old) => removeItem(id, old));
  const removeRelatedItem = (id: number) => setRelatedItems((old) => removeItem(id, old));
  const removeOptionalItem = (id: number) => setOptionalItems((old) => removeItem(id, old));

  const removeImage = (imageId: string) => {
    const img = images.find((image) => image.uid === imageId);

    if (img?.guid && unsavedImageUUIDs.includes(img?.guid)) {
      fetch(`${AppConfig.API_URL}image`, {
        method: 'DELETE',
        credentials: 'include',
        body: JSON.stringify([img.guid]),
        headers: headers,
      });
    }

    let newDisplayArray = images?.filter(function (item: IImageData) {
      return item.uid !== imageId;
    });
    let newUploadArray = imageFileList?.filter(function (item: IImageFile) {
      return item.uid !== imageId;
    });

    typeof imageId === 'number' && setImageKeysToRemove([...imageKeysToRemove, parseInt(imageId)]);

    setImageFileList(newUploadArray);
    setImages(newDisplayArray);
    setShowDialog(true);
  };

  const createNewProduct = (newData: IProductTabForm) => {
    fetch(`${AppConfig.API_URL}product`, {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      body: JSON.stringify(newData),
    })
      .then((res) => {
        if (res.status === 401) {
          return onLogout();
        }
        return res.json();
      })
      .then((data) => {
        // Store product ID of created product
        productId = data.id;
        // Categories
        if (checkedCategoryKeys) {
          addProductCategories(data.id, checkedCategoryKeys);
        }
        //Search tags
        updatedSearchTags && addSearchTags(data.id, updatedSearchTags);
        // Included items
        if (includedItems) {
          includedItems.forEach((x: IListProduct) => {
            x.numberOfItems && addIncludedItemData(productId, x.id, x.numberOfItems);
          });
        }
        // Related items
        if (relatedItems) {
          relatedItems.forEach((x: IListProduct) => {
            addRelatedProductData(productId, x.id);
          });
        }
        // Optional items
        if (optionalItems) {
          optionalItems.forEach((x: IListProduct) => {
            addOptionalData(productId, x.id);
          });
        }
        // Attributes / Technical Specifications
        if (attributes) {
          const attributeBulk: IAttributeBulk[] =
            attributes?.map((x: IAttribute) => {
              return {
                attributeId: x.id,
                value: x.value,
              };
            }) ?? [];
          updateAttribute(productId, attributeBulk);
        }

        // Product tab images
        if (imageFileList.length > 0) {
          const formData = new FormData();
          imageFileList.forEach((file: File) => {
            formData.append('files', file, file.name);
          });
          uploadImage(productId, formData);
          setImageFileList([]);
        }

        // Documents
        if (documentFileList.length > 0) {
          const nameArray: string[] = [];
          documentFileList.forEach((file: File) => {
            uploadingDocuments.append('files', file, file.name);
            nameArray.push(file.name);
          });

          if (uploadingDocuments) {
            uploadDocument(productId, nameArray, uploadingDocuments, refetch);
            setDocumentFileList([]);
            setDocuments(documents);
          }
        }
      })
      .then(() => {
        message.success('Product created');
        navigate(paths.adminProductsOverview);
      })
      .catch((error) => fetchSendError(`trying to create a new product`, error));
  };

  // const debouncedRouteChange = useDebouncedCallback((value) => {
  //   goToEditProductPage(value);
  // }, 500);

  const { mutate: deleteConnection } = useMutate<
    any,
    any,
    any,
    any,
    { Id: number; connectionURL: string; relatedId: number }
  >(
    'DELETE',
    (pathParams: { Id: number; connectionURL: string; relatedId: number }) =>
      `${pathParams.connectionURL}?productId=${pathParams.Id}&${pathParams.connectionURL}Id=${pathParams.relatedId}`,
  );

  const removeConnection = (object: IConnectionModalInformation) => {
    const newConnections = connections?.filter((x) => {
      if (x.id === object.productId && x.connectionUrl === object.connectionUrl) {
        return false;
      } else return true;
    });
    setConnections(newConnections);
  };

  const checkErrors = (inputData: IProductTabForm | undefined) => {
    let errors: IFormError[] = [];
    if (!inputData?.name || inputData?.name === '') {
      errors.push({ identifier: 'name', type: 'error' });
    }
    if (!inputData?.productNumber || inputData?.productNumber === '') {
      errors.push({ identifier: 'productNumber', type: 'error' });
    }
    setErrorState(errors);
    if (errors.length > 0) {
      return true;
    }
  };

  const onSave = async () => {
    if (saving) {
      message.error('Saving is already in progress, please wait a bit before trying again.');
      return;
    }

    if (checkErrors(productTabForm)) {
      message.error('Required information missing.');
      return;
    }
    setSaving(true);
    setShowDialog(false);

    let saveErrors = 0;
    let noChanges = true;

    const testSaveErrors = (response: any, from: string) => {
      if (!response || !(response.success || response.status < 400)) {
        console.error(from, ': ', response);
        saveErrors++;
      }
    };

    // Create new product
    if (props.createMode && productTabForm) {
      let newForm = productTabForm;
      newForm.isPublished = isPublished;
      return createNewProduct(newForm);
    }

    // Categories
    if (checkedCategoryKeys) {
      let alreadyPostedCategoryKeys: number[] = [];
      let newCategoryKeysToPost: number[] = [];
      let categoryKeysToRemove: number[] = [];

      if (productTab && productTab.categories) {
        alreadyPostedCategoryKeys = productTab.categories.map((x: ICategory) => {
          return x.id;
        });

        if (checkedCategoryKeys !== alreadyPostedCategoryKeys) {
          checkedCategoryKeys.forEach((element: number) => {
            if (!alreadyPostedCategoryKeys.includes(element)) {
              newCategoryKeysToPost.push(element);
            }
          });
        }

        alreadyPostedCategoryKeys.forEach((element: number) => {
          if (!checkedCategoryKeys.includes(element)) {
            noChanges = false;

            categoryKeysToRemove.push(element);
          }
        });

        if (newCategoryKeysToPost.length > 0) {
          noChanges = false;

          const response = await addProductCategories(productId, newCategoryKeysToPost);
          testSaveErrors(response, 'addProductCategories');
        }
        if (categoryKeysToRemove.length > 0) {
          noChanges = false;

          const response = await removeProductCategories(productId, categoryKeysToRemove);
          testSaveErrors(response, 'removeProductCategories');
        }
      }
    }

    // Included items
    if (includedItems && includedItems !== productData?.includedProduct) {
      noChanges = false;

      const response: any = await uppdateIncludedItems(
        productId,
        includedItems.map((item) => ({
          includedProductId: item.id ?? -1,
          numberOfItems: item.numberOfItems ?? 0,
        })),
      );
      testSaveErrors(response, 'updateIncludeItems');
    }

    // Related products
    if (relatedItems !== productData?.relatedProduct) {
      noChanges = false;

      const response: any = await updateRelatedItems(
        productId,
        relatedItems.map((item) => item.id),
      );
      testSaveErrors(response, 'updateRelatedData');
    }

    // Optional items
    if (optionalItems !== productData?.addOnProduct) {
      noChanges = false;

      const response: any = await updateOptionalItems(
        productId,
        optionalItems.map((item) => item.id),
      );
      testSaveErrors(response, 'updateOptionalData');
    }

    // Technical Specifications
    if (attributes !== productData?.attribute) {
      noChanges = false;

      const attributeBulk: IAttributeBulk[] =
        attributes?.map((x: IAttribute) => {
          return {
            attributeId: x.id,
            value: x.value,
          };
        }) ?? [];

      let response: any = await updateAttribute(productId, attributeBulk);
      testSaveErrors(response, 'updateAttribute');
    }

    if (
      (!props.createMode &&
        (productData?.price !== productTabForm?.price ||
          productData?.description !== productTabForm?.description ||
          productData?.name !== productTabForm?.name ||
          productData?.productNumber !== productTabForm?.productNumber ||
          productData?.id !== productTabForm?.referenceId)) ||
      productData?.isPublished !== isPublished ||
      imageFileList.length > 0 ||
      imageKeysToRemove.length > 0 ||
      images !== productData?.image
    ) {
      if (productTabForm) {
        noChanges = false;
        const imgs = images.map((img) => ({ imagePath: img.guid ?? '', fileName: img.name ?? img.fileName ?? '' }));

        let response = await updatedProductTabForm(
          productId,
          productTabForm.name,
          productTabForm.description,
          productTabForm.price,
          productTabForm.productNumber,
          productTabForm.eMaxNumber,
          productTabForm.mainProduct,
          isPublished,
          imgs,
        );
        testSaveErrors(response, 'updatedProductTabForm');
      }
    }

    // Documents
    if (documentFileList.length > 0) {
      noChanges = false;

      let nameArray: string[] = [];
      documentFileList.forEach((file: File) => {
        uploadingDocuments.append('files', file, file.name);
        nameArray.push(file.name);
      });

      if (uploadingDocuments) {
        let responses: any = await uploadDocument(productId, nameArray, uploadingDocuments, refetch);

        responses.forEach(async (response: any) => {
          await response;
          testSaveErrors(response, 'uploadDocument');
        });
        setDocumentFileList([]);
        setDocuments(documents);
      }
    }

    if (documentKeysToRemove.length > 0) {
      noChanges = false;
      let responses: any = removeDocument(documentKeysToRemove);
      responses.forEach(async (response: any) => {
        await response;
        testSaveErrors(response, 'removeDocument');
      });
      setDocumentKeysToRemove([]);
    }

    // Search tags
    if (updatedSearchTags && productTabForm?.searchTags) {
      noChanges = false;

      let addedTagsArray: string[] = updatedSearchTags;
      let removedTagsId: number[] = [];
      let removedTagsArray: string[] =
        productTabForm?.searchTags.map((x) => {
          return x.tag;
        }) ?? [];

      productTabForm?.searchTags.forEach((oldTags) => {
        addedTagsArray = addedTagsArray.filter((s) => s !== oldTags.tag);
      });

      updatedSearchTags.forEach((newTags) => {
        removedTagsArray = removedTagsArray.filter((s) => s !== newTags);
      });

      removedTagsArray.forEach((element) => {
        productTabForm &&
          productTabForm.searchTags &&
          productTabForm?.searchTags.forEach((x) => {
            if (element === x.tag) {
              removedTagsId.push(x.id);
            }
          });
      });

      if (addedTagsArray.length > 0) {
        let response: any = await addSearchTags(productId, addedTagsArray);
        testSaveErrors(response, 'addSearchTags');
      }
      if (removedTagsId.length > 0) {
        const response: any = await deleteSearchTags(removedTagsId);
        testSaveErrors(response, 'deleteSearchTags');
      }
    }
    if (connections !== initialConnections) {
      initialConnections.forEach((x) => {
        if (!connections.includes(x)) {
          noChanges = false;

          let response: any = deleteConnection(
            {},
            { pathParams: { Id: x.id, connectionURL: x.connectionUrl, relatedId: productId } },
          );
          testSaveErrors(response, 'deleteConnection');
        }
      });
    }
    setSaving(false);
    if (noChanges) {
      message.info('Could not detect any changes to save.');
    } else if (saveErrors)
      message.warning(
        `${
          saveErrors === 1 ? 'An unexpected issue' : 'Unexpected issues'
        } occurred while saving, please check that the product was saved correctly`,
        10,
      );
    else {
      message.success('Saved!');
      navigate(paths.adminProductsOverview);
    }
  };

  const handleUpdateDocumentList = (id: number) => {
    setDocumentKeysToRemove([...documentKeysToRemove, id]);
    setDocuments(documents?.filter((el) => el.id !== id));
  };

  const updateSetProductFormState = (formdata: any) => {
    setProductTabForm(formdata);
    checkErrors(formdata as IProductTabForm);
  };

  const handleQuantityIncludedChange = (value: number, itemId: number) => {
    setIncludedItems((prev) => prev.map((item) => (item.id === itemId ? { ...item, numberOfItems: value } : item)));
  };

  useEffect(() => {
    getCategoriesData();
    if (!props.createMode) {
      getProductData();
    }
  }, [getProductData, props.createMode]);

  const handleUploadImage = async (image: RcFile) => {
    const guid = uuidv4();

    const imageObject: IImageData = { name: image.name, url: '', uid: image.uid, guid: guid };

    if (imageObject.url === '') {
      imageObject.url = await getBase64(image).then();
    }

    const formData = new FormData();
    formData.append('file', image);

    const description = 'upload image';

    fetch(`${AppConfig.API_URL}images/addToBlob/${productId}/${guid}`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
      headers: headersFiles,
    })
      .then((res) => {
        if (res.ok) {
          setShowDialog(true);
          setUnsavedImageUUIDs((prev) => [...prev, guid]);
          setImageFileList((prev) => [...prev, image]);
          setImages([...(images ?? []), imageObject]);
        }
      })
      .catch((error) => fetchSendError(`trying to ${description}`, error));
  };

  const onPictureGirdChange = ({ fileList }: { fileList: UploadFile[] }) => {
    let temp: IImageData[] = fileList.map((z) => {
      return {
        name: z.name,
        uid: z.uid,
        url: z.url ?? '',
        guid: images.find((i) => i.uid === z.uid)?.guid,
      };
    });
    setImages(temp);
  };

  const handleCancel = async () => {
    if (unsavedImageUUIDs.length) {
      await fetch(`${AppConfig.API_URL}image`, {
        method: 'DELETE',
        credentials: 'include',
        body: JSON.stringify(unsavedImageUUIDs),
        headers: headers,
      }).then((res) => {
        if (res.ok) {
          setUnsavedImageUUIDs([]);
          setImageFileList([]);
          setImages(originalImages);
          setShowDialog(false);
        }
      });
    }
  };

  const handleNavigateFromPage = async () => {
    await handleCancel();
    confirmNavigation();
  };

  return (
    <>
      <Modal title="Unsaved changes" visible={showPrompt} onOk={handleNavigateFromPage} onCancel={cancelNavigation}>
        <p>You have unsaved changes, are you sure you want to leave the page? </p>
      </Modal>
      <SubHeader
        title={props.subHeaderTitle}
        productNumber={productData?.productNumber}
        productName={productData?.name}
      />
      {loading && <LoadingState text="Loading Product..." />}
      {(productData || props.createMode) && (
        <Tabs defaultActiveKey="1" className={classes.tabs}>
          <TabPane tab="Product" key="1">
            {(productTabForm || props.createMode) && (
              <ProductTab
                isCreatorMode={props.createMode}
                form={productTabForm}
                images={images ?? []}
                handleChange={onPictureGirdChange}
                imageFileList={imageFileList}
                removeImage={(imageId: string) => removeImage(imageId)}
                categories={categoriesList}
                checkedCategories={checkedCategoryKeys ?? []}
                onCheckChange={(checkKeys: number[]) => setCheckedCategoryKeys(checkKeys)}
                onFormChange={(formdata: any) => updateSetProductFormState(formdata)}
                searchTags={productTabForm?.searchTags ?? []}
                updateTags={setUpdatedSearchTags}
                errorState={errorState}
                onUploadFile={handleUploadImage}
              />
            )}
          </TabPane>
          <TabPane tab="Included Items" key="2">
            <IncludedItemsTab
              setNewProductList={(e) => setIncludedItems(e)}
              productId={productId}
              products={includedItems}
              onAddMultipleItems={(e) => onAddMultipleIncludedItems(e)}
              onRemoveItem={(id: number) => removeIncludedItem(id)}
              onAddItems={(e) => onAddIncludedItems(e)}
              orderList={changedIncludedOrder}
              changeOrder={(list: IListProduct[]) => setChangedIncludedOrder(list)}
              handleQuantityChange={(value: number, index: number) => handleQuantityIncludedChange(value, index)}
            />
          </TabPane>
          <TabPane tab="Add Options" key="3">
            <OptionalItemsTab
              setNewProductList={(e) => setOptionalItems(e)}
              productId={productId}
              products={optionalItems}
              onAddMultipleItems={(e) => onAddMultipleOptionalItems(e)}
              onRemoveItem={(id: number) => removeOptionalItem(id)}
              onAddItems={(e) => onAddOptionalItems(e)}
              orderList={changedOptionalOrder}
              changeOrder={(list: IListProduct[]) => setChangedOptionalOrder(list)}
            />
          </TabPane>
          <TabPane tab="Specifications" key="4">
            <TechnicalSpecificationsTab
              attributes={attributes ?? []}
              onFormChange={(values: IAttribute[]) => setAttributes(values)}
            />
          </TabPane>
          <TabPane tab="Documents" key="5">
            <DocumentsTab
              reloadDocumentsList={refetch}
              isDocumentsLoading={isDocumentsLoading}
              documents={documents ?? []}
              removeDocument={(id: number) => handleUpdateDocumentList(id)}
              documentFileList={documentFileList}
              setDocumentFileList={(list: File[]) => setDocumentFileList(list)}
            />
          </TabPane>
          <TabPane tab="You may also like" key="6">
            <RelatedProductsTab
              setNewProductList={(e) => setRelatedItems(e)}
              productId={productId}
              onAddMultipleItems={(e) => onAddMultipleRelatedItems(e)}
              products={relatedItems}
              onRemoveItem={(id: number) => removeRelatedItem(id)}
              onAddItems={(e) => onAddRelatedlItems(e)}
              orderList={changedRelatedOrder}
              changeOrder={(list: IListProduct[]) => setChangedRelatedOrder(list)}
            />
          </TabPane>
          <TabPane tab="Connections" key="7">
            <ConnectionTab removeConnection={removeConnection} tableData={connections ?? []} loading={loading} />
          </TabPane>
        </Tabs>
      )}
      <StickySaveFooter
        onSave={() => onSave()}
        onCancel={() => {
          navigate(paths.adminProductsOverview);
        }}
        published={isPublished}
        errorState={errorState.length > 0 || saving}
        publishToSite={(isPublished: boolean) => setIsPublished(isPublished)}
      />
    </>
  );
};

export default EditProduct;
