import { headers } from '../../../api';
import { onLogout } from '../../../Components/Header/Header';
import { AppConfig } from '../../../config';
import fetchSendError from '../../../Utils/fetchSendError';
import { ICategory } from './CategoriesPage';

interface ICategoryEdit {
  name: string;
  parentId: number;
  categoryText: string;
  isPublished: boolean;
}

export interface IConfirmRemove {
  name: string;
  id: number;
}

export const removeCategory = (id: any) => {
  const description = 'remove a category';
  fetch(AppConfig.API_URL + 'categories/' + id, {
    method: 'DELETE',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      res.json();
    })
    .then((res) => {})
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const updateList = (afterId: number, moveId: number) => {
  const description = 'update a category list';
  fetch(AppConfig.API_URL + 'categories-move?id=' + moveId + '&afterId=' + afterId, {
    method: 'PUT',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      res.json();
    })
    .then((res) => {})
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const saveNewName = (name: string, categoryId: number, parentId: number) => {
  const description = 'save a category name';
  fetch(
    AppConfig.API_URL + 'Categories/' + categoryId + '?Name=' + encodeURIComponent(name) + '&ParentId=' + parentId,
    {
      method: 'PUT',
      headers: headers,
      credentials: 'include',
    },
  )
    .then((res) => {
      if (res.status === 401) {
        return onLogout();
      }
      res.json();
    })
    .then((res) => {})
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const addSubCategory = (title: string, parentId: number) => {
  const description = 'add a subcategory';
  let data: ICategoryEdit = {
    name: encodeURIComponent(title),
    parentId: parentId,
    categoryText: '',
    isPublished: false,
  };
  fetch(AppConfig.API_URL + 'categories', {
    method: 'POST',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      if (res.status === 200) {
      } else {
        console.log('Look at the error below...');
      }
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

// Implement later, without the function the site crashes
export const handleRLDDChange = (e: any) => {
  console.log(e);
};

export const addCategory = (title: string) => {
  const description = 'add a category';
  let data = {
    name: encodeURIComponent(title),
    parentId: 0,
    categoryText: '',
    isPublished: false,
  };
  fetch(AppConfig.API_URL + 'categories', {
    method: 'POST',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      res.json();
    })
    .then(() => {})
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const publish = (item: ICategory) => {
  const description = 'publish a category';
  fetch(
    AppConfig.API_URL +
      '/Categories/' +
      item.id +
      '?Name=' +
      encodeURIComponent(item.name) +
      '&ParentId=' +
      item.parentId +
      '&CategoryText=' +
      item.categoryText +
      '&IsPublished=' +
      !item.isPublished,
    {
      method: 'PUT',
      headers: headers,
      credentials: 'include',
    },
  )
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      res.json();
    })
    .then((res) => {})
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};
