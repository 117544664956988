import { Button } from 'antd';
import { useContext } from 'react';
import { CartContext, CartContextType } from '../../../../../../Utils/Providers/CartContextProvider';
import componentClasses from '../../../../../../styles/Components.module.scss';
import { IProductInCart } from '../../../../Pages/Cart/CartComponent';
import { quickAddProductToCart } from '../../../../Pages/Cart/Functions/add-product-to-cart';
import { cartKeyWords } from '../../../../Pages/Cart/Other/keywords';
import classes from './QuickAddButton.module.scss';

interface IQuickAddButton {
  product: IProductInCart;
  padding?: boolean;
  isInCard?: boolean;
}

export const QuickAddButton = (props: IQuickAddButton) => {
  // Get cart from context
  const { setCart } = useContext(CartContext) as CartContextType;

  const addToCart = (product: IProductInCart) => {
    quickAddProductToCart(product);

    const currentCart = JSON.parse(localStorage.getItem(cartKeyWords.localStorageKey) ?? '');
    setCart(currentCart);
  };

  return (
    <div className={classes.actionsContainer + ` ${props.isInCard && classes.inCard}`}>
      <Button
        type="primary"
        onClick={() => addToCart(props.product)}
        className={`${componentClasses.button} ${componentClasses.buttonPrimary} ${classes.addButton} ${
          props.padding && classes.addButtonPadding
        }`}
      >
        Add
      </Button>
    </div>
  );
};
