import { ICart } from '../CartComponent';

export const emptyCart: ICart = {
  id: Math.floor(Math.random() * 1000),
  cartName: '',
  url: '',
  created: new Date().toString(),
  cartGroups: [],
  ungroupedProducts: [],
};
