import { AdminContainer } from '../Components/AdminContainer/AdminContainer';
import EditProduct from './EditProduct/EditProduct';

const CreateProductPage = () => {
  return (
    <AdminContainer>
      <EditProduct subHeaderTitle="Create Product" createMode={true} />
    </AdminContainer>
  );
};

export default CreateProductPage;
