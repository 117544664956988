import { Col, Row } from 'antd';
import { ContactForm } from '../../Components/ContactForm/ContactForm';
import { IconButton } from '../../Components/IconButton/IconButton';
import classes from './Contact.module.scss';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

export const Contact = () => {
  return (
    <div className={classes.container}>
      <Row className={classes.contactTitle}>
        <Col span={16}>
          <h1 className={classes.title}>CONTACT</h1>
        </Col>
      </Row>
      <Row className={classes.info}>
        <Col span={24} lg={12} className={classes.layout}>
          <div className={classes.column}>
            <address className={classes.address}>
              <span className={classes.listTitle}>
                <b>Address</b>
              </span>
              <span>HyrMax Rental AB</span>
              <span>Flöjelbergsgatan 8</span>
              <span>431 37 MÖLNDAL</span>
              <span>SWEDEN</span>
            </address>

            <address className={classes.address}>
              <span className={classes.listTitle}>
                <b>Postal address</b>
              </span>
              <span>HyrMax Rental AB</span>
              <span>Box 210</span>
              <span>431 23 MÖLNDAL</span>
              <span>SWEDEN</span>
            </address>
          </div>
          <div className={classes.column}>
            <div>
              <p id="opening-hours" className={classes.listTitle}>
                <b>Regular hours</b>
              </p>
              <ul aria-labelledby="opening-hours" className={classes.list}>
                <li aria-label="Monday to friday, from 08:00 to 17:00">Mon-Fri 08:00-17:00</li>
                <li aria-label="Closed for lunch 12:00-13:00">Closed for lunch 12:00-13:00</li>
              </ul>
            </div>

            <address className={classes.iconButtonGroup}>
              <IconButton icon={<PhoneIcon />} colour="black" href="tel:+4631212121" className={classes.iconButton}>
                031 - 21 21 21
              </IconButton>
              <IconButton
                icon={<EmailIcon />}
                colour="black"
                href="mailto:booking@hyrmax.se"
                className={classes.iconButton}
              >
                booking@hyrmax.se
              </IconButton>
            </address>
          </div>
        </Col>
        <Col span={24} lg={12} className={classes.mapEmbed}>
          <iframe
            title="Map to Hyrmax"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2133.7815057081066!2d12.01502461643812!3d57.669197850037136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff3cbc1cd0655%3A0xbb0483be9c4a21f0!2sHyrMax%20Rental%20AB!5e0!3m2!1ssv!2sse!4v1648624648212!5m2!1ssv!2sse"
            width="600"
            height="450"
            className="map-responsive"
            loading="lazy"
          ></iframe>
        </Col>
      </Row>
      <Row className={classes.formWrapper}>
        <Col span={24}>
          <ContactForm />
        </Col>
      </Row>
    </div>
  );
};
