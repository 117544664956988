export const setConfig = async () =>
  fetch('/appconfig.json')
    .then((response) => response.json())
    .then((cfg) => {
      AppConfig = {
        ...AppConfig,
        ...cfg.appConfig,
      };
      return AppConfig;
    })
    .catch(() => {
      return AppConfig;
    });

export interface IConfig {
  API_URL: string;
  IMAGE_BLOB: string;
  CAROUSEL_IMAGE_BLOB: string;
  DOCUMENT_BLOB: string;
  GENERAL_DOCUMENT_BLOB: string;
  PDF_PRODUCT_LINK: string;
}

export let AppConfig: IConfig = {
  API_URL: '',
  IMAGE_BLOB: '',
  CAROUSEL_IMAGE_BLOB: '',
  DOCUMENT_BLOB: '',
  GENERAL_DOCUMENT_BLOB: '',
  PDF_PRODUCT_LINK: '',
};
