import { Image } from 'antd';
import React from 'react';
import { useEffect, useState } from 'react';
import { AppConfig } from '../../../../../config';
import classes from './Images.module.scss';

interface IImages {
  productName: string;
  images: IImage[];
}

interface IImage {
  imagePath: string;
}

export const Images = React.forwardRef<HTMLDivElement, IImages>((props, ref) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setActiveImage(props.images[0].imagePath);
  }, [props.images]);

  const [activeImage, setActiveImage] = useState(props.images[0].imagePath);
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className={classes.root} ref={ref}>
      <div className={classes.imageWrapper}>
        <Image
          preview={{ visible: false }}
          alt={`${props.productName} (1)`}
          className={classes.preview}
          src={AppConfig.IMAGE_BLOB + activeImage}
          onClick={() => setVisible(true)}
        />
      </div>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible,
            onVisibleChange: (vis) => setVisible(vis),
            current: activeIndex,
          }}
        >
          {props.images.map((x, index) => (
            <Image src={AppConfig.IMAGE_BLOB + x.imagePath} key={index} />
          ))}
        </Image.PreviewGroup>
      </div>
      {props.images.length > 1 && (
        <div className={classes.thumbnailGroup}>
          {props.images.map((x, index) => {
            return (
              <div className={classes.thumbnailContainer}>
                <button
                  aria-label="Make active"
                  aria-pressed={x.imagePath === activeImage}
                  className={classes.button}
                  onClick={() => {
                    setActiveImage(x.imagePath);
                    setActiveIndex(index);
                  }}
                >
                  <img
                    className={`${classes.thumbnail} ${x.imagePath === activeImage && classes.thumbnailActive}`}
                    alt={`${props.productName} (${index})`}
                    src={AppConfig.IMAGE_BLOB + x.imagePath}
                  />
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});
