import { Collapse } from 'antd';
import { useGet } from 'restful-react';
import { LoadingState } from '../../../../../Components/LoadingState/LoadingState';
import classes from './FaqGroupComponent.module.scss';
import { getDocumentLink, documentsObject } from '../../../../../Utils/generalDocumentFetchers';
import { Link, useLocation } from 'react-router-dom';
import { paths } from '../../../../../routes/paths';
import { useCallback, useEffect, useState } from 'react';

interface IFaquestions {
  id: string | number;
  question: string;
  answer: string;
  category: string;
}
interface IFaqGroup {
  title: string;
  category: string;
  questionsAndAnswers: IFaquestions[];
}

interface IFaqGroupComponent {
  faqList: IFaqGroup[];
  generalDocuments?: any;
  loading?: boolean;
}

export const FaqGroupComponent = (props: IFaqGroupComponent) => {
  const { Panel } = Collapse;

  const { data, loading } = useGet({
    path: '/faqs',
  });

  const location = useLocation();
  const [openCookies, setOpenCookies] = useState(false);
  const [cookiePolicyWithOffset, setCookiePolicyWithOffset] = useState(0);
  /* Create a call back that fires when the ref to cookiePolicy is set */
  const cookiePolicyRef = useCallback((node) => {
    if (node !== null) {
      /* Wait half a second for the page to render once the ref has been set */
      setTimeout(() => {
        let objControl = document.getElementById('group6');
        /* Set the length of the page to scroll */
        if (objControl) setCookiePolicyWithOffset(objControl.getBoundingClientRect().top + window.pageYOffset - 100);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (location?.hash === '#cookiepolicy') {
      setOpenCookies(true);
    }
  }, [location]);

  useEffect(() => {
    if (location?.hash === '#cookiepolicy') {
      /* This is where the scroll happens */
      window.scrollTo({ top: cookiePolicyWithOffset, behavior: 'smooth' });
    }
  }, [cookiePolicyWithOffset, location]);

  const createTextWithLinks = (text: string) => {
    // A link will come as a pair of [text]{documentTitle}
    // Split out anything that is inside [...}
    const textArray = text.split(/[[}]+/);
    return textArray.map((subString, index) => {
      if (subString.includes(']{')) {
        // Split the link into text and documentTitle
        const subArray = subString.split(/[\]{]+/);
        if (subArray.length !== 2) return subString;

        // Check link goes to a valid document:
        if (Object.values(documentsObject).includes(subArray[1])) {
          return (
            <a key={index} href={getDocumentLink(subArray[1], props.generalDocuments)}>
              {subArray[0]}
            </a>
          );
        } else if (subArray[1].startsWith('http')) {
          // Create an external link
          return (
            <a key={index} href={subArray[1]}>
              {subArray[0]}
            </a>
          );
        } else if (subArray[1].startsWith('www')) {
          // Add https:// and create external link
          return (
            <a key={index} href={`https://${subArray[1]}`}>
              {subArray[0]}
            </a>
          );
        } else {
          // Else create an internal link:
          return <Link to={paths.home + subArray[1]}> {subArray[0]}</Link>;
        }
      } else return subString;
    });
  };

  return (
    <>
      {!loading ? (
        <div className={classes.root}>
          {props.faqList.map((item) => (
            <section
              id={item.category}
              className={classes.groupRoot}
              ref={item.category === 'group6' ? cookiePolicyRef : undefined}
            >
              <Collapse
                ghost
                bordered={false}
                defaultActiveKey={openCookies && item.category === 'group6' ? item.title : undefined}
              >
                <Panel className={classes.groupName} header={item.title} key={item.title}>
                  <Collapse bordered={false}>
                    {data.faqs
                      .filter((x: any) => x.category === item.category)
                      .map((item: any) => (
                        <Panel className={classes.question} header={item.question} key={item.id}>
                          <div className={classes.answer}>
                            {props.loading ? item.answer : createTextWithLinks(item.answer)}
                          </div>
                        </Panel>
                      ))}
                  </Collapse>
                </Panel>
              </Collapse>
            </section>
          ))}
        </div>
      ) : (
        <LoadingState />
      )}
    </>
  );
};
