import { DeleteOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons/lib/icons';
import { Button, Switch, Tooltip } from 'antd';
import { ICategory } from '../../CategoriesPage';
import classes from './CategoryListItem.module.scss';

interface ICategoryListItem {
  item: ICategory;
  itemId: number;
  itemIndex: number;
  onRemove: (id: number, name: string) => void;
  onRouteTo: (id: number) => void;
  onPublish: (item: ICategory) => void;
  currentIdAndRow: (data: number, index: number) => void;
}

export const CategoryListItem = (props: ICategoryListItem) => {
  const numbersOfSubcategories = props.item.subCategories.length ?? 0;

  const handleDragItem = () => {
    props.currentIdAndRow(props.itemId, props.itemIndex);
  };

  return (
    <div onMouseDown={handleDragItem} className={classes.root} onClick={() => props.onRouteTo(props.item.id)}>
      <div className={classes.header}>
        <MenuOutlined style={{ fontSize: '16px' }} />
        <h4>{props.item.name}</h4>

        {numbersOfSubcategories > 0 && (
          <span>
            {numbersOfSubcategories}
            {numbersOfSubcategories === 1 ? ' subcategory' : ' subcategories'}
          </span>
        )}
      </div>

      <div className={classes.actions} onClick={(e) => e.stopPropagation()}>
        <Switch
          className="margin-right"
          checked={props.item.isPublished}
          onChange={() => props.onPublish(props.item)}
        />

        <Button type="link" onClick={() => props.onRouteTo(props.item.id)}>
          <EditOutlined style={{ fontSize: '20px' }} />
        </Button>

        <Tooltip
          placement="left"
          title={
            numbersOfSubcategories !== 0
              ? 'This category contains subcategories, please remove them before removing this category.'
              : ''
          }
        >
          <Button
            type="link"
            disabled={numbersOfSubcategories !== 0}
            onClick={(e) => {
              props.onRemove(props.item.id, props.item.name);
              e.stopPropagation();
            }}
          >
            <DeleteOutlined style={{ fontSize: '20px' }} />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
