import { useState } from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { useGet } from 'restful-react';
import { updateFaqList, createNewFaq, editFaq, removeFaq, IConfirmRemove } from './FaqFunctions';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import FaqListItem from './FaqListItem/FaqListItem';
import { AddFaq, INewFaq } from './AddFaq/AddFaq';
import { ConfirmRemoveModal } from '../../../Components/ConfirmRemoveModal/ConfirmRemoveModal';
import { AdminContainer } from '../Components/AdminContainer/AdminContainer';
import classes from './FaqPage.module.scss';
import { LoadingState } from '../../../Components/LoadingState/LoadingState';

export interface IFaq {
  question: string;
  answer: string;
  category: string;
  id: number;
}

export interface IFaqCategory {
  title: string;
  id: string;
  faqItems: IFaq[];
}

export const categories: IFaqCategory[] = [
  { title: 'CLIENT APPLICATION', id: 'group1', faqItems: [] },
  { title: 'PRODUCTS & CART', id: 'group2', faqItems: [] },
  { title: 'ORDER', id: 'group3', faqItems: [] },
  { title: 'LOGISTICS', id: 'group4', faqItems: [] },
  { title: 'PAYMENT', id: 'group5', faqItems: [] },
  { title: 'COOKIES & PRIVACTY POLICY', id: 'group6', faqItems: [] },
];

const FaqsPage = () => {
  const [currentRow, setCurrentRow] = useState<number | null>(null);
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const [confirmRemoveData, setConfirmRemoveData] = useState<IConfirmRemove | null>(null);
  const [faqs, setFaqs] = useState<IFaq[]>([]);
  const [faqCategories, setFaqCategories] = useState<IFaqCategory[]>(categories);

  const { loading, refetch } = useGet({
    path: '/faqs',
    resolve: (data) => {
      let tempCat = JSON.parse(JSON.stringify(categories));
      data.faqs.forEach((faq: IFaq) => {
        tempCat.find((c: IFaqCategory) => c.id === faq.category)?.faqItems.push(faq);
      });
      setFaqCategories(tempCat);
      console.log(tempCat);
      setFaqs(data.faqs);
    },
  });

  const currentId = (data: number, index: number) => {
    setSelectedRowId(data);
    setCurrentRow(index);
  };

  const confirmRemove = () => {
    if (confirmRemoveData) {
      removeFaq(confirmRemoveData.id);
      if (faqs) {
        const updatedData: IFaq[] = faqs.filter((x: IFaq) => x.id !== confirmRemoveData?.id);
        setFaqs(updatedData);
      }
    }
    setConfirmRemoveData(null);
  };

  const handleRLDDChange = (newOrderdArray: Array<IFaq>) => {
    setFaqs(newOrderdArray);
    let newRowPosition: any;

    newOrderdArray.forEach((item, index = 0) => {
      if (item.id === selectedRowId) {
        newRowPosition = index;
      }
    });

    if (currentRow !== null && currentRow >= 0) {
      if (selectedRowId && currentRow < newRowPosition) {
        let afterId = faqs[newRowPosition].id;
        updateFaqList(afterId, selectedRowId);
      }
      if (selectedRowId && currentRow > newRowPosition) {
        let afterId;
        if (newRowPosition === 0) {
          afterId = faqs[newRowPosition].id;
        } else {
          afterId = faqs[newRowPosition - 1].id;
        }
        updateFaqList(afterId, selectedRowId);
      }
    }
  };

  const handleAddNewFaq = (faq: INewFaq) => {
    createNewFaq(faq, refetch);
  };

  return (
    <AdminContainer>
      <PageHeader title="Faqs" />
      <AddFaq onCreateFaq={(faq: INewFaq) => handleAddNewFaq(faq)} />
      {faqCategories.map((category: IFaqCategory) => {
        return (
          <div className={classes.categoryWrapper}>
            <h2 className={classes.categoryHeader}>{category.title}</h2>
            {!loading ? (
              <RLDD
                items={category.faqItems ?? []}
                itemRenderer={(item: IFaq, index: number) => {
                  return (
                    <FaqListItem
                      item={item}
                      currentIdAndRow={currentId}
                      itemId={item.id}
                      itemIndex={index}
                      onRemove={(id, name) => setConfirmRemoveData({ id, name })}
                      onEditFaq={(faq: IFaq) => editFaq(faq, refetch)}
                    />
                  );
                }}
                onChange={handleRLDDChange}
              />
            ) : (
              <LoadingState text={`Loading ${category.title}...`} />
            )}
          </div>
        );
      })}
      <ConfirmRemoveModal
        itemName={confirmRemoveData?.name ?? ''}
        visible={confirmRemoveData ? true : false}
        onConfirm={() => confirmRemove()}
        onClose={() => setConfirmRemoveData(null)}
      />
    </AdminContainer>
  );
};

export default FaqsPage;
