import { DeleteOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons/lib/icons';
import { Button, Form, Input } from 'antd';
import classes from './AttributesListItem.module.scss';
import style from '../AddAttribute/AddAttribute.module.scss';
import { IAttribute } from '../AttributesPage';
import { useState } from 'react';

interface IAttributeListItem {
  item: IAttribute;
  itemId: number;
  itemIndex: number;
  onRemove: (id: number, name: string) => void;
  currentIdAndRow: (data: number, index: number) => void;
  onEditAttribute: (attribute: IAttribute) => void;
}

const AttributesListItem = (props: IAttributeListItem) => {
  const [editMode, setEditMode] = useState(false);
  const [selectedId] = useState(props.item.id);

  const handleDragItem = () => {
    props.currentIdAndRow(props.itemId, props.itemIndex);
  };

  const onFinishEditForm = (attribute: IAttribute) => {
    const parsedAttributeObject = {
      name: attribute.name,
      unit: attribute.unit,
      id: selectedId,
    };
    props.onEditAttribute(parsedAttributeObject);
    setEditMode(false);
  };

  return (
    <>
      {!editMode ? (
        <div onMouseDown={handleDragItem} className={classes.root}>
          <div className={classes.header}>
            <MenuOutlined style={{ fontSize: '16px' }} />
            <h4>{props.item.name}</h4>
            <span>{props.item.unit && props.item.unit}</span>
          </div>

          <div className={classes.actions}>
            <Button
              aria-label={`Edit ${props.item.name}`}
              onClick={() => setEditMode(true)}
              type="link"
              icon={<EditOutlined style={{ fontSize: '20px' }} />}
            />
            <Button
              aria-label={`Remove ${props.item.name}`}
              onClick={() => props.onRemove(props.item.id, props.item.name)}
              type="link"
              icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
            />
          </div>
        </div>
      ) : (
        <div>
          <Form
            layout="vertical"
            onFinish={onFinishEditForm}
            initialValues={{ name: props.item.name, unit: props.item.unit }}
          >
            <div className={style.addAttributeForm}>
              <div className={style.inputs}>
                <Form.Item
                  label="Attribute name"
                  name="name"
                  rules={[{ required: true, message: 'Please enter a name' }]}
                >
                  <Input autoFocus onClick={(e) => (e.target as HTMLInputElement).focus()} autoComplete="off" />
                </Form.Item>
                <Form.Item label="Attribute unit" name="unit">
                  <Input onClick={(e) => (e.target as HTMLInputElement).focus()} autoComplete="off" />
                </Form.Item>
              </div>
              <Form.Item>
                <Button onClick={() => setEditMode(!editMode)} type="link">
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default AttributesListItem;
