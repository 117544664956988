import { Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useGet } from 'restful-react';
import { IProduct } from '../../../Admin/Products/EditProduct/EditProduct';
import { ProductList } from '../../Components/ProductList/ProductList';
import { IFrontPageProduct } from '../../Interfaces/IProduct';
import classes from './Favourites.module.scss';
import { loadFavouritesFromLocalStorage } from '../../Components/FavouriteButton/FavouriteButton';
import { ConnectionError } from '../../Components/ConnectionError/ConnectionError';

export const Favourites = () => {
  const PAGE: string = 'favourites';
  const [favourites] = useState<number[]>(loadFavouritesFromLocalStorage());
  const [matchedProducts, setMatchedProducts] = useState<IFrontPageProduct[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(20);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(20);

  const { data: products, loading } = useGet({
    path: 'publishedProducts',
    resolve: (data) => data.products,
  });

  const filterProducts = useCallback(
    (array: IProduct[]) => {
      if (!array || array.length < 1) {
        setMatchedProducts([]);
        return;
      }
      let filteredArray = array.filter((product: IProduct) => {
        return favourites.indexOf(product.id) >= 0;
      });
      setMatchedProducts(filteredArray);
    },
    [favourites],
  );

  const getCurrentPageSettings = useCallback(() => {
    const sessionItemPage = sessionStorage.getItem('currentShopPage');
    const sessionItemSize = sessionStorage.getItem('currentShopPageSize');

    if (
      sessionItemPage !== null &&
      sessionItemSize !== null &&
      sessionItemPage.split(',')[2] === PAGE &&
      sessionItemSize.split(',')[2] === PAGE
    ) {
      let sessionPage = parseInt(sessionItemPage.split(',')[0]);
      let sessionSize = parseInt(sessionItemSize.split(',')[0]);

      setCurrentPage(sessionPage);
      setCurrentPageSize(sessionSize);
      setMinIndex((sessionPage - 1) * sessionSize);
      setMaxIndex(sessionPage * sessionSize);
    } else {
      setCurrentPage(1);
      setCurrentPageSize(20);
      setMinIndex(0);
      setMaxIndex(20);
    }
  }, []);

  useEffect(() => {
    !loading && filterProducts(products);
    getCurrentPageSettings();
  }, [products, filterProducts, loading, getCurrentPageSettings]);

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>Favourites</h1>
      </div>
      {loading ? (
        <div className={classes.noFavorites}>
          <Spin />
        </div>
      ) : products ? (
        matchedProducts.length > 0 ? (
          <ProductList
            products={matchedProducts}
            loading={loading}
            page={PAGE}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPage={setCurrentPage}
            setCurrentPageSize={setCurrentPageSize}
            minIndex={minIndex}
            maxIndex={maxIndex}
            setMinIndex={setMinIndex}
            setMaxIndex={setMaxIndex}
          />
        ) : (
          <div className={classes.noFavorites}>
            <p>You have no favourites yet.</p>
          </div>
        )
      ) : (
        <ConnectionError />
      )}
    </div>
  );
};
