import { useGet } from 'restful-react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { useState } from 'react';
import { IListProduct } from '../Products/EditProduct/Tabs/ProductLists/Interfaces/IProductList';
import DraggableProductItem from '../../../Components/DraggableProductItem/DraggableProductItem';
import { AddProductsModal } from '../../../Components/AddProductsModal/AddProductsModal';
import { ConfirmRemoveModal } from '../../../Components/ConfirmRemoveModal/ConfirmRemoveModal';
import { ProductListWrapper } from '../Products/EditProduct/Tabs/ProductLists/Components/ProductListWrapper/ProductListWrapper';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import * as utils from '../../../Utils/SelectableProductsFunctions';
import { Button } from 'antd';
import classes from './FilmEquipmentProducts.module.scss';
import { AdminContainer } from '../Components/AdminContainer/AdminContainer';
import { LoadingState } from '../../../Components/LoadingState/LoadingState';
import { ConnectionError } from '../../Shop/Components/ConnectionError/ConnectionError';

const FilmEquipmentProducts = (props: any) => {
  const FILM_EQUIPMENT = 'FilmEquipment';
  const { loading, refetch } = useGet({
    path: `frontPageProduct?page=${FILM_EQUIPMENT}`,
    resolve: (data) => {
      setFilmEquipmentProducts(data.products);
      setInitialProducts(data.products);
    },
  });

  const clearState: IListProduct = {
    name: '',
    id: 0,
  };

  const [confirmRemoveItem, setConfirmRemoveItem] = useState<IListProduct>(clearState);
  const [addProductsModalOpen, setAddProductsModalOpen] = useState(false);
  const [filmEquipmentProducts, setFilmEquipmentProducts] = useState<IListProduct[]>([]);
  const [initialProducts, setInitialProducts] = useState<IListProduct[]>([]);
  const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false);

  // Removes the confirmed item from the list display
  const removeItem = (id: number) => {
    removeFilmEquipmentProduct(id);
    setConfirmRemoveItem(clearState);
    setHasMadeChanges(true);
  };

  const onUpdateItems = (items: IListProduct[]) => {
    items.forEach((element) => {
      setFilmEquipmentProducts([...filmEquipmentProducts, element]);
    });
    setAddProductsModalOpen(false);
    setHasMadeChanges(true);
  };

  // Handle the Drag and drop change, set the products-array to new order
  const handleRLDDChange = (newOrderdArray: IListProduct[]) => {
    setFilmEquipmentProducts(newOrderdArray);
    setHasMadeChanges(true);
  };

  // Filter products-state to remove the confirmed item
  const removeFilmEquipmentProduct = (id: number) => {
    let newArray = filmEquipmentProducts?.filter(function (item: IListProduct) {
      return item.id !== id;
    });
    setFilmEquipmentProducts(newArray);
  };

  const addFilmEquipmentProduct = (product: IListProduct) => {
    if (!filmEquipmentProducts.find((element) => element.productId === product.id)) {
      setFilmEquipmentProducts([...filmEquipmentProducts, product]);
      setHasMadeChanges(true);
    } else return;
  };

  const saveOrder = () => {
    utils.updateProducts(
      FILM_EQUIPMENT,
      filmEquipmentProducts.map((p) => p.productId ?? p.id),
      () => {
        refetch();
        setHasMadeChanges(false);
      },
    );
  };

  return (
    <AdminContainer>
      <PageHeader title="Film Equipment Products" />
      <div className={classes.root}>
        <AddProductsModal
          visible={addProductsModalOpen}
          onClose={() => setAddProductsModalOpen(false)}
          onConfirm={(items: IListProduct[]) => onUpdateItems(items)}
          selectedProducts={props.products ?? []}
        />
        <ProductListWrapper
          onAddProducts={() => setAddProductsModalOpen(true)}
          addToList={(items: IListProduct) => addFilmEquipmentProduct(items)}
        >
          {loading ? (
            <LoadingState text="Loading Film Equipment Products..." />
          ) : filmEquipmentProducts && filmEquipmentProducts.length > 0 ? (
            <RLDD
              items={filmEquipmentProducts}
              onChange={handleRLDDChange}
              itemRenderer={(item: IListProduct, index: number) => {
                return (
                  <DraggableProductItem
                    item={item}
                    itemId={item.id}
                    itemIndex={index}
                    onRemove={(id, name) => setConfirmRemoveItem({ id, name })}
                  />
                );
              }}
            />
          ) : (
            <ConnectionError />
          )}
        </ProductListWrapper>
        <ConfirmRemoveModal
          visible={confirmRemoveItem.id ? true : false}
          onConfirm={() => confirmRemoveItem.id && removeItem(confirmRemoveItem.id)}
          onClose={() => setConfirmRemoveItem(clearState)}
          itemName={confirmRemoveItem.name}
        />
        <div className={classes.sticky}>
          <Button
            onClick={() => {
              setFilmEquipmentProducts(initialProducts);
              setHasMadeChanges(false);
            }}
            type="link"
            danger
            disabled={!hasMadeChanges}
          >
            Discard changes
          </Button>
          <Button type="primary" onClick={() => saveOrder()} disabled={!hasMadeChanges}>
            Save products
          </Button>
        </div>
      </div>
    </AdminContainer>
  );
};
export default FilmEquipmentProducts;
