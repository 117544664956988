import { DownOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { ICategoryProducts } from '../../Pages/Categories/Categories';
import classes from './SideMenu.module.scss';
import componentClasses from '../../../../styles/Components.module.scss';
import { paths } from '../../../../routes/paths';
import VideocamIcon from '@mui/icons-material/Videocam';
import SpeakerIcon from '@mui/icons-material/Speaker';
import { useLocation } from 'react-router';
import { SubTree } from './SubTree/SubTree';
import breakPoints from '../../../../Utils/breakPoints';
import { useWindowDimensions } from '../../../../Utils/Hooks/useWindowDimensions';
import { DrawingAreaContext, DrawingAreaTypes } from '../../../../Utils/Providers/DrawingAreaContextProvider';

export interface ISideMenu {
  data: ICategoryProducts[];
  loading?: boolean;
}

export const SideMenu = (props: ISideMenu) => {
  const [tab, setTab] = useState('');
  const [dataNodes, setDataNodes] = useState<DataNode[] | undefined>();
  const icons = [<VideocamIcon />, <SpeakerIcon />];
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { data } = props;
  const [documentHeight, setDocumentHeight] = useState(window.innerHeight);
  const { state: drawingArea, dispatch } = useContext(DrawingAreaContext);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [oldLocation, setOldLocation] = useState('');

  useEffect(() => {
    function getDataNode(categoriesWithProducts: ICategoryProducts[]): DataNode[] {
      const dataNode = categoriesWithProducts?.map((category: ICategoryProducts) => {
        return {
          key: category.id,
          title: category.name,
          children: getDataNode(category.subCategories ?? []),
        };
      });
      return dataNode;
    }

    setDataNodes(getDataNode(data));
  }, [tab, data]);

  useEffect(() => {
    if (location.pathname.includes(paths.categoryBase) && oldLocation !== location.pathname) {
      setOldLocation(location.pathname);
      if (window.innerWidth >= breakPoints.lg) dispatch({ payload: true, type: DrawingAreaTypes.UpdateLeft });

      const categoryId = parseInt(location.pathname.replace(paths.categoryBase, ''));

      if (data) {
        const recursiveFindNode = (category: ICategoryProducts, targetId: number) => {
          if (category.id === targetId) return true;
          else if (!category.subCategories) return false;
          else {
            let foundTargetId = false;
            category.subCategories.forEach((subCategory) => {
              foundTargetId = foundTargetId || recursiveFindNode(subCategory, targetId);
              if (foundTargetId) return true;
            });
            return foundTargetId;
          }
        };

        if (recursiveFindNode(data[0], categoryId)) {
          setTab(data[0].name);
        } else {
          setTab(data[1].name);
        }
      }
    }
  }, [location, data, dispatch, oldLocation]);

  useEffect(() => {
    if (window.innerWidth <= breakPoints.lg) dispatch({ payload: false, type: DrawingAreaTypes.UpdateLeft });
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (drawingArea.left) {
      buttonRef.current?.focus();
    }
  }, [drawingArea.left]);

  const handleButtonClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, node: ICategoryProducts) => {
    if (node.name === drawingArea.selectedTab && location.pathname === paths.categoryBase + node.id) {
      setTab('');
      dispatch({ payload: '', type: DrawingAreaTypes.UpdateSelectedTab });
    } else {
      setTab(node.name);
      setOldLocation(location.pathname);
      dispatch({ payload: node.name, type: DrawingAreaTypes.UpdateSelectedTab });
    }
    dispatch({ payload: { selectedKeys: [], expandedKeys: [] }, type: DrawingAreaTypes.UpdateAVKeys });
    dispatch({ payload: { selectedKeys: [], expandedKeys: [] }, type: DrawingAreaTypes.UpdateFilmKeys });
    if (width < breakPoints.lg && e.target.toString() !== '[object SVGSVGElement]') {
      dispatch({ payload: false, type: DrawingAreaTypes.UpdateLeft });
    }
    navigate(`${paths.categoryBase}${node.id}`);
  };

  const addCategories = () => {
    if (props.loading)
      return (
        <div className={classes.error}>
          <Spin />
        </div>
      );
    if (!props.data)
      return (
        <div className={classes.error}>
          <p>Could not load categories, please try again later.</p>
        </div>
      );
    return (
      <div>
        {props.data?.map((node, index) => (
          <>
            <div className={classes.tabs}>
              <Button
                key={`${node.id}`}
                className={`${componentClasses.button} ${
                  node.name === tab
                    ? componentClasses.buttonPrimary + ' ' + classes.tabButtonSelected
                    : componentClasses.buttonText
                } ${classes.tabButton} `}
                onClick={(e) => handleButtonClick(e, node)}
                type={node.name === tab ? 'primary' : 'default'}
                icon={icons.length > index && icons[index]}
                ref={node.name === tab || (index === 0 && tab === '') ? buttonRef : undefined}
              >
                {node.name}
                <DownOutlined className={tab === node.name ? classes.tabArrowIconUp : classes.tabArrowIconDown} />
              </Button>
            </div>
            {dataNodes && dataNodes[index].children && (
              <SubTree
                avSubtree={index !== 0}
                dataNodes={dataNodes[index].children ?? ([] as DataNode[])}
                showSelf={tab === node.name}
                setShowParent={(show) => {
                  dispatch({ payload: show, type: DrawingAreaTypes.UpdateLeft });
                }}
                isUnderBreakpoint={width < breakPoints.lg}
              />
            )}
          </>
        ))}
      </div>
    );
  };

  const handleNavButtonClick = (path: string) => {
    if (width < breakPoints.lg) dispatch({ payload: false, type: DrawingAreaTypes.UpdateLeft });
    navigate(path);
  };

  const resize = () => {
    setDocumentHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return (
    /* To add back the animation only change the hidden attribute to aria-hidden */
    <aside hidden={!drawingArea.left} className={`${classes.root} ${!drawingArea.left ? classes.hidden : ''}`}>
      <div className={classes.fixed} style={{ '--documentHeight': documentHeight + 'px' } as React.CSSProperties}>
        {addCategories()}
        <div className={classes.navLinks} style={{ '--documentHeight': documentHeight + 'px' } as React.CSSProperties}>
          <Button
            type="text"
            className={`${componentClasses.buttonText} ${classes.buttonLink}`}
            onClick={() => handleNavButtonClick(paths.faq)}
          >
            FAQ
          </Button>
          <Button
            type="text"
            className={`${componentClasses.buttonText} ${classes.buttonLink}`}
            onClick={() => handleNavButtonClick(paths.contact)}
          >
            Contact
          </Button>
        </div>
      </div>
    </aside>
  );
};
