import { Carousel } from 'antd';
import { useGet } from 'restful-react';
import { AppConfig } from '../../../../../config';
import { ProductList } from '../../../Components/ProductList/ProductList';
import classes from './LandingPage.module.scss';
import { useNavigate } from 'react-router';

interface ILandingPage {
  title: string;
}

interface ICarouselItem {
  id: number;
  image: string;
  link: string;
  sortOrder: number;
}

export const LandingPage = (props: ILandingPage) => {
  const navigate = useNavigate();
  const { data: featuredProducts } = useGet({
    path: `/frontPageProduct?page=${props.title.replace(' ', '')}`,
    resolve: (data) => {
      return data.products.map((product: any) => {
        return { ...product, id: product.productId, mainProduct: true };
      });
    },
  });

  const { data: carouselImages } = useGet({
    path: `/carousel?page=${props.title.replace(' ', '')}`,
    resolve: (data) => data.carouselImages,
  });

  return (
    <>
      <div className={classes.titleContainer}>
        <h1>{props.title}</h1>
      </div>
      {carouselImages && carouselImages.length > 0 && (
        <Carousel className={classes.landingPageCarousel} autoplay autoplaySpeed={5000}>
          {carouselImages.map((item: ICarouselItem) => (
            <div>
              <div
                className={classes.carouselImage}
                onClick={() => navigate(item.link.charAt(0) === '/' ? item.link : '/' + item.link)}
                style={{ backgroundImage: `url("${AppConfig.CAROUSEL_IMAGE_BLOB + item.image}")` }}
              ></div>
            </div>
          ))}
        </Carousel>
      )}
      {featuredProducts && <ProductList products={featuredProducts} />}
    </>
  );
};
