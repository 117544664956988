import { Container } from '../../Components/Container/Container';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import classes from './Faq.module.scss';
import { FaqGroupComponent } from './FaqGroup/FaqGroupComponent';
import { ContactInfoBox } from '../../Components/ContactInfoBox/ContactInfoBox';
import { Button, Spin } from 'antd';
import * as data from './QuestionGroups.json';
import { getDocumentLink, documentsObject } from '../../../../Utils/generalDocumentFetchers';
import { useGet } from 'restful-react';
import { ConnectionError } from '../../Components/ConnectionError/ConnectionError';

export const Faq = () => {
  const { data: generalDocuments, loading } = useGet({
    path: '/generalDocuments',
  });

  return (
    <Container>
      <h1>FAQ</h1>
      {!loading ? (
        generalDocuments ? (
          <div className={classes.boxContainer}>
            <div className={classes.resourceList}>
              <Button
                className={classes.button}
                href={getDocumentLink(documentsObject.CLIENT_APPLICATION_FORM, generalDocuments)}
                target="_blank"
                type="text"
                icon={<InsertDriveFileIcon />}
              >
                Client Application Form
              </Button>
              <Button
                className={classes.button}
                href={getDocumentLink(documentsObject.RENTAL_TERMS_SWE, generalDocuments)}
                target="_blank"
                type="text"
                icon={<InsertDriveFileIcon />}
              >
                Rental Terms
              </Button>
            </div>
            <ContactInfoBox />
          </div>
        ) : (
          <ConnectionError />
        )
      ) : (
        <div className={classes.spinnerWrapper}>
          <p>Loading...</p>
          <Spin />
        </div>
      )}
      <FaqGroupComponent faqList={data.questionsArray} generalDocuments={generalDocuments} loading={loading} />
    </Container>
  );
};
