import { AboutArea } from '../../Components/AboutArea/AboutArea';
import { Hero } from '../../Components/Hero/Hero';
import { CategoryCard } from './CategoryCard/CategoryCard';
import { useState } from 'react';
import { paths } from '../../../../routes/paths';
import classes from './Home.module.scss';
import bgLarge from '../../../../assets/Hyrmax_hero.jpg';
import bgMedium from '../../../../assets/Hyrmax_hero_md.jpg';
import bgSmall from '../../../../assets/Hyrmax_hero_sm.jpg';
import { ICategoryProducts } from '../Categories/Categories';

interface IHomePage {
  data: ICategoryProducts[];
  loading: boolean;
}

export const Home = (props: IHomePage) => {
  const texts = [
    `Camera, lighting, grip, audio, stills and other gear for your next project`,
    'Displays, projectors, projection screens, cameras, wireless audio and other dry-hire solutions for your next production',
  ];

  const [categoryCards] = useState<JSX.Element[]>([
    <CategoryCard key={0} title="Film Equipment" text={texts[0]} path={paths.categoryBase + 50} inverted={true} />,
    <CategoryCard key={0} title="Audiovisual" text={texts[1]} path={paths.categoryBase + 1} />,
  ]);

  return (
    <div className={classes.root}>
      <Hero
        title={
          <>
            Welcome to <span style={{ display: 'inline-block' }}>The Professional&#8217;s Supplier&trade;</span>
          </>
        }
        subtitle="Your trusted dry-hire rental supplier of Film &amp; AV equipment"
        textAlign="left"
        images={[{ src: bgLarge, minWidth: '1000px' }, { src: bgMedium, minWidth: '600px' }, { src: bgSmall }]}
      />
      <div className={`${classes.cardContainer} ${categoryCards.length % 2 === 0 && classes.cardContainerEven}`}>
        <div className={classes.cardPadding} />
        <div className={classes.cardWrapper}>{categoryCards.map((card: JSX.Element) => card)}</div>
        <div className={`${classes.cardPadding} ${categoryCards.length % 2 === 0 && classes.cardPaddingEven}`} />
      </div>
      <AboutArea />
    </div>
  );
};
