import { Button } from 'antd';
import { useNavigate } from 'react-router';
import classes from './CategoryCard.module.scss';
import componentClasses from './../../../../../styles/Components.module.scss';
import { RightOutlined } from '@ant-design/icons';

interface ICategoryCard {
  title: string;
  text: string;
  inverted?: boolean;
  path: string;
}

export const CategoryCard = (props: ICategoryCard) => {
  const navigate = useNavigate();
  return (
    <div className={`${classes.root} ${props.inverted ? classes.cardInverted : classes.cardRegular}`}>
      <h2 className={classes.header}>{props.title}</h2>
      <p className={classes.text}>{props.text}</p>
      <Button
        type="primary"
        icon={<RightOutlined />}
        className={`${classes.button} ${
          props.inverted ? componentClasses.buttonPrimaryInverted : componentClasses.buttonPrimary
        }`}
        onClick={() => navigate(props.path)}
      >
        Rent {props.title.toLocaleLowerCase().includes('equipment') ? props.title : props.title + ' Equipment'}
      </Button>
    </div>
  );
};
