import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useContext, useState } from 'react';
import classes from './AddToCart.module.scss';
import { addProductToCart } from '../../../../Pages/Cart/Functions/add-product-to-cart';
import componentClasses from '../../../../../../styles/Components.module.scss';
import { CartContext, CartContextType } from '../../../../../../Utils/Providers/CartContextProvider';
import { cartKeyWords } from '../../../../Pages/Cart/Other/keywords';
import { IProductInCart } from '../../../../Pages/Cart/CartComponent';

interface IAddToCart {
  product: IProductInCart;
  padding?: boolean;
  isInCard?: boolean;
  isCurrentProduct?: boolean;
}

export const AddToCart = (props: IAddToCart) => {
  // Get cart from context
  const { setCart } = useContext(CartContext) as CartContextType;

  const onDecrease = () => {
    setValue(value - 1);
  };

  const onIncrease = () => {
    setValue(value + 1);
  };

  const [value, setValue] = useState(1);

  const addToCart = (value: number, product: IProductInCart) => {
    addProductToCart(!isNaN(value) ? value : 1, product);
    setValue(1);

    const currentCart = JSON.parse(localStorage.getItem(cartKeyWords.localStorageKey) ?? '');
    setCart(currentCart);
  };

  const onInputBlur = (value: string) => {
    let valueNum = parseInt(value);
    if (!valueNum || valueNum < 1) setValue(1);
  };

  const bigButton = {
    padding: '0.6rem 1.8rem',
    lineHeight: '1.35em',
    fontSize: '1.05rem',
  };

  return (
    <div className={classes.actionsContainer + ` ${props.isInCard && classes.inCard}`}>
      <div className={classes.inputWrapper}>
        <Button
          disabled={value < 2 || isNaN(value)}
          icon={<MinusOutlined />}
          onClick={onDecrease}
          aria-label={`Decrease number of items in cart. Current value: ${value}`}
        />
        <Input
          type="number"
          className={classes.input}
          value={value}
          min={1}
          onBlur={(e) => onInputBlur(e.target.value)}
          onChange={(e) => setValue(parseInt(e.target.value))}
        />
        <Button
          aria-label={`Increase number of items in cart. Current value: ${value}`}
          icon={<PlusOutlined />}
          onClick={onIncrease}
        />
      </div>
      <Button
        type="primary"
        style={props.isCurrentProduct ? bigButton : undefined}
        onClick={() => addToCart(value, props.product)}
        className={`${componentClasses.button} ${componentClasses.buttonPrimary} ${classes.addButton} ${
          props.padding && classes.addButtonPadding
        }`}
      >
        Add
      </Button>
    </div>
  );
};
