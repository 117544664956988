import { DeleteOutlined, EditOutlined, PaperClipOutlined } from '@ant-design/icons/lib/icons';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { useState } from 'react';
import { headers } from '../../api';
import { AppConfig } from '../../config';
import { IDocument } from '../DocumentUpload/DocumentUpload';
import classes from './DocumentListItem.module.scss';

interface IDocumentListItem {
  item: IDocument;
  onRemove: (document: IDocument) => void;
  reloadDocumentsList: () => void;
}

export const DocumentListItem = (props: IDocumentListItem) => {
  const [editMode, setEditMode] = useState(false);
  const [selectedId] = useState(props.item.id);

  const onFinishEditForm = (document: IDocument) => {
    const parsedEditDocumentObject = {
      name: document.name,
      id: selectedId,
    };

    fetch(`${AppConfig.API_URL}documents/${parsedEditDocumentObject.id}?name=${parsedEditDocumentObject.name}`, {
      method: 'PUT',
      headers: headers,
      credentials: 'include',
    })
      .then(() => props.reloadDocumentsList())
      .then(() => setEditMode(false));
  };

  return (
    <>
      {!editMode ? (
        <Row className={classes.root}>
          <Col span={8}>{props.item.name}</Col>
          <Col span={8}>
            <PaperClipOutlined />
            <Typography.Link> {props.item.name}</Typography.Link>
          </Col>
          <Col span={8} className={classes.actions}>
            <Button
              aria-label={`Edit ${props.item.name}`}
              onClick={() => setEditMode(true)}
              type="link"
              icon={<EditOutlined style={{ fontSize: '20px' }} />}
            />
            <Button
              aria-label={`Remove ${props.item.name}`}
              onClick={() => props.onRemove(props.item)}
              type="link"
              danger
              icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
            />
          </Col>
        </Row>
      ) : (
        <Form
          className={classes.editDocumentForm}
          layout="vertical"
          onFinish={onFinishEditForm}
          initialValues={{ name: props.item.name }}
        >
          <Col span={8} className={classes.inputs}>
            <Form.Item name="name" rules={[{ required: true, message: 'Please enter a name' }]}>
              <Input autoFocus autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Typography.Link>
              <PaperClipOutlined /> {props.item.name}
            </Typography.Link>
          </Col>
          <Col span={8} className={classes.actions}>
            <Form.Item>
              <Button onClick={() => setEditMode(!editMode)} type="link">
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Form>
      )}
    </>
  );
};
