import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import { FaqGenerateLink } from '../FaqGenerateLink/FaqGenerateLink';
import { categories, IFaqCategory } from '../FaqPage';
import classes from './AddFaq.module.scss';

interface IAddFaq {
  onCreateFaq: (Faq: INewFaq) => void;
}

export interface INewFaq {
  question: string;
  answer: string;
  category: string;
}

export const AddFaq = (props: IAddFaq) => {
  const [showForm, setShowForm] = useState(false);
  const [category, setCategory] = useState<string>('group1');
  const { Option } = Select;

  const onFinishForm = async (newFaq: INewFaq) => {
    props.onCreateFaq(newFaq);
    setShowForm(false);
  };

  return (
    <div className={classes.root}>
      {!showForm ? (
        <div className={classes.headerButtons}>
          <FaqGenerateLink />
          <Button onClick={() => setShowForm(!showForm)} type="primary" icon={<PlusOutlined />}>
            Add New Faq
          </Button>
        </div>
      ) : (
        <div>
          <Form layout="vertical" onFinish={onFinishForm}>
            <div className={classes.addFaqForm}>
              <div className={classes.inputs}>
                <Form.Item label="Question" name="question" rules={[{ required: true, message: 'Question' }]}>
                  <TextArea autoFocus autoComplete="off" className={classes.textArea} />
                </Form.Item>
                <Form.Item label="Answer" name="answer" rules={[{ required: true, message: 'Answer' }]}>
                  <TextArea autoComplete="off" className={classes.textArea} />
                </Form.Item>
                <Form.Item
                  label="Category"
                  className={classes.category}
                  name="category"
                  rules={[{ required: true, type: 'string', message: 'Category' }]}
                >
                  <Select
                    value={category}
                    onChange={(groupId: string) => setCategory(groupId)}
                    placeholder="Please select"
                  >
                    {categories.map((o: IFaqCategory) => {
                      return <Option value={o.id}>{o.title}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </div>
              <Form.Item>
                <div className={classes.buttonGroup}>
                  <FaqGenerateLink />
                  <span className={classes.saveRow}>
                    <Button onClick={() => setShowForm(!showForm)} type="link">
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                      Add
                    </Button>
                  </span>
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};
