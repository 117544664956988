import { ICart, IProductInCart } from '../CartComponent';

export const getCartWithoutRemovedGroup = (cartGroupId: number, cart: ICart) => {
  // Get the products in the group that will be removed
  const products: IProductInCart[] | undefined = cart.cartGroups.find((x) => x.id === cartGroupId)?.products;

  // Get cart
  let copyOfCart: ICart = cart;

  // Push the products into the 'ungrouped'-group.
  if (products) {
    copyOfCart.ungroupedProducts.push(...products);
  }

  // Remove the group
  const cartGroupsWithoutRemovedGroup = copyOfCart.cartGroups.filter((x) => x.id !== cartGroupId);

  // Return the whole cart without the group
  const cartWithoutRemovedGroup: ICart = {
    ...cart,
    cartGroups: cartGroupsWithoutRemovedGroup,
    ungroupedProducts: copyOfCart.ungroupedProducts,
  };

  return cartWithoutRemovedGroup;
};
