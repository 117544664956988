import { ICart, ICartGroup, IProductInCart } from '../CartComponent';

// Remove product from cart if the product is ungrouped
export const getCartWithoutRemovedProductFromUngrouped = (cart: ICart, uniqueProductId: number) => {
  // Remove the product and return the whole cart
  const cartWithoutRemovedProductFromUngrouped: ICart = {
    ...cart,
    ungroupedProducts: cart.ungroupedProducts.filter((x) => x.uniqueProductId !== uniqueProductId),
  };

  return cartWithoutRemovedProductFromUngrouped;
};

// Remove product from cart if the product is grouped
export const getCartWithoutRemovedProductFromGrouped = (
  cart: ICart,
  uniqueProductId: number,
  productGroupName: string,
) => {
  // Filter product to keep the ones you didn't remove
  const filterProducts = (products: IProductInCart[], groupName: string) => {
    if (groupName === productGroupName) {
      return products.filter((x) => x.uniqueProductId !== uniqueProductId);
    } else return products;
  };

  // Create new cart group
  const cartGroups: ICartGroup[] = cart.cartGroups.map((x: ICartGroup) => {
    return {
      id: x.id,
      cartId: x.cartId,
      cartGroupName: x.cartGroupName,
      products: filterProducts(x.products, x.cartGroupName),
    };
  });

  // Remove the product and return the whole cart
  const cartWithoutRemovedProductFromGrouped: ICart = {
    ...cart,
    cartGroups: cartGroups,
  };

  return cartWithoutRemovedProductFromGrouped;
};
