import { DeleteOutlined, FileTwoTone, InboxOutlined } from '@ant-design/icons/lib/icons';
import { Button, List, Upload } from 'antd';
import classes from './DocumentUpload.module.scss';
interface IDocumentUpload {
  documentFileList: File[];
  setDocumentFileList: (list: File[]) => void;
}

export interface IDocument {
  id: number;
  name: string;
  documentLink: string;
}

const DocumentUpload = (props: IDocumentUpload) => {
  const { Dragger } = Upload;

  const handleDeleteUploadedDocument = (removedFile: File) => {
    let tempArray = props.documentFileList.filter((item) => item !== removedFile);
    props.setDocumentFileList(tempArray);
  };

  return (
    <div>
      <Dragger
        beforeUpload={(file) => {
          props.setDocumentFileList([...props.documentFileList, file]);
          return false;
        }}
        showUploadList={false}
        listType="picture"
      >
        <div>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files.
          </p>
        </div>
      </Dragger>
      {props.documentFileList.length > 0 && (
        <List
          className={classes.uploadList}
          size="large"
          bordered
          dataSource={props.documentFileList}
          renderItem={(item) => (
            <List.Item
              className={classes.uploadListItem}
              extra={[
                <Button
                  type="link"
                  danger
                  icon={<DeleteOutlined className={classes.listIcon} />}
                  onClick={() => handleDeleteUploadedDocument(item)}
                />,
              ]}
            >
              <List.Item.Meta avatar={<FileTwoTone className={classes.listIcon} />} title={item.name} />
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default DocumentUpload;
