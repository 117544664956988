import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons/lib/icons';
import { Button, Input } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import classes from './SubPageHeader.module.scss';

export interface IPageSubHeader {
  title?: string;
  editName: (name: string) => void;
}

const PageSubHeader = (props: IPageSubHeader) => {
  let navigate = useNavigate();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [newCategoryName, setNewCategoryName] = useState('');

  const inputOnChangeHandler = (name: string) => {
    setNewCategoryName(name);
  };
  const save = () => {
    props.editName(newCategoryName);
    setEditMode(false);
  };
  return (
    <div className={classes.root}>
      {editMode ? (
        <>
          <Input
            type="text"
            defaultValue={props.title}
            autoFocus
            onChange={(event) => {
              inputOnChangeHandler(event.currentTarget.value);
            }}
          />
          <Button type="link" onClick={() => setEditMode(false)}>
            Cancel
          </Button>
          <Button disabled={newCategoryName.length < 1} type="primary" onClick={save}>
            Save
          </Button>
        </>
      ) : (
        <>
          <Button onClick={() => navigate(-1)} type="link" shape="circle" icon={<ArrowLeftOutlined />} />
          <h2>{props.title}</h2>
          <Button type="link" onClick={() => setEditMode(true)}>
            <EditOutlined style={{ fontSize: '20px' }} />
          </Button>
        </>
      )}
    </div>
  );
};

export default PageSubHeader;
