import { message } from 'antd';
import 'antd/dist/antd.less';
import { Routes } from './routes/Routes';
import { CartContextProvider } from './Utils/Providers/CartContextProvider';
import { DrawingAreaProvider } from './Utils/Providers/DrawingAreaContextProvider';

function App() {
  message.config({ maxCount: 5 });
  return (
    <CartContextProvider>
      <DrawingAreaProvider>
        <Routes />
      </DrawingAreaProvider>
    </CartContextProvider>
  );
}

export default App;
