import { EditOutlined, DeleteOutlined } from '@ant-design/icons/lib/icons';
import { Button, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import { headers } from '../../../api';
import { onLogout } from '../../../Components/Header/Header';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import { AppConfig } from '../../../config';
import { AdminContainer } from '../Components/AdminContainer/AdminContainer';

const Users = () => {
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    fetch(`${AppConfig.API_URL}users`, {
      method: 'GET',
      headers: headers,
      credentials: 'include',
    })
      .then((res) => {
        if (res.status === 401) {
          return onLogout();
        }
        return res.json();
      })
      .then((data) => {
        setUsersList(data.users);
      });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'x',
      render: (id: number, item: any) => (
        <Space size="small">
          <Button type="link" disabled icon={<EditOutlined style={{ fontSize: '20px' }} />} />
          <Button type="link" disabled icon={<DeleteOutlined style={{ fontSize: '20px' }} />} />
        </Space>
      ),
    },
  ];

  return (
    <AdminContainer>
      <PageHeader title="Users" search />
      <Table
        rowKey="id"
        rowSelection={{
          type: 'checkbox',
        }}
        dataSource={usersList}
        className="padding-x"
        columns={columns}
      />
    </AdminContainer>
  );
};

export default Users;
