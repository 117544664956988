import classes from './SideCart.module.scss';
import componentClasses from '../../../../styles/Components.module.scss';
import { Button } from 'antd';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router';
import { paths } from '../../../../routes/paths';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CartComponent } from '../../Pages/Cart/CartComponent';
import { CartContext, CartContextType } from '../../../../Utils/Providers/CartContextProvider';
import { useLocation } from 'react-router';
import breakPoints from '../../../../Utils/breakPoints';
import { DrawingAreaContext, DrawingAreaTypes } from '../../../../Utils/Providers/DrawingAreaContextProvider';

export const SideCart = () => {
  const { state: drawingArea, dispatch } = useContext(DrawingAreaContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [documentHeight, setDocumentHeight] = useState(window.innerHeight);

  const { itemsInCart } = useContext(CartContext) as CartContextType;

  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (drawingArea.right) {
      buttonRef.current?.focus();
    }
  }, [drawingArea.right]);

  const asideRef = useRef<any>(null);
  useEffect(() => {
    const state = location.state as any;
    if (state?.openCart) {
      dispatch({ payload: true, type: DrawingAreaTypes.UpdateRight });
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (window.innerWidth <= breakPoints.lg) dispatch({ payload: false, type: DrawingAreaTypes.UpdateRight });
  }, [dispatch, location.pathname]);

  useEffect(() => {
    window.addEventListener('resize', () => setDocumentHeight(window.innerHeight));
    return () => {
      window.removeEventListener('resize', () => setDocumentHeight(window.innerHeight));
    };
  }, []);

  return (
    <aside hidden={!drawingArea.right} className={classes.root}>
      <div
        className={classes.fixed}
        ref={asideRef}
        style={{ '--documentHeight': documentHeight + 'px' } as React.CSSProperties}
      >
        {itemsInCart && location.pathname !== paths.cart ? (
          <Button
            icon={<ArrowForwardIcon />}
            onClick={() => navigate(paths.cart, { state: location.pathname })}
            className={`${componentClasses.buttonPrimary} ${classes.checkoutButton}`}
            type="primary"
            ref={buttonRef}
          >
            Go to Checkout ({itemsInCart})
          </Button>
        ) : null}
        <CartComponent isAside wrapperRef={asideRef} />
      </div>
    </aside>
  );
};
