import { useContext, useEffect, useState } from 'react';
import { Footer } from '../Footer/Footer';
import { Navbar } from '../Navbar/Navbar';
import { SideMenu } from '../SideMenu/SideMenu';
import classes from './ShopContainer.module.scss';
import { cartKeyWords } from '../../Pages/Cart/Other/keywords';
import { emptyCart } from '../../Pages/Cart/Other/empty-cart';
import { SideCart } from '../SideCart/SideCart';
import { useWindowDimensions } from '../../../../Utils/Hooks/useWindowDimensions';
import { ICategoryProducts } from '../../Pages/Categories/Categories';
import breakPoints from '../../../../Utils/breakPoints';
import { useLocation } from 'react-router';
import { DrawingAreaContext, DrawingAreaTypes } from '../../../../Utils/Providers/DrawingAreaContextProvider';
import { SideMenuMobile } from '../SideMenu/SideMenuMobile';
import { SideCartMobile } from '../SideCart/SideCartMobile';

interface IShopContainer {
  data: ICategoryProducts[];
  loading: boolean;
  children: JSX.Element;
}

export const ShopContainer = (props: IShopContainer) => {
  const [documentHeight, setDocumentHeight] = useState(window.innerHeight);
  const { state: drawingArea, dispatch } = useContext(DrawingAreaContext);
  const { data: categoriesWithProducts } = props;
  const location = useLocation();
  const BREAK_POINT = 1300;

  // Create a cart in localStorage if no one exits
  if (!localStorage.getItem('cart')) {
    localStorage.setItem(cartKeyWords.localStorageKey, JSON.stringify(emptyCart));
  }

  const dimension = useWindowDimensions();

  useEffect(() => {
    if (dimension.width < BREAK_POINT && drawingArea.right) {
      dispatch({ payload: false, type: DrawingAreaTypes.UpdateLeft });
    }
  }, [dimension, dispatch, drawingArea.right]);

  useEffect(() => {
    if (dimension.width < BREAK_POINT && drawingArea.left) {
      dispatch({ payload: false, type: DrawingAreaTypes.UpdateRight });
    }
  }, [drawingArea.left, dimension, dispatch]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  const resize = () => {
    setDocumentHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  if (dimension.width < breakPoints.lg)
    return (
      <div className={`${classes.root}`} style={{ '--documentHeight': documentHeight + 'px' } as React.CSSProperties}>
        <Navbar />
        <main
          className={`${classes.main}`}
          style={{ '--documentHeight': documentHeight + 'px' } as React.CSSProperties}
        >
          {drawingArea.left || drawingArea.right ? (
            drawingArea.right ? (
              <SideCartMobile />
            ) : (
              <SideMenuMobile data={categoriesWithProducts} loading={props.loading} />
            )
          ) : null}
          <div className={drawingArea.left || drawingArea.right ? classes.hide : classes.show}>{props.children}</div>
        </main>
        {!(drawingArea.left || drawingArea.right) && <Footer />}
      </div>
    );

  return (
    <div className={`${classes.root}`} style={{ '--documentHeight': documentHeight + 'px' } as React.CSSProperties}>
      <Navbar />
      <main className={`${classes.main} `} style={{ '--documentHeight': documentHeight + 'px' } as React.CSSProperties}>
        <SideMenu data={categoriesWithProducts} loading={props.loading} />
        <div
          className={
            dimension.width < breakPoints.lg && (drawingArea.left || drawingArea.right)
              ? classes.hidePageContainer
              : classes.pageContainer
          }
          style={{ '--documentHeight': documentHeight + 'px' } as React.CSSProperties}
        >
          {props.children}
        </div>
        <SideCart />
      </main>
      <Footer />
    </div>
  );
};
