import { ProductInCart } from './CartGroup/ProductInCart/ProductInCart';
import { CartGroup } from './CartGroup/CartGroup';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { ShareCart } from './ShareCart/ShareCart';
import classes from './CartComponent.module.scss';
import { replaceLocalStorage } from './Functions/replace-local-storage';
import { getCartWithNewGroup } from './Functions/get-cart-with-new-group';
import { getCartWithoutRemovedGroup } from './Functions/get-cart-without-removed-group';
import {
  getCartWithoutRemovedProductFromUngrouped,
  getCartWithoutRemovedProductFromGrouped,
} from './Functions/get-cart-without-removed-product';
import {
  getCartWithUpdatedProductValueForUngrouped,
  getCartWithUpdatedProductValueForGrouped,
} from './Functions/get-cart-with-updated-product-value';
import {
  getCartWithMovedProductToGrouped,
  getCartWithMovedProductToUngrouped,
} from './Functions/get-cart-with-moved-product';
import { cartKeyWords } from './Other/keywords';
import { CartContext, CartContextType } from '../../../../Utils/Providers/CartContextProvider';
import componentClasses from '../../../../styles/Components.module.scss';
import LinkIcon from '@mui/icons-material/Link';
import { PlusOutlined } from '@ant-design/icons';
import InfoIcon from '@mui/icons-material/Info';
import { getCartWithUpdatedGroup } from './Functions/get-cart-with-updated-group';
import { useWindowDimensions } from '../../../../Utils/Hooks/useWindowDimensions';
import { DrawingAreaContext } from '../../../../Utils/Providers/DrawingAreaContextProvider';
import { PopoverButton } from '../../Components/PopoverButton/PopoverButton';

export interface ICartComponent {
  isAside?: boolean;
  wrapperRef?: any;
}

export interface ICart {
  id?: number;
  cartName: string;
  url: string;
  created: string;
  cartGroups: ICartGroup[];
  ungroupedProducts: IProductInCart[];
}

export interface ICartGroup {
  id: number;
  cartId: number;
  cartGroupName: string;
  products: IProductInCart[];
}

export interface IProductInCart {
  id: number;
  eMaxNumber: string;
  uniqueProductId: number;
  productName: string;
  numberOfItems?: number;
  productNumber: string;
  imagePath?: string;
  cartGroupId?: number;
  isMainProduct?: boolean;
  addedFromProduct?: string;
  favourite?: boolean;
}

export const CartComponent = (props: ICartComponent) => {
  const { width } = useWindowDimensions();
  const { state: drawingArea } = useContext(DrawingAreaContext);
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  // Get cart from context
  const { cart, setCart, itemsInCart } = useContext(CartContext) as CartContextType;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [productInMoveModal, setProductInMoveModal] = useState<
    { target: string; product: IProductInCart; currentGroup: string } | undefined
  >(undefined);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);

    if (!productInMoveModal) return;
    const { target, product, currentGroup } = productInMoveModal;

    if (target === cartKeyWords.ungrouped) {
      const oldProduct = cart.ungroupedProducts.find((oldProduct) => oldProduct.id === product.id);
      if (!oldProduct || !oldProduct?.numberOfItems) return;
      oldProduct.numberOfItems += product.numberOfItems ?? 0;
    } else {
      const cartGroup = cart.cartGroups.find((group) => group.cartGroupName === target);
      const oldProduct = cartGroup?.products.find((oldProduct) => oldProduct.id === product.id);
      if (!oldProduct || !oldProduct?.numberOfItems) return;
      oldProduct.numberOfItems += product.numberOfItems ?? 0;
    }

    removeProduct(product.uniqueProductId, currentGroup, cart);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Add a new group
  const addNewGroup = () => {
    let name = 'Group';
    let duplicate = true;

    for (let i = 1; duplicate; i++) {
      const nameToTest = `${name} ${i}`;

      duplicate = cart.cartGroups.some((group) => group.cartGroupName === nameToTest);
      if (!duplicate) name = nameToTest;
    }

    addNewNamedGroup(name);
  };

  const addNewNamedGroup = (name: string) => {
    // Get cart with the new group
    const updatedCartWithNewGroup = getCartWithNewGroup(name, cart);
    if (updatedCartWithNewGroup) {
      // Update state
      setCart(updatedCartWithNewGroup);

      // Update localStorage
      replaceLocalStorage(updatedCartWithNewGroup);
    }
  };

  // Remove group
  const removeGroup = (cartGroupId: number) => {
    // Get cart without the removed group
    const updatedCartWithoutRemovedGroup = getCartWithoutRemovedGroup(cartGroupId, cart);
    // Update state
    setCart(updatedCartWithoutRemovedGroup);

    // Update localStorage
    replaceLocalStorage(updatedCartWithoutRemovedGroup);
  };

  // Remove product
  const removeProduct = (unqiueProductId: number, productGroupName: string, currentCart: ICart) => {
    // If the product is ungrouped
    if (productGroupName === cartKeyWords.ungrouped) {
      const cartWithoutRemovedProductFromUngrouped = getCartWithoutRemovedProductFromUngrouped(
        currentCart,
        unqiueProductId,
      );
      setCart(cartWithoutRemovedProductFromUngrouped);
      replaceLocalStorage(cartWithoutRemovedProductFromUngrouped);
    }

    // If the product not is ungrouped
    if (productGroupName !== cartKeyWords.ungrouped) {
      const cartWithoutRemovedProductFromGrouped = getCartWithoutRemovedProductFromGrouped(
        currentCart,
        unqiueProductId,
        productGroupName,
      );
      setCart(cartWithoutRemovedProductFromGrouped);
      replaceLocalStorage(cartWithoutRemovedProductFromGrouped);
    }
  };

  const changeNumberOfItemsPerProduct = (
    currentValue: number,
    cartGroupId: number,
    uniqueProductId: number,
    ungrouped?: boolean,
  ) => {
    if (ungrouped) {
      const updatedCartWithUpdatedProductValue = getCartWithUpdatedProductValueForUngrouped(
        cart,
        currentValue,
        uniqueProductId,
      );
      setCart(updatedCartWithUpdatedProductValue);
      replaceLocalStorage(updatedCartWithUpdatedProductValue);
    }

    if (!ungrouped) {
      const updatedCartWithUpdatedProductValue = getCartWithUpdatedProductValueForGrouped(
        cartGroupId,
        cart,
        currentValue,
        uniqueProductId,
      );
      setCart(updatedCartWithUpdatedProductValue);
      replaceLocalStorage(updatedCartWithUpdatedProductValue);
    }
  };

  const moveTo = (target: string, product: IProductInCart, currentGroup: string) => {
    if (target === cartKeyWords.ungrouped) {
      if (cart.ungroupedProducts.find((productInCartGroup) => productInCartGroup.id === product.id)) {
        setProductInMoveModal({ target, product, currentGroup });
        showModal();
      } else {
        const cartWithMovedProductToUngrouped = getCartWithMovedProductToUngrouped(cart, product);

        // Set state
        setCart(cartWithMovedProductToUngrouped);

        // Replace localStorage
        replaceLocalStorage(cartWithMovedProductToUngrouped);

        // RemoveProduct from current group
        removeProduct(product.uniqueProductId, currentGroup, cartWithMovedProductToUngrouped);
      }
    }

    if (target !== cartKeyWords.ungrouped) {
      const cartGroup = cart.cartGroups.find((group) => group.cartGroupName === target);

      if (cartGroup?.products.find((productInCartGroup) => productInCartGroup.id === product.id)) {
        setProductInMoveModal({ target, product, currentGroup });
        showModal();
      } else {
        const cartWithMovedProductToGrouped = getCartWithMovedProductToGrouped(cart, product, target);

        // Set state
        setCart(cartWithMovedProductToGrouped);

        // Replace localStorage
        replaceLocalStorage(cartWithMovedProductToGrouped);

        // RemoveProduct from current group
        removeProduct(product.uniqueProductId, currentGroup, cartWithMovedProductToGrouped);
      }
    }
  };

  const changeGroupName = (name: string, id: number) => {
    // Get cart with the new group
    const updatedCartWithNewGroupName = getCartWithUpdatedGroup(name, id, cart);
    if (updatedCartWithNewGroupName) {
      // Update state
      setCart(updatedCartWithNewGroupName);

      // Update localStorage
      replaceLocalStorage(updatedCartWithNewGroupName);
    }
  };

  useEffect(() => {
    if (props.wrapperRef) {
      const element = props.wrapperRef.current;
      const barWidth = element.offsetWidth - element.clientWidth;
      setScrollBarWidth(barWidth > 1 ? barWidth : 0);
    }
  }, [props.wrapperRef, width, drawingArea]);

  return (
    <>
      <div className={`${classes.buttonGroup} ${props.isAside && classes.buttonGroupAside}`}>
        <div className={classes.cartGroupButtonWrapper}>
          <Button
            className={`${componentClasses.button} ${componentClasses.buttonSecondary} ${classes.button} 
          ${classes.groupButton} ${props.isAside && classes.buttonGrow}`}
            icon={<PlusOutlined />}
            onClick={() => addNewGroup()}
          >
            Add new cart group
          </Button>
          <PopoverButton
            classes={`${componentClasses.button} ${componentClasses.buttonSecondary} ${classes.button} ${classes.buttonInfo}`}
            icon={<InfoIcon />}
            popoverText={'Create cart groups to organize your cart.'}
          />
        </div>
        {!props.isAside && (
          <>
            <ShareCart
              cart={cart}
              className={`${componentClasses.button} ${componentClasses.buttonSecondary} ${classes.button} ${classes.shareButton}`}
              icon={<LinkIcon />}
            />
          </>
        )}
      </div>
      {cart &&
        cart.cartGroups.map((cartGroup: ICartGroup) => (
          <CartGroup
            key={cartGroup.id}
            title={cartGroup.cartGroupName}
            onDelete={() => removeGroup(cartGroup.id)}
            onEditName={(newName: string) => changeGroupName(newName, cartGroup.id)}
            asideTitleCss={props.isAside ? classes.cartGroupIsAside : ''}
          >
            {cartGroup.products.map((product: IProductInCart) => (
              <React.Fragment key={product.uniqueProductId}>
                <ProductInCart
                  {...product}
                  remove={() => removeProduct(product.uniqueProductId, cartGroup.cartGroupName, cart)}
                  updateNumberOfItems={(e: number) =>
                    changeNumberOfItemsPerProduct(e, cartGroup.id, product.uniqueProductId)
                  }
                  cartGroups={cart.cartGroups}
                  moveTo={(e: string) => moveTo(e, product, cartGroup.cartGroupName)}
                  currentGroupName={cartGroup.cartGroupName}
                  isAside={props.isAside}
                  wrapperRef={props.wrapperRef}
                />
              </React.Fragment>
            ))}
            {cartGroup.products.length < 1 && <p className={classes.disabledText}>No products in this group</p>}
          </CartGroup>
        ))}

      {cart.ungroupedProducts.length > 0 && (
        <CartGroup title="Ungrouped" asideTitleCss={props.isAside ? classes.cartGroupIsAside : ''}>
          {cart &&
            cart.ungroupedProducts.map((product: IProductInCart) => (
              <React.Fragment key={product.uniqueProductId}>
                <ProductInCart
                  {...product}
                  updateNumberOfItems={(e: number) =>
                    changeNumberOfItemsPerProduct(e, 0, product.uniqueProductId, true)
                  }
                  remove={() => removeProduct(product.uniqueProductId, cartKeyWords.ungrouped, cart)}
                  moveTo={(e: string) => moveTo(e, product, cartKeyWords.ungrouped)}
                  cartGroups={cart.cartGroups}
                  currentGroupName={cartKeyWords.ungrouped}
                  isAside={props.isAside}
                  wrapperRef={props.wrapperRef}
                />
              </React.Fragment>
            ))}
        </CartGroup>
      )}
      {!itemsInCart && <p className={classes.emptyCartText}>Your cart is empty</p>}
      {props.isAside && <div className={classes.shareAreaPadding}></div>}
      {props.isAside && (
        <div
          className={`${classes.shareButtonContainer}`}
          style={{ '--scrollbar-width': scrollBarWidth + 'px' } as React.CSSProperties}
        >
          <ShareCart
            cart={cart}
            className={`${componentClasses.button} ${componentClasses.buttonSecondary} ${classes.button}`}
            icon={<LinkIcon />}
          />
        </div>
      )}
      <Modal
        title="Product already exists in group"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Add Item"
        okButtonProps={{ className: componentClasses.buttonPrimary }}
        onCancel={handleCancel}
        cancelButtonProps={{ className: componentClasses.buttonSecondary }}
        className={classes.modal}
      >
        <p>
          <strong>{productInMoveModal?.product.productName}</strong> already exists in{' '}
          <strong>{productInMoveModal?.target}</strong>, do you wish to add it to the existing item?
        </p>
      </Modal>
    </>
  );
};
