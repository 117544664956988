import classes from './ConnectionError.module.scss';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

interface IConnectionError {
  inverted?: boolean;
}

export const ConnectionError = (props: IConnectionError) => {
  return (
    <div className={classes.root}>
      <HeartBrokenIcon className={`${classes.icon} ${props.inverted && classes.inverted}`} />
      <p className={`${classes.text} ${props.inverted && classes.inverted}`}>
        An error occured, please try again later
      </p>
    </div>
  );
};
