import { Button, Switch } from 'antd';
import classes from './Stickyfooter.module.scss';

interface IStickySaveFooter {
  onSave: () => void;
  onCancel: () => void;
  disabled?: boolean;
  publishToSite: (isPublished: boolean) => void;
  published: boolean;
  errorState: boolean;
}

const StickySaveFooter = (props: IStickySaveFooter) => {
  return (
    <div className={classes.root}>
      <div>
        <Switch checked={props.published} onChange={() => props.publishToSite(!props.published)} />
        <span>Publish to site</span>
      </div>
      <div className="button-group">
        <Button onClick={props.onCancel} type="link">
          Cancel
        </Button>
        <Button type="primary" onClick={() => props.onSave()} disabled={props.errorState}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default StickySaveFooter;
