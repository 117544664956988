import React from 'react';
import ReactDOM from 'react-dom';
import { RestfulProvider } from 'restful-react';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { IConfig, setConfig } from './config';
import CookieConsent from 'react-cookie-consent';

setConfig().then((conf) => {
  init(conf);
});

function onError(): ((err: { message: string; data: unknown; status?: number | undefined }) => void) | undefined {
  return (error) => {
    if ((!error.status || error.status === 401) && window.location.pathname !== '/login') {
      localStorage.removeItem('authenticated');
      window.sessionStorage.clear();
      window.location.href = '/';
    }
  };
}
const init = async (conf: IConfig) => {
  ReactDOM.render(
    <React.StrictMode>
      <RestfulProvider base={conf.API_URL} requestOptions={() => ({ credentials: 'include' })} onError={onError}>
        <CookieConsent style={{ zIndex: '9999' }}>
          These sites use cookies. By continuing to browse the site you are agreeing to our use of cookies. For more
          information, you can read our cookie policy <a href="/faq#cookiepolicy">here</a>.
        </CookieConsent>
        <App />
      </RestfulProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
