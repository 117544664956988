import classes from './Hero.module.scss';

interface IHero {
  title: string | React.ReactNode;
  subtitle?: string;
  textAlign?: 'left' | 'center' | 'right';
  images: { src: string; minWidth?: string }[];
}

export const Hero = (props: IHero) => {
  const style = {
    '--bg-large': `url("${props.images[0].src}")`,
    '--bg-medium': `url("${props.images[1].src}")`,
    '--bg-small': `url("${props.images[2].src}")`,
  } as React.CSSProperties;

  const getAlignClass = () => {
    switch (props.textAlign) {
      case 'left':
        return classes.left;
      case 'right':
        return classes.right;
      default:
        return classes.center;
    }
  };

  return (
    <header className={classes.root} style={style}>
      <div className={`${classes.container} ${getAlignClass()}`}>
        <div className={classes.textContent}>
          {props.title && <h1 className={classes.title}>{props.title}</h1>}
          {props.subtitle && <p className={classes.subtitle}>{props.subtitle}</p>}
        </div>
      </div>
    </header>
  );
};
