import { DeleteOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons/lib/icons';
import { Button, Form, Select } from 'antd';
import classes from './FaqListItem.module.scss';
import { categories, IFaq, IFaqCategory } from '../FaqPage';
import { useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';

interface IFaqListItem {
  item: IFaq;
  itemId: number;
  itemIndex: number;
  onRemove: (id: number, name: string) => void;
  currentIdAndRow: (data: number, index: number) => void;
  onEditFaq: (Faq: IFaq) => void;
}

const FaqListItem = (props: IFaqListItem) => {
  const [editMode, setEditMode] = useState(false);
  const [selectedId] = useState(props.item.id);
  const [category, setCategory] = useState<string>(props.item.category);
  const { Option } = Select;

  const handleDragItem = () => {
    props.currentIdAndRow(props.itemId, props.itemIndex);
  };

  const onFinishEditForm = (faq: IFaq) => {
    const parsedFaqObject = {
      question: faq.question,
      answer: faq.answer,
      category: category,
      id: selectedId,
    };
    props.onEditFaq(parsedFaqObject);
    setEditMode(false);
  };

  return (
    <>
      {!editMode ? (
        <div onMouseDown={handleDragItem} className={classes.root}>
          <div className={classes.header}>
            <MenuOutlined style={{ fontSize: '16px' }} />
            <h4>{props.item.question}</h4>
          </div>

          <div className={classes.actions}>
            <Button
              aria-label={`Edit ${props.item.question}`}
              onClick={() => setEditMode(true)}
              type="link"
              icon={<EditOutlined style={{ fontSize: '20px' }} />}
            />
            <Button
              aria-label={`Remove ${props.item.question}`}
              onClick={() => props.onRemove(props.item.id, props.item.question)}
              type="link"
              icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
            />
          </div>
        </div>
      ) : (
        <div>
          <Form
            layout="vertical"
            onFinish={onFinishEditForm}
            initialValues={{ question: props.item.question, answer: props.item.answer, category: props.item.category }}
          >
            <div className={classes.editFaqForm}>
              <div className={classes.inputs}>
                <Form.Item label="Question" name="question" rules={[{ required: true, message: 'Question' }]}>
                  <TextArea
                    autoFocus
                    onClick={(e) => (e.target as HTMLInputElement).focus()}
                    autoComplete="off"
                    className={classes.textArea}
                  />
                </Form.Item>
                <Form.Item label="Answer" name="answer" rules={[{ required: true, message: 'Answer' }]}>
                  <TextArea
                    onClick={(e) => (e.target as HTMLInputElement).focus()}
                    autoComplete="off"
                    className={classes.textArea}
                  />
                </Form.Item>
                <Form.Item
                  label="Category"
                  className={classes.category}
                  name="category"
                  rules={[{ required: true, type: 'string', message: 'Category' }]}
                >
                  <Select
                    value={category}
                    onChange={(groupId: string) => setCategory(groupId)}
                    placeholder="Please select"
                  >
                    {categories.map((o: IFaqCategory) => {
                      return <Option value={o.id}>{o.title}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </div>
              <Form.Item className={classes.buttonGroup}>
                <Button onClick={() => setEditMode(!editMode)} type="link">
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default FaqListItem;
