import { Modal } from 'antd';
import { Tree } from 'antd';
import { useEffect, useState } from 'react';
import { DataNode } from 'antd/lib/tree';
import { IListProduct } from '../../Pages/Admin/Products/EditProduct/Tabs/ProductLists/Interfaces/IProductList';
import { useGet } from 'restful-react';
import { LoadingState } from '../LoadingState/LoadingState';
import { ConnectionError } from '../../Pages/Shop/Components/ConnectionError/ConnectionError';

interface ICategoriesWithProducts {
  id: number;
  name: string;
  products?: IListProduct[];
  subCategories: ICategoriesWithProducts[];
}

interface IAddProductsModal {
  visible: boolean;
  onConfirm: (checked: IListProduct[]) => void;
  onClose: () => void;
  selectedProducts: IListProduct[];
}

export const AddProductsModal = (props: IAddProductsModal) => {
  // Set states
  const [categoriesWithProducts, setCategoriesWithProducts] = useState<ICategoriesWithProducts[]>([]);

  // Fetch data from api
  const { loading, refetch: getData } = useGet({
    path: 'categories-with-products',
    lazy: true,
    resolve: (data) => setCategoriesWithProducts(data.categoriesWithProductsResult),
  });

  // Run on mount
  useEffect(() => {
    getData();
    setSelected(props.selectedProducts.map((x) => formatProductKey(x.id, x.name)));
  }, [props.selectedProducts, getData]);

  // Format product key to distinguish between product and category
  function formatProductKey(id: number, name: string): string {
    return (id + '+' + name + 'product').replace(/\s/g, '');
  }

  // Set default keys
  const [selected, setSelected] = useState<string[]>(props.selectedProducts.map((x) => formatProductKey(x.id, x.name)));

  // Re-type data for tree
  function getDataNode(categoriesWithProducts: ICategoriesWithProducts[]): DataNode[] | [] {
    const dataNode = categoriesWithProducts.map((x: ICategoriesWithProducts) => {
      // Returns a product
      const products: DataNode[] | undefined =
        (x.products &&
          x.products.map((x) => {
            return {
              title: x.name + x.id,
              key: (x.id + '+' + x.name + 'product').replace(/\s/g, ''),
              children: [],
            };
          })) ??
        undefined;

      // Hide categories with neither subcategories or products
      if (x.subCategories.length === 0 && (!x.products || x.products.length === 0)) {
        return {
          key: x.id + x.name,
          title: x.name,
          children: [],
          disabled: true,
          style: { display: 'none' },
        };
      }

      // Re-runs function if the category has a subcategory
      return {
        key: x.id + x.name,
        title: x.name,
        children: !products ? getDataNode(x.subCategories) : [...getDataNode(x.subCategories), ...products],
      };
    });
    return dataNode;
  }

  // Runs everytime a item is checked or unchecked
  const onCheck = (checkedKeys: string[]) => {
    setSelected(checkedKeys);
  };

  // On confirm
  const onConfirm = (selected: string[]) => {
    // Filter out every item that's not a product
    const filteredSelected: string[] = selected.filter((x) => x.includes('product'));

    // Parsing id:s to numbers
    const ids: number[] = filteredSelected.map((x) => {
      return parseInt(x.split('+')[0]);
    });

    // Initiating a empty list
    let productsArr: IListProduct[] = [];

    // Adding products when id is matching with a product id
    const addProducts = (categoriesWithProducts: ICategoriesWithProducts[], ids: number[]) => {
      categoriesWithProducts.forEach((categoryWithProduct: ICategoriesWithProducts) => {
        categoryWithProduct.products?.forEach((product) => {
          if (ids.includes(product.id) && !productsArr.includes(product)) {
            productsArr.push({
              name: product.name,
              id: product.id,
              imagePath: product.imagePath,
              fileName: product.fileName,
              numberOfItems: 1,
            });
          }
        });

        // Run the function again if there's a subcategory
        if (categoryWithProduct.subCategories && categoryWithProduct.subCategories.length > 0) {
          return addProducts(categoryWithProduct.subCategories, ids);
        }
      });
    };

    addProducts(categoriesWithProducts, ids);

    // Pass data to parent component
    props.onConfirm(productsArr);
  };

  return (
    <Modal
      title="Add products"
      visible={props.visible}
      onOk={() => onConfirm(selected)}
      onCancel={() => props.onClose()}
      okText="Add"
      okButtonProps={{ type: 'primary' }}
    >
      {loading ? (
        <LoadingState />
      ) : categoriesWithProducts.length > 0 ? (
        <Tree
          checkable
          onCheck={(checkedKeys: any) => onCheck(checkedKeys)}
          checkedKeys={selected}
          treeData={getDataNode(categoriesWithProducts)}
        />
      ) : (
        <ConnectionError />
      )}
    </Modal>
  );
};
