import { IProductInCart } from '../../CartComponent';
import classes from './ProductInCart.module.scss';
import React from 'react';
import { AppConfig } from '../../../../../../config';
import { Link } from 'react-router-dom';

interface IProductInCartContainer extends IProductInCart {
  showNumberOfItemsOutsideInput?: boolean;
  isAside?: boolean;
  onLinkClick?: () => void;
}

export const ProductInCartContainer: React.FC<IProductInCartContainer> = (props) => {
  return (
    <li className={`${classes.root} ${props.isAside && classes.rootIsAside}`}>
      {props.imagePath && (
        <img
          className={classes.image}
          style={{ maxWidth: '4rem', overflow: 'hidden' }}
          src={AppConfig.IMAGE_BLOB + props.imagePath}
          alt={props.productName}
        />
      )}
      <div className={`${classes.productInfo} ${!props.imagePath && classes.productInfoNoImage}`}>
        {props.isMainProduct ? (
          <Link
            className={classes.productName}
            to={`/products/${props.id}/productNumber/${props.productNumber}`}
            onClick={props.onLinkClick}
          >
            {props.productName}
          </Link>
        ) : (
          <p className={classes.productTitle}>{props.productName}</p>
        )}
      </div>
      {props.children}
      {props.showNumberOfItemsOutsideInput && <span>{props.numberOfItems}</span>}
    </li>
  );
};
