import { headers, headersFiles } from '../../../../api';
import { IAttributeBulk } from './Tabs/TechnicalSpecificationsTab';
import { onLogout } from '../../../../Components/Header/Header';
import { AppConfig } from '../../../../config';
import { IListProduct } from './Tabs/ProductLists/Interfaces/IProductList';
import { UploadFile } from 'antd/es/upload/interface';
import fetchSendError from '../../../../Utils/fetchSendError';

export const uploadDocument = (
  id: number,
  fileNames: string[],
  uploadDocumentData: FormData,
  refetchFunction: () => void,
) => {
  const description = 'upload document';
  let fetchURL = `${AppConfig.API_URL}documents?productId=${id}`;
  const responses: any = [];
  fileNames.forEach((element) => {
    fetchURL = fetchURL.concat(`&names=${element}`);
  });
  responses.push(
    fetch(fetchURL, {
      method: 'POST',
      credentials: 'include',
      body: uploadDocumentData,
      headers: headersFiles,
    })
      .then(() => refetchFunction())
      .catch((error) => fetchSendError(`trying to ${description}`, error)),
  );
  return responses;
};

export const removeDocument = (documentKeys: number[]) => {
  const description = 'remove document';
  const responses: any = [];
  documentKeys.forEach((element) => {
    responses.push(
      fetch(`${AppConfig.API_URL}documents/${element}`, {
        method: 'DELETE',
        headers: headers,
        credentials: 'include',
      })
        .then((res) => {
          if (res.status >= 400) {
            fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
          }
          if (res.status === 401) {
            return onLogout();
          } else return res.json();
        })
        .catch((error) => fetchSendError(`trying to ${description}`, error)),
    );
  });
  return responses;
};

export const uploadImage = (id: number, productImages: FormData) => {
  const description = 'upload image';
  console.log(id);

  return fetch(`${AppConfig.API_URL}images/${id}`, {
    method: 'POST',
    credentials: 'include',
    body: productImages,
    headers: headersFiles,
  }).catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const deleteImages = (imageKeys: number[]) => {
  const description = 'delete images';
  let responses: any = [];
  imageKeys.forEach((element) => {
    responses.push(
      fetch(`${AppConfig.API_URL}image/${element}`, {
        method: 'DELETE',
        headers: headers,
        credentials: 'include',
      })
        .then((res) => {
          if (res.status >= 400) {
            fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
          }
          if (res.status === 401) {
            return onLogout();
          }
          res.json();
        })
        .catch((error) => fetchSendError(`trying to ${description}`, error)),
    );
  });
  return responses;
};

export const removeCategory = (id: number, catId: number) => {
  const description = 'remove category';
  return fetch(AppConfig.API_URL + 'categoryProduct?CategoryId=' + catId + '&ProductId=' + id, {
    method: 'DELETE',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const updateProductCategory = (id: number, catId: number) => {
  const description = 'update product category';
  return fetch(AppConfig.API_URL + 'categoryProduct?CategoryId=' + catId + '&ProductId=' + id, {
    method: 'POST',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const uppdateIncludedItems = (
  productId: number,
  includedProducts: {
    includedProductId: number;
    numberOfItems: number;
  }[],
) => {
  const description = 'update included items';
  return fetch(`${AppConfig.API_URL}updateIncludedProductOrder?productId=${productId}`, {
    method: 'PUT',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify(includedProducts),
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const addIncludedItemData = (currentId: number, id: number, numberOfItems: number) => {
  const description = 'add included item';
  return fetch(
    `${AppConfig.API_URL}includedProduct?ProductId=${currentId}&IncludedProductId=${id}&NumberOfItems=${numberOfItems}`,
    {
      method: 'POST',
      headers: headers,
      credentials: 'include',
    },
  )
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const addRelatedProductData = (currentId: number, id: number) => {
  const description = 'add related product';
  return fetch(`${AppConfig.API_URL}relatedProduct?productId=${currentId}&relatedProductId=${id}`, {
    method: 'POST',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const removeRelatedProductData = (currentId: number, id: number) => {
  const description = 'remove related product';
  return fetch(`${AppConfig.API_URL}relatedProduct?productId=${currentId}&relatedProductId=${id}`, {
    method: 'DELETE',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const addOptionalData = (currentId: number, id: number) => {
  const description = 'add optional item';
  return fetch(`${AppConfig.API_URL}addOnProduct?productId=${currentId}&addOnProductId=${id}`, {
    method: 'POST',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const updateAttribute = (currentId: number, attributes: IAttributeBulk[]) => {
  const description = 'update attribute';
  const body = {
    productIds: [currentId],
    attributeValues: [...attributes],
  };

  const jsonBody = JSON.stringify(body);

  return fetch(`${AppConfig.API_URL}productAttribute-bulk`, {
    method: 'PUT',
    headers: headers,
    credentials: 'include',

    body: `${jsonBody}`,
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const updatedProductTabForm = (
  currentId: number,
  name: string,
  description: string,
  price: number,
  productNumber: string,
  eMaxNumber: string,
  mainProduct: boolean,
  isPublished: boolean,
  images: {
    imagePath: string;
    fileName: string;
  }[],
) => {
  const errorDescription = 'update product tab';

  return fetch(`${AppConfig.API_URL}updateProduct`, {
    method: 'PUT',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify({
      id: currentId,
      name: name,
      description: description === 'null' ? '' : description ?? '',
      price: price,
      productNumber: productNumber,
      eMaxNumber: eMaxNumber,
      mainProduct: mainProduct,
      isPublished: isPublished,
      images: images,
    }),
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${errorDescription}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${errorDescription}`, error));
};

export const updateFile = (currentId: number, file: File) => {
  const description = 'update file';
  return fetch(`${AppConfig.API_URL}images/${currentId}`, {
    method: 'POST',
    headers: headers,
    credentials: 'include',
    body: file,
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const getRemoveItems = (arrayOne: IListProduct[], arrayTwo?: IListProduct[]) => {
  var items =
    arrayTwo?.filter(function (obj: any) {
      return !arrayOne.some(function (obj2: any) {
        return obj.id === obj2.id;
      });
    }) ?? [];

  return items;
};

export const getAddItems = (arrayOne: IListProduct[], arrayTwo?: IListProduct[]) => {
  var items = arrayOne.filter(function (obj: any) {
    return !arrayTwo?.some(function (obj2: any) {
      return obj.id === obj2.id;
    });
  });

  return items;
};

export const addProductCategories = (productId: number, categoryIdArray: number[]) => {
  const description = 'add product categories';
  let fetchURL: string = `${AppConfig.API_URL}categoryProduct-Bulk?productId=${productId}`;
  categoryIdArray.forEach((element) => {
    fetchURL = fetchURL.concat(`&categoryIds=${element}`);
  });
  return fetch(fetchURL, {
    method: 'POST',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const removeProductCategories = (productId: number, categoryIdArray: number[]) => {
  const description = 'remove product categories';
  let fetchURL: string = `${AppConfig.API_URL}categoryProductBulk?productId=${productId}`;
  categoryIdArray.forEach((element) => {
    fetchURL = fetchURL.concat(`&categoryIds=${element}`);
  });

  return fetch(fetchURL, {
    method: 'DELETE',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const updateRelatedItems = async (productId: number, relatedProductIds: number[]) => {
  const description = 'update related items';
  return fetch(`${AppConfig.API_URL}updateRelatedProductOrder?productId=${productId}`, {
    method: 'PUT',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify(relatedProductIds),
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const updateOptionalItems = async (productId: number, optionalItemsIds: number[]) => {
  const description = 'update optional items';
  return fetch(`${AppConfig.API_URL}updateAddOnProductOrder?productId=${productId}`, {
    method: 'PUT',
    headers: headers,
    credentials: 'include',
    body: JSON.stringify(optionalItemsIds),
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      return res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const addSearchTags = (productId: number, searchTags: string[]) => {
  const description = 'add search tags';
  let fetchURL: string = `${AppConfig.API_URL}searchTag/${productId}?`;
  searchTags.forEach((element) => {
    fetchURL = fetchURL.concat(`searchTags=${encodeURIComponent(element)}&`);
  });
  return fetch(fetchURL, {
    method: 'POST',
    headers: headers,
    credentials: 'include',
  }).catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const deleteSearchTags = (tagIds: number[]) => {
  const description = 'delete search tags';
  let fetchURL: string = `${AppConfig.API_URL}searchTag/?`;
  tagIds.forEach((element) => {
    fetchURL = fetchURL.concat(`listOfIds=${element}&`);
  });
  return fetch(fetchURL, {
    method: 'DELETE',
    headers: headers,
    credentials: 'include',
  }).catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const getBase64 = (file: File | Blob | undefined): Promise<string> => {
  if (!file) return Promise.reject(new Error('no file'));
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file!);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const imagePreview = async (file: UploadFile, callback: (params: { image: string }) => void) => {
  const newFile = file;
  if (!newFile.url && !newFile.preview) {
    newFile.preview = await getBase64(file.originFileObj);
  }
  const newPreviewImage: string = newFile.url || newFile.preview || '';
  callback({
    image: newPreviewImage,
  });
};
