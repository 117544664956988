import { message } from 'antd';
import { ICart, IProductInCart } from '../CartComponent';
import { cartKeyWords } from '../Other/keywords';
import { replaceLocalStorage } from './replace-local-storage';

export const addProductToCart = (quantity: number, product: IProductInCart) => {
  const cart = localStorage.getItem(cartKeyWords.localStorageKey);
  if (cart) {
    // Get cart
    let parseCart: ICart = JSON.parse(cart);

    const productInCart = parseCart.ungroupedProducts.filter(
      (ungroupedProduct) => product.id === ungroupedProduct.id,
    )[0];
    if (productInCart) {
      // Add to existing product
      productInCart.numberOfItems = productInCart.numberOfItems ? productInCart.numberOfItems + quantity : quantity;
    } else {
      // Add new product
      const newProduct: IProductInCart = {
        id: product.id,
        eMaxNumber: product.eMaxNumber,
        productName: product.productName,
        productNumber: product.productNumber,
        numberOfItems: quantity,
        imagePath: product.imagePath,
        uniqueProductId: Math.floor(Math.random() * 10000),
        addedFromProduct: product.addedFromProduct,
        isMainProduct: product.isMainProduct,
      };
      parseCart.ungroupedProducts.push(newProduct);
    }
    message.success('The product was successfully added to cart');

    replaceLocalStorage(parseCart);
  }
};

export const quickAddProductToCart = (product: IProductInCart) => {
  const cart = localStorage.getItem(cartKeyWords.localStorageKey);
  if (cart) {
    // Get cart
    let parseCart: ICart = JSON.parse(cart);

    const productInCart = parseCart.ungroupedProducts.filter(
      (ungroupedProduct) => product.id === ungroupedProduct.id,
    )[0];
    if (productInCart) {
      // Add to existing product
      productInCart.numberOfItems = productInCart.numberOfItems ? productInCart.numberOfItems + 1 : 1;
    } else {
      // Add new product
      const newProduct: IProductInCart = {
        id: product.id,
        eMaxNumber: product.eMaxNumber,
        productName: product.productName,
        productNumber: product.productNumber,
        numberOfItems: 1,
        imagePath: product.imagePath,
        uniqueProductId: Math.floor(Math.random() * 10000),
        addedFromProduct: product.addedFromProduct,
        isMainProduct: product.isMainProduct,
      };
      parseCart.ungroupedProducts.push(newProduct);
    }
    message.success('The product was successfully added to cart');

    replaceLocalStorage(parseCart);
  }
};
