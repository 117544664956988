import { ProductCarousel } from '../../../Components/ProductCarousel/ProductCarousel';
import { IConnectProduct } from '../../../Interfaces/IProduct';
import classes from './RelatedProducts.module.scss';

interface IRelatedProducts {
  products: IConnectProduct[];
}

export const RelatedProducts = (props: IRelatedProducts) => {
  if (!props.products || props.products.length < 1) return null;
  return (
    <div className={classes.root}>
      <h2 className={classes.title}>You may also like</h2>
      <ProductCarousel products={props.products} productsShown={5} animationTimer={350} />
    </div>
  );
};
