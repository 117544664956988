import { IAttribute } from '../../../Interfaces/IProduct';
import { Table } from 'antd';
import classes from './TechnicalSpecifications.module.scss';

interface ITechnicalSpecifications {
  attributes: IAttribute[];
}

interface IData {
  key: string;
  name: string;
  value: string;
}

export const TechnicalSpecifications = (props: ITechnicalSpecifications) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <span className={classes.attributeName}>{text}</span>,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text: string) => <span className={classes.value}>{text}</span>,
    },
  ];

  const data: IData[] = props.attributes.map((x) => {
    return {
      name: x.name,
      value: x.unit ? x.value + ' ' + x.unit : x.value,
      key: x.id.toString(),
    };
  });

  return <Table pagination={false} columns={columns} dataSource={data} showHeader={false} className={classes.root} />;
};
