import { useEffect, useState } from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { AddCategory } from '../Components/AddCategory/AddCategory';
import { headers } from '../../../../api';
import { addCategory, updateList, IConfirmRemove, publish, removeCategory } from '../CategoriesFunctions';
import { ConfirmRemoveModal } from '../../../../Components/ConfirmRemoveModal/ConfirmRemoveModal';
import { CategoryListItem } from '../Components/CategoryListItem/CategoryListItem';
import { useNavigate, useLocation } from 'react-router';
import { ICategory } from '../CategoriesPage';
import { ConfirmPublishModal } from '../Components/ConfirmPublishModal/ConfirmPublishModal';
import { onLogout } from '../../../../Components/Header/Header';
import { AppConfig } from '../../../../config';
import { LoadingState } from '../../../../Components/LoadingState/LoadingState';
import { ConnectionError } from '../../../Shop/Components/ConnectionError/ConnectionError';

export const MainCategories = () => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [confirmPublish, setConfirmPublish] = useState<ICategory | null>(null);
  const [confirmRemoveData, setConfirmRemoveData] = useState<IConfirmRemove | null>(null);
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const [currentRow, setCurrentRow] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getCategoryData();
  }, []);

  const getCategoryData = () => {
    fetch(AppConfig.API_URL + 'categories/', {
      headers: headers,
      credentials: 'include',
    })
      .then((res) => {
        if (res.status === 401) {
          return onLogout();
        }
        return res.json();
      })
      .then((data) => {
        setCategories(data ? data.categories : []);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  // Add sub category and refresh data
  const addCategoryToList = (title: string) => {
    addCategory(title);

    // TODO: Remove this when useGet is implemented
    setTimeout(function () {
      getCategoryData();
    }, 500);
  };

  // Sets the row index and category id of the grabbed item
  const currentId = (data: number, index: number) => {
    setSelectedRowId(data);
    setCurrentRow(index);
  };

  // Handle the change on drag-drop
  const handleRLDDChange = (newOrderdArray: Array<ICategory>) => {
    setCategories(newOrderdArray);
    let newRowPosition: any;

    newOrderdArray.forEach((item, index = 0) => {
      if (item.id === selectedRowId) {
        newRowPosition = index;
      }
    });

    if (currentRow !== null && currentRow >= 0) {
      if (selectedRowId && currentRow < newRowPosition) {
        let afterId = categories[newRowPosition].id;
        updateList(afterId, selectedRowId);
      }
      if (selectedRowId && currentRow > newRowPosition) {
        let afterId;
        if (newRowPosition === 0) {
          afterId = categories[newRowPosition].id;
        } else {
          afterId = categories[newRowPosition - 1].id;
        }
        updateList(afterId, selectedRowId);
      }
    }
  };

  // Confirm removing a category with modal
  const confirmRemove = () => {
    if (confirmRemoveData) {
      removeCategory(confirmRemoveData.id);
      if (categories) {
        const updatedData: ICategory[] = categories.filter((x: ICategory) => x.id !== confirmRemoveData?.id);
        setCategories(updatedData);
      }
    }
    setConfirmRemoveData(null);
  };

  // Change route to go to a subcategory
  const goToCategory = (id: number) => {
    navigate(`${location.pathname}/category/${id}`);
  };

  // Publish category (confirm in modal)
  const publishCategory = () => {
    if (confirmPublish) {
      publish(confirmPublish);
      if (categories) {
        categories.forEach((x: ICategory) => {
          if (x.id === confirmPublish.id) {
            x.isPublished = !x.isPublished;
          }
        });
      }
    }

    setConfirmPublish(null);
  };

  return (
    <>
      <AddCategory
        isDisabled={true}
        buttonText="Add New Category"
        onEditName={(title: string) => addCategoryToList(title)}
      />
      <div>
        {loading && <LoadingState text="Loading Main Categories..." />}
        {!loading && categories && categories.length > 0 ? (
          <RLDD
            items={categories}
            onChange={handleRLDDChange}
            itemRenderer={(item: ICategory, index: number) => {
              return (
                <CategoryListItem
                  item={item}
                  itemId={item.id}
                  itemIndex={index}
                  currentIdAndRow={currentId}
                  onRemove={(id, name) => setConfirmRemoveData({ id, name })}
                  onRouteTo={(id) => goToCategory(id)}
                  onPublish={(item) => setConfirmPublish(item)}
                />
              );
            }}
          />
        ) : !loading ? (
          <ConnectionError />
        ) : null}
      </div>
      <ConfirmRemoveModal
        itemName={confirmRemoveData?.name ?? ''}
        visible={confirmRemoveData ? true : false}
        onConfirm={() => confirmRemove()}
        onClose={() => setConfirmRemoveData(null)}
      />
      <ConfirmPublishModal
        visible={confirmPublish ? true : false}
        name={confirmPublish?.name}
        onConfirm={() => publishCategory()}
        onCancel={() => setConfirmPublish(null)}
        unpublish={confirmPublish?.isPublished}
      />
    </>
  );
};
