import { Button } from 'antd';
import { useNavigate } from 'react-router';
import { paths } from '../../../../routes/paths';
import classes from './FourOFour.module.scss';
import componentClasses from '../../../../styles/Components.module.scss';

export const FourOFour = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <h1 className={classes.header}>404</h1>
          <p>Sorry, the page you requested does not exist.</p>
          <div className={classes.buttonGroup}>
            <Button
              type="primary"
              onClick={() => navigate(paths.home)}
              className={`${componentClasses.button} ${componentClasses.buttonPrimary}`}
            >
              Go home
            </Button>
            <Button
              onClick={() => navigate(-1)}
              className={`${componentClasses.button} ${componentClasses.buttonSecondary}`}
            >
              Go back
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
