import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { ReactComponent as MainLogo } from '../../../../logo-hyrmax.svg';
import { ReactComponent as SloganLogo } from '../../../../logo-slogan.svg';
import classes from './Navbar.module.scss';
import { useContext, useEffect, useState } from 'react';
import { HeartOutlined } from '@ant-design/icons';
import { paths } from '../../../../routes/paths';
import { useLocation, useNavigate } from 'react-router';
import { SearchBar } from '../Searchbar/SearchBar';
import { IconButton } from '../IconButton/IconButton';
import breakPoints from '../../../../Utils/breakPoints';
import { useWindowDimensions } from '../../../../Utils/Hooks/useWindowDimensions';
import { DrawingAreaContext, DrawingAreaTypes } from '../../../../Utils/Providers/DrawingAreaContextProvider';
import { CartContext, CartContextType } from '../../../../Utils/Providers/CartContextProvider';
import { loadFavouritesFromLocalStorage } from '../FavouriteButton/FavouriteButton';

interface IMenuItem {
  link: string;
  text: string;
}

const MenuItem = (props: IMenuItem) => (
  <Link className={classes.menuItem} to={props.link}>
    {props.text}
  </Link>
);

const Logotype = () => (
  <Link to="/" className={classes.linkWrapContainer}>
    <MainLogo className={classes.logotype} />
    <SloganLogo className={classes.slogan} />
  </Link>
);

export const Navbar = () => {
  const { state: drawingArea, dispatch } = useContext(DrawingAreaContext);

  // Get cart from context
  const { itemsInCart } = useContext(CartContext) as CartContextType;

  const [nbrOfFavourites, setNbrOfFavourites] = useState<number>(loadFavouritesFromLocalStorage().length);

  const { width } = useWindowDimensions();
  const location = useLocation();
  const navigate = useNavigate();
  const [cartIconButton, setCartIconButton] = useState(
    <IconButton
      icon={drawingArea.right ? <CloseIcon /> : <ShoppingCartIcon />}
      onClick={() => dispatch({ payload: !drawingArea.right, type: DrawingAreaTypes.UpdateRight })}
      className={`${classes.buttonIcon} ${classes.cartButton}`}
      aria-label="Cart"
      counter={!drawingArea.right && itemsInCart}
    />,
  );
  const [isSearching, setIsSearching] = useState(false);

  const showSearchBar = () => {
    return isSearching ? (
      <SearchBar />
    ) : (
      width >= breakPoints.lg && (
        <>
          <MenuItem link={paths.faq} text="FAQ" />
          <MenuItem link={paths.contact} text="Contact" />
        </>
      )
    );
  };

  useEffect(() => {
    if (width < breakPoints.lg && isSearching) {
      dispatch({ payload: false, type: DrawingAreaTypes.UpdateBoth });
    }
  }, [dispatch, isSearching, width]);

  useEffect(() => {
    if (width < breakPoints.lg && (drawingArea.left || drawingArea.right)) {
      setIsSearching(false);
    }
  }, [drawingArea.left, drawingArea.right, width]);

  useEffect(() => {
    if (paths.cart === location.pathname) {
      dispatch({ payload: false, type: DrawingAreaTypes.UpdateRight });
      setCartIconButton(
        <IconButton
          disabled
          className={`${classes.buttonIcon} ${classes.cartButton} ${classes.cartPageIsOpen}`}
          icon={<ShoppingCartIcon className={classes.cartPageIsOpen} />}
          aria-label="Cart"
          counter={!drawingArea.right && itemsInCart}
        />,
      );
    } else {
      setCartIconButton(
        <IconButton
          icon={drawingArea.right ? <CloseIcon /> : <ShoppingCartIcon />}
          onClick={() => dispatch({ payload: !drawingArea.right, type: DrawingAreaTypes.UpdateRight })}
          className={`${classes.buttonIcon} ${classes.cartButton}`}
          aria-label="Cart"
          counter={!drawingArea.right && itemsInCart}
        />,
      );
    }
  }, [dispatch, drawingArea.right, itemsInCart, location]);

  window.addEventListener('favourite', () => {
    setNbrOfFavourites(loadFavouritesFromLocalStorage().length);
  });

  return (
    <nav aria-label="Secondary navigation" className={classes.root}>
      <div className={classes.hamburgerWithLogo}>
        <IconButton
          icon={drawingArea.left ? <CloseIcon /> : <MenuIcon />}
          onClick={() => dispatch({ payload: !drawingArea.left, type: DrawingAreaTypes.UpdateLeft })}
          className={classes.buttonIcon}
          aria-label="Search"
        />
        <Logotype />
      </div>
      <div className={classes.info}>{showSearchBar()}</div>
      <div className={classes.iconGroup}>
        <IconButton icon={isSearching ? <CloseIcon /> : <SearchIcon />} onClick={() => setIsSearching(!isSearching)} />
        <IconButton
          onClick={() => {
            if (width < breakPoints.lg) dispatch({ payload: false, type: DrawingAreaTypes.UpdateBoth });
            navigate(paths.favourites);
          }}
          icon={<HeartOutlined />}
          className={`${classes.buttonIcon} ${classes.favouriteButton}`}
          aria-label="Favorites"
          counter={nbrOfFavourites > 0 && nbrOfFavourites}
        />
        {cartIconButton}
      </div>
    </nav>
  );
};
