import { List, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { IConnectProduct } from '../../../Interfaces/IProduct';
import { paths } from '../../../../../routes/paths';
import classes from './IncludedProducts.module.scss';

interface IIncludedProducts {
  products: IConnectProduct[];
}

export const IncludedProducts = (props: IIncludedProducts) => {
  return (
    <>
      <List
        size="large"
        dataSource={props.products}
        className={classes.root}
        locale={{
          emptyText: 'No included items',
        }}
        renderItem={(item, index) => (
          <>
            <List.Item>
              <span>
                {item.mainProduct ? (
                  <>
                    {item.numberOfItems} <span className={classes.x}>x</span>{' '}
                    <Link
                      to={`${paths.productBase}${item.id}/productNumber/${item.productNumber}`}
                      className={classes.link}
                    >
                      {item.name}
                    </Link>
                  </>
                ) : (
                  <Typography>
                    {item.numberOfItems} x {item.name}
                  </Typography>
                )}
              </span>
            </List.Item>
          </>
        )}
      />
    </>
  );
};
