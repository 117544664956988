import { View, Text, StyleSheet } from '@react-pdf/renderer';

interface IRentalInformation {
  deliveryAddress: string;
  pickup: string;
  pickupTime: string;
  startDate: string;
  endDate: string;
  comment: string;
  returnBefore: string;
  deliveryContactName?: string;
  deliveryTime?: string;
}

interface IPDFRentalInformation {
  rentalInformation: IRentalInformation;
}

export const PDFRentalInformation = (props: IPDFRentalInformation) => {
  const styles = StyleSheet.create({
    root: {
      padding: '16px 24px 16px 24px',
      borderBottom: '1pt solid #D9D9D9',
      fontSize: '8pt',
    },
    customerInformationContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    rightSide: {
      display: 'flex',
      flexDirection: 'row',
      width: '60%',
    },
    additionalInformationContainer: {
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    title: {
      marginBottom: '8pt',
      fontSize: '15pt',
      fontWeight: 'bold',
    },
    headerNameGroupPickup: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '68px',
    },
    headerNameGroupDelivery: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '38px',
    },
    rentalInfoGroupPickup: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '40px',
    },
    rentalInfoGroupDelivery: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      paddingRight: '38px',
    },
    listText: {
      fontWeight: 'semibold',
      marginVertical: '6pt',
    },
    listInfo: {
      marginVertical: '6pt',
    },
  });

  return (
    <View style={styles.root}>
      <Text style={styles.title}>Rental Information</Text>
      <View style={styles.customerInformationContainer}>
        <View style={styles.rightSide}>
          {props.rentalInformation.pickup === 'delivery' ? (
            <View style={styles.headerNameGroupDelivery}>
              <Text style={styles.listText}>Start date</Text>
              <Text style={styles.listText}>End date</Text>
              <Text style={styles.listText}>Return before</Text>
              <Text style={styles.listText}>Delivery address</Text>
              <Text style={styles.listText}>Delivery time</Text>
              <Text style={styles.listText}>Delivery contact person</Text>
            </View>
          ) : (
            <View style={styles.headerNameGroupPickup}>
              <Text style={styles.listText}>Start date</Text>
              <Text style={styles.listText}>End date</Text>
              <Text style={styles.listText}>Pickup time</Text>
              <Text style={styles.listText}>Return before</Text>
            </View>
          )}
          {props.rentalInformation.pickup === 'delivery' ? (
            <View style={styles.rentalInfoGroupDelivery}>
              <Text style={styles.listInfo}>{props.rentalInformation.startDate}</Text>
              <Text style={styles.listInfo}>{props.rentalInformation.endDate}</Text>
              <Text style={styles.listInfo}>{props.rentalInformation.returnBefore}</Text>
              <Text style={styles.listInfo}>{props.rentalInformation.deliveryAddress}</Text>
              <Text style={styles.listInfo}>{props.rentalInformation.deliveryTime}</Text>
              <Text style={styles.listInfo}>{props.rentalInformation.deliveryContactName}</Text>
            </View>
          ) : (
            <View style={styles.rentalInfoGroupPickup}>
              <Text style={styles.listInfo}>{props.rentalInformation.startDate}</Text>
              <Text style={styles.listInfo}>{props.rentalInformation.endDate}</Text>
              <Text style={styles.listInfo}>{props.rentalInformation.pickupTime}</Text>
              <Text style={styles.listInfo}>{props.rentalInformation.returnBefore}</Text>
            </View>
          )}
        </View>
        <View style={styles.additionalInformationContainer}>
          <Text style={styles.listText}>Additional comments</Text>
          <Text style={styles.listInfo}>{props.rentalInformation.comment}</Text>
        </View>
      </View>
    </View>
  );
};
