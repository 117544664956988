import { headers } from '../api';
import { onLogout } from '../Components/Header/Header';
import { AppConfig } from '../config';
import fetchSendError from './fetchSendError';

export const updateProducts = (page: string, ids: number[], onSucces: () => void) => {
  const description = 'update a product';
  fetch(`${AppConfig.API_URL}updateFrontPageProduct?page=${page}${ids.map((id) => `&ids=${id}`).join('')}`, {
    method: 'PUT',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
        if (res.status === 401) {
          return onLogout();
        }
      } else {
        onSucces();
      }
      res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};
