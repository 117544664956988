import { ArrowLeftOutlined } from '@ant-design/icons/lib/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router';
import classes from './GobackButton.module.scss';

export const GobackButton = (props: { state?: any }) => {
  const navigate = useNavigate();
  const goBack = () => {
    props.state ? navigate(props.state, { state: { openCart: true } }) : navigate(-1);
  };
  return (
    <Button
      onClick={goBack}
      aria-label="Go back to previous page"
      type="link"
      shape="circle"
      icon={<ArrowLeftOutlined />}
      className={classes.root}
    />
  );
};
