import { Input } from 'antd';
import { ICartGroup, IProductInCart } from '../../CartComponent';
import classes from './ProductInCart.module.scss';
import componetClasses from '../../../../../../styles/Components.module.scss';
import { Menu, Dropdown, Button } from 'antd';
import DeleteIcon from '@mui/icons-material/Delete';
import { cartKeyWords } from '../../Other/keywords';
import { ProductInCartContainer } from './ProductInCartContainer';
import { DownOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { useWindowDimensions } from '../../../../../../Utils/Hooks/useWindowDimensions';
import { useLocation } from 'react-router';
import breakPoints from '../../../../../../Utils/breakPoints';
import { DrawingAreaContext, DrawingAreaTypes } from '../../../../../../Utils/Providers/DrawingAreaContextProvider';

interface IProductInCartComponent extends IProductInCart {
  cartGroups: ICartGroup[];
  currentGroupName: string;
  updateNumberOfItems: (e: number) => void;
  remove: () => void;
  moveTo: (target: string) => void;
  isAside?: boolean;
  wrapperRef?: any;
}

export const ProductInCart = (props: IProductInCartComponent) => {
  const { state: drawingArea, dispatch } = useContext(DrawingAreaContext);
  const dropdownRef = useRef<any>(null);
  const location = useLocation();

  const windowDimensions = useWindowDimensions();
  const [dropdownPlacement, setDropdownPlacement] = useState<
    'bottom' | 'bottomLeft' | 'bottomRight' | 'top' | 'topLeft' | 'topRight'
  >('bottomLeft');

  useEffect(() => {
    const asideRef = props.wrapperRef;
    if (props.isAside && asideRef && asideRef.current) {
      const menyRowHeight = 32;
      const safetyMargin = 48;
      const menyHeight = props.cartGroups.length * menyRowHeight + safetyMargin;
      const buttonPos = dropdownRef.current?.offsetTop!;
      const menyBottom = menyHeight + buttonPos;
      const containerHeight = asideRef.current?.scrollHeight!;

      setDropdownPlacement(menyBottom > containerHeight ? 'topLeft' : 'bottomLeft');
    }
  }, [windowDimensions, props]);

  const menu = () => (
    <Menu>
      {props.cartGroups.map((cartGroup: ICartGroup) => (
        <>
          {props.currentGroupName !== cartGroup.cartGroupName && (
            <Menu.Item onClick={() => props.moveTo(cartGroup.cartGroupName)}>{cartGroup.cartGroupName}</Menu.Item>
          )}
        </>
      ))}
      {props.currentGroupName !== cartKeyWords.ungrouped && (
        <Menu.Item onClick={() => props.moveTo(cartKeyWords.ungrouped)}>Ungrouped</Menu.Item>
      )}
    </Menu>
  );

  const onDecrease = () => {
    if (props.numberOfItems) {
      props.updateNumberOfItems(props.numberOfItems - 1);
    }
  };

  const onIncrease = () => {
    if (props.numberOfItems) {
      props.updateNumberOfItems(props.numberOfItems + 1);
    }
  };

  const onInputBlur = (value: string) => {
    let valueNum = parseInt(value);
    if (!valueNum || valueNum < 1) props.updateNumberOfItems(1);
  };

  const onLinkClick = () => {
    const link = `/products/${props.id}/productNumber/${props.productNumber}`;
    if (drawingArea.right && location.pathname === link && windowDimensions.width < breakPoints.lg) {
      dispatch({ payload: false, type: DrawingAreaTypes.UpdateRight });
    }
  };

  return (
    <ProductInCartContainer
      id={props.id}
      eMaxNumber={props.eMaxNumber}
      productNumber={props.productNumber}
      uniqueProductId={props.uniqueProductId}
      productName={props.productName}
      numberOfItems={props.numberOfItems}
      imagePath={props.imagePath}
      cartGroupId={props.cartGroupId}
      isAside={props.isAside}
      onLinkClick={onLinkClick}
      isMainProduct={props.isMainProduct}
    >
      {props.cartGroups.length > 0 && (
        <Dropdown overlay={menu} placement={dropdownPlacement} arrow disabled={props.cartGroups.length === 0}>
          <Button
            className={`${classes.action} ${!props.imagePath && classes.actionNoImage} ${componetClasses.button} ${
              componetClasses.buttonSecondary
            } ${classes.button}`}
            ref={dropdownRef}
          >
            Move to <DownOutlined />
          </Button>
        </Dropdown>
      )}
      <div className={`${classes.buttonGroup} ${props.isAside ? classes.buttonGroupAside : ''}`}>
        <div className={classes.inputWrapper}>
          <Button
            className={`${componetClasses.button} ${componetClasses.buttonSecondary} ${classes.button}`}
            disabled={props.numberOfItems ? props.numberOfItems < 2 : true}
            icon={<MinusOutlined />}
            onClick={onDecrease}
            aria-label={`Decrease number of items in cart. Current value: ${props.numberOfItems}`}
          />
          <Input
            type="number"
            className={classes.input}
            value={props.numberOfItems}
            min={1}
            onBlur={(e) => onInputBlur(e.target.value)}
            onChange={(e) => props.updateNumberOfItems(parseInt(e.target.value))}
          />
          <Button
            className={`${componetClasses.button} ${componetClasses.buttonSecondary} ${classes.button}`}
            aria-label={`Increase number of items in cart. Current value: ${props.numberOfItems}`}
            icon={<PlusOutlined />}
            onClick={onIncrease}
          />
        </div>

        <Button
          className={`${componetClasses.button} ${componetClasses.buttonSecondary} ${classes.button}`}
          icon={<DeleteIcon />}
          onClick={() => props.remove()}
        />
      </div>
    </ProductInCartContainer>
  );
};
