import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Result,
  Select,
} from 'antd';
import 'moment/locale/en-gb';
import locale from 'antd/es/date-picker/locale/en_GB';
import classes from './OrderForm.module.scss';
import componentClasses from '../../../../../styles/Components.module.scss';
import { useContext, useRef, useState } from 'react';
import { ContactInfoBox } from '../../../Components/ContactInfoBox/ContactInfoBox';
import { RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';
import { CartContext, CartContextType } from '../../../../../Utils/Providers/CartContextProvider';
import { InsertDriveFile } from '@mui/icons-material';
import { headers } from '../../../../../api';
import { AppConfig } from '../../../../../config';
import { getDocumentLink, documentsObject } from '../../../../../Utils/generalDocumentFetchers';
import { useGet } from 'restful-react';

export const OrderForm = () => {
  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const earliestDate = moment();
  const [isConfirmedModalVisible, setIsConfirmedModalVisible] = useState<boolean>(false);
  const [showPickup, setShowPickup] = useState(true);
  const [pickupTime, setPickupTime] = useState<string | undefined>(undefined);
  const [isSameDay, setIsSameDay] = useState(false);
  const [radioValue, setRadioValue] = useState('');
  type formattedRangePickerType = {
    startDate: string;
    dateBeforeStart: string;
    endDate: string;
    dateAfterEnd: string;
  };
  const [rangePickerValues, setRangePickerValues] = useState<RangeValue<Moment> | undefined>();
  const [formattedRangePicker, setFormattedRangePicker] = useState<formattedRangePickerType | undefined>();
  // Get cart from context
  const { cart, clearCart } = useContext(CartContext) as CartContextType;
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance>();
  formRef.current = form;

  const { data: generalDocuments, loading } = useGet({
    path: '/generalDocuments',
  });

  const clearOrderForm = () => {
    setShowPickup(true);
    setPickupTime(undefined);
    setIsSameDay(false);
    setRadioValue('');

    formRef.current!.resetFields();
  };

  const onRangePickerChange = (values: RangeValue<Moment>) => {
    setRangePickerValues(values);
    if (values && values[0] && values[1]) {
      setFormattedRangePicker({
        startDate: values[0].format('MMMM Do'),
        dateBeforeStart: values[0].clone().subtract(1, 'days').format('MMMM Do'),
        endDate: values[1].format('MMMM Do'),
        dateAfterEnd: values[1].clone().add(1, 'days').format('MMMM Do'),
      });
    }
  };

  // In Hours
  const createOptionsForPickup = (startTime: number, endTime: number, slotLength: number, lunchbreak?: boolean) => {
    let dates: string[] = [];

    for (let i = startTime; i < endTime; i += slotLength) {
      if (i === 12 && lunchbreak) continue;
      else if (i === 23) dates.push(`${i}:00 - 00:00`);
      else dates.push(`${i}:00 - ${i + slotLength}:00`);
    }
    return dates.map((date) => <Option value={date}>{date}</Option>);
  };

  const handleRadioChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
    setIsSameDay(e.target.value === 'same-day');
  };

  const showTab = () => {
    return showPickup ? (
      <div className={classes.pickupContainer}>
        <Form.Item
          name="pickup"
          id="pickup"
          rules={[{ type: 'string', required: true, message: 'Please select type of pick up.' }]}
        >
          <Radio.Group defaultValue={radioValue}>
            <Radio value="same-day" onChange={handleRadioChange}>
              Select a pickup time for {formattedRangePicker && formattedRangePicker.startDate}
            </Radio>
            <div className={classes.pickupTimePlaceholder} />
            <Radio
              value="day-before"
              onChange={handleRadioChange}
              disabled={
                rangePickerValues
                  ? rangePickerValues[0]?.format('YYYY-MM-DD') === earliestDate.format('YYYY-MM-DD')
                  : false
              }
            >
              Pickup the day before on {formattedRangePicker && formattedRangePicker.dateBeforeStart} at 16:00 - 17:00
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className={classes.pickupTimeSelect}
          name="pickupTime"
          id="pickupTime"
          rules={[{ type: 'string', required: isSameDay, message: 'Please select a pickup time!' }]}
        >
          <Select
            value={pickupTime}
            onChange={(time: string) => setPickupTime(time)}
            placeholder="Please select"
            disabled={!isSameDay}
          >
            {createOptionsForPickup(8, 17, 1, true)}
          </Select>
        </Form.Item>
      </div>
    ) : (
      <>
        <Form.Item
          label="Delivery Address"
          name="deliveryAddress"
          id="deliveryAddress"
          className={classes.label}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please enter a delivery address!',
            },
          ]}
        >
          <TextArea rows={3} placeholder="Address to where you want the delivery to be made" />
        </Form.Item>
        <Form.Item
          label="Desired Delivery Time"
          name="deliveryTime"
          id="deliveryTime"
          rules={[{ required: true, message: 'Please input your desired delivery time!', whitespace: true }]}
          className={classes.label}
        >
          <Select className={classes.placeholder} placeholder="Please select your desired delivery time">
            {createOptionsForPickup(0, 24, 1)}
          </Select>
        </Form.Item>
        <Form.Item
          label="On Location Contact Person"
          name="deliveryContactName"
          id="deliveryContactName"
          rules={[
            {
              required: true,
              message: 'Please input name and telephone number of your on location contact person!',
              whitespace: true,
            },
          ]}
          className={classes.label}
        >
          <Input placeholder="Name and telephone number of your on location contact person" />
        </Form.Item>
      </>
    );
  };

  const showPickupOrDelivery = () => {
    if (!formattedRangePicker) return <p>Please select a start and an end date.</p>;

    return (
      <>
        <p className={classes.label}>Pickup or Delivery</p>
        <div className={classes.pickupButtonGroup}>
          <Button
            type={showPickup ? 'primary' : 'default'}
            className={`${showPickup ? componentClasses.buttonPrimary : componentClasses.buttonSecondary} 
                          ${classes.pickupButton}`}
            onClick={() => setShowPickup(true)}
          >
            Pickup
          </Button>
          <Button
            type={!showPickup ? 'primary' : 'default'}
            className={`${!showPickup ? componentClasses.buttonPrimary : componentClasses.buttonSecondary} 
                          ${classes.pickupButton}`}
            onClick={() => setShowPickup(false)}
          >
            Delivery
          </Button>
        </div>

        {showTab()}
        <p>Return no later than {formattedRangePicker && formattedRangePicker.dateAfterEnd} 9:00.</p>
      </>
    );
  };

  const onFinish = (values: any) => {
    const requestBody = {
      startDate: values['rentalDate'][0].format('YYYY-MM-DD'),
      endDate: values['rentalDate'][1].format('YYYY-MM-DD'),
      deliveryOrPickup: showPickup ? 'pickup' : 'delivery',
      deliveryAddress: showPickup
        ? 'Not applicable'
        : `${values.deliveryAddress}, Time: ${values.deliveryTime}, Contact: ${values.deliveryContactName}`,
      additionalInfo: values.comment,
      pickupTime: showPickup
        ? values.rentalDate
          ? values.pickup === 'same-day'
            ? moment(values.rentalDate[0]).format('YYYY-MM-DD ').concat(values.pickupTime)
            : moment(values.rentalDate[0]).subtract(1, 'day').format('YYYY-MM-DD ').concat('16:00 - 17:00')
          : ''
        : 'Not applicable',
      customerInfo: {
        contactPerson: values.name,
        companyName: values.company,
        phone: values.phone,
        email: values.email,
        reference: values.reference,
      },
      cart: {
        cartName: cart.cartName,
        CartGroups: [...cart.cartGroups, { cartGroupName: '', products: cart.ungroupedProducts }],
      },
    };

    let fetchURL = `${AppConfig.API_URL}checkoutCart`;
    fetch(fetchURL, {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        return res && res.json();
      })
      .then((data) => {
        if (data.success) {
          setIsConfirmedModalVisible(true);
        }
      })
      .catch(() => {
        message.error('Something went wrong with your request, please try again later.');
      });
  };

  function disabledDate(current: Moment) {
    return current && current < earliestDate.startOf('day');
  }

  const getForm = async () => {
    const formValues = await form.getFieldsValue();
    const pdfObject = {
      customerInformation: {
        company: formValues.company,
        email: formValues.email,
        name: formValues.name,
        phone: formValues.phone,
        reference: formValues.reference,
      },
      rentalInformation: {
        deliveryAddress: formValues.deliveryAddress,
        deliveryTime: formValues.deliveryTime,
        deliveryContactName: formValues.deliveryContactName,
        pickup: showPickup ? 'pickup' : 'delivery',
        pickupTime: formValues.rentalDate
          ? formValues.pickup === 'same-day'
            ? moment(formValues.rentalDate[0]).format('YYYY-MM-DD ').concat(formValues.pickupTime)
            : moment(formValues.rentalDate[0]).subtract(1, 'day').format('YYYY-MM-DD ').concat('16:00 - 17:00')
          : '',
        startDate: formValues.rentalDate ? moment(formValues.rentalDate[0]).format('YYYY-MM-DD') : '',
        endDate: formValues.rentalDate ? moment(formValues.rentalDate[1]).format('YYYY-MM-DD') : '',
        returnBefore: formValues.rentalDate
          ? moment(formValues.rentalDate[1]).hour(9).minutes(0).add(1, 'day').format('YYYY-MM-DD hh:mm')
          : '',
        comment: formValues.comment,
      },
    };
    return pdfObject;
  };

  const getCartForPDF = () => {
    return {
      cartGroups: [...cart.cartGroups, { cartGroupName: 'Ungrouped products', products: cart.ungroupedProducts }],
    };
  };

  const handleCreatePDF = async () => {
    const pdfObject = await getForm();
    const cartPdfObject = getCartForPDF();
    localStorage.setItem('orderFormData', JSON.stringify(pdfObject));
    localStorage.setItem('cartData', JSON.stringify(cartPdfObject));
    const link = document.createElement('a');
    link.href = '/pdf';
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'norefferrer');
    document.body.appendChild(link);
    link.click();

    // Clean up URL
    link.parentNode?.removeChild(link);
  };

  const handleModalClose = () => {
    setIsConfirmedModalVisible(false);
    clearCart();
    clearOrderForm();
  };

  const handleModalPDF = () => {
    setIsConfirmedModalVisible(false);
    handleCreatePDF();
    clearCart();
    clearOrderForm();
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          if (errorFields.length) {
            const name: any = errorFields[0].name[0];
            const firstErrorElement = document.getElementById(name);
            firstErrorElement?.scrollIntoView({ block: 'center', behavior: 'smooth' });
          }
        }}
      >
        <section className={classes.root}>
          <ContactInfoBox />

          <div className={classes.container}>
            {!loading && generalDocuments ? (
              <p>
                Please note that we only rent to companies. You will find our rental terms{' '}
                <a
                  href={getDocumentLink(documentsObject.RENTAL_TERMS_SWE, generalDocuments)}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.link}
                >
                  here
                </a>
                .
              </p>
            ) : (
              <p>Please note that we only rent to companies.</p>
            )}
            <Form.Item
              label="Start and End Date"
              name="rentalDate"
              id="rentalDate"
              className={classes.label}
              rules={[{ type: 'array', required: true, message: 'Please input date range' }]}
            >
              <RangePicker
                format="YYYY-MM-DD"
                style={{ width: '100%' }}
                value={rangePickerValues}
                onChange={onRangePickerChange}
                disabledDate={disabledDate}
                locale={locale}
                dropdownClassName={classes.rangePickerDropDown}
              />
            </Form.Item>
            {showPickupOrDelivery()}
          </div>

          <div className={classes.container}>
            <Form.Item
              label="Company"
              name="company"
              id="company"
              rules={[{ required: true, message: 'Please input your company name!', whitespace: true }]}
              className={classes.label}
            >
              <Input placeholder="Name of your company" />
            </Form.Item>
            <Form.Item
              label="Contact Person"
              name="name"
              id="name"
              rules={[{ required: true, message: 'Please input the name of a contact person!', whitespace: true }]}
              className={classes.label}
            >
              <Input placeholder="Name of your contact person" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              id="email"
              className={classes.label}
              rules={[
                {
                  required: true,
                  message: 'Please input your email address!',
                  whitespace: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/g).test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Please enter a valid email address!'));
                  },
                }),
              ]}
            >
              <Input type="email" placeholder="Email address you want us to send the offer to" />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              id="phone"
              className={classes.label}
              rules={[
                {
                  required: true,
                  message: 'Please input your phone number!',
                  whitespace: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || new RegExp(/^\+|\d/gm).test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Please enter a correct phone number!'));
                  },
                }),
              ]}
            >
              <Input type="tel" placeholder="Phone number" />
            </Form.Item>
            <Form.Item label="Project reference" name="reference" id="reference" className={classes.label}>
              <Input placeholder="Your own project reference" />
            </Form.Item>
          </div>

          <div className={classes.container}>
            <Form.Item label="Additional comments" name="comment" id="comment" className={classes.label}>
              <TextArea showCount rows={3} maxLength={500} placeholder="Anything else we should know?" />
            </Form.Item>
          </div>
          <p>
            Please note that this is not a confirmed booking. Once your request has been sent, we will contact you
            shortly with an offer.
          </p>
          <div className={classes.buttonGroup}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={`${componentClasses.buttonPrimary} ${classes.button}`}
                disabled={
                  !form.isFieldsTouched() || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                }
              >
                Send Request
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                icon={<InsertDriveFile />}
                type="default"
                className={`${componentClasses.buttonSecondary} ${classes.button}`}
                onClick={handleCreatePDF}
              >
                Export to PDF
              </Button>
            </Form.Item>
          </div>
        </section>
      </Form>
      <Modal visible={isConfirmedModalVisible} footer={null} onCancel={handleModalClose} maskClosable={false}>
        <Result
          status="success"
          title="Request sent successfully!"
          subTitle="Would you like to export your request to a PDF?"
          extra={[
            <div className={classes.buttonGroup}>
              <Button onClick={handleModalClose} className={`${componentClasses.buttonSecondary} ${classes.button}`}>
                No thanks
              </Button>
              <Button
                onClick={handleModalPDF}
                type="primary"
                icon={<InsertDriveFile />}
                className={`${componentClasses.buttonPrimary} ${classes.button}`}
              >
                Export to PDF
              </Button>
            </div>,
          ]}
        />
      </Modal>
    </>
  );
};
