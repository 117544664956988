import { DeleteOutlined, MenuOutlined } from '@ant-design/icons/lib/icons';
import { Button, Typography } from 'antd';
import classes from './DraggableProductItem.module.scss';
import { IListProduct } from '../../Pages/Admin/Products/EditProduct/Tabs/ProductLists/Interfaces/IProductList';
import { Link } from 'react-router-dom';
import { AppConfig } from '../../config';
import { paths } from '../../routes/paths';

interface IFrontPageItem {
  item: IListProduct;
  itemId: number;
  itemIndex: number;
  onRemove: (id: number, name: string) => void;
}

const DraggableProductItem = (props: IFrontPageItem) => {
  return (
    <div className={classes.root}>
      <MenuOutlined className={classes.dragAndDropIcon} />
      {props.item.imagePath && (
        <img
          className={classes.image}
          alt={props.item.fileName}
          src={`${AppConfig.IMAGE_BLOB + props.item.imagePath}`}
        />
      )}
      <Link className={classes.link} to={paths.adminProductBase + props.item.productId}>
        <Typography.Link>{props.item.name}</Typography.Link>
      </Link>
      <Button
        aria-label={`Remove ${props.item.name}`}
        onClick={() => props.onRemove(props.item.id, props.item.name)}
        type="link"
        danger
        icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
      />
    </div>
  );
};
export default DraggableProductItem;
