import { ArrowLeftOutlined } from '@ant-design/icons/lib/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router';
import { paths } from '../../routes/paths';
import classes from './SubHeader.module.scss';

interface ISubHeader {
  title: string;
  productNumber?: string;
  productName?: string;
}

const SubHeader = (props: ISubHeader) => {
  let navigate = useNavigate();
  const goToProductsPage = () => {
    navigate(paths.adminProductsOverview);
  };

  return (
    <div className={classes.subHeader}>
      <Button
        onClick={goToProductsPage}
        aria-label="Go back to product page"
        type="link"
        shape="circle"
        icon={<ArrowLeftOutlined />}
      />
      <h2>{props.title}</h2>
      {(props.productName || props.productNumber) && (
        <span className={classes.product}>
          #{props.productNumber} {props.productName}
        </span>
      )}
    </div>
  );
};

export default SubHeader;
