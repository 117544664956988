import { Spin } from 'antd';
import classes from './LoadingState.module.scss';

interface ILoadingState {
  text?: string;
}

export const LoadingState = (props: ILoadingState) => {
  return (
    <div className={classes.root}>
      <p>{props.text ?? 'Loading...'}</p>
      <Spin />
    </div>
  );
};
