import { Form, Input, Row, Col, Button, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { AppConfig } from '../../../../config';
import { headers } from '../../../../api';
import classes from './ContactForm.module.scss';
import componentClasses from '../../../../styles/Components.module.scss';
import { useState } from 'react';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

interface IContactInformation {
  subject: string;
  name: string;
  company?: string;
  message: string;
  email: string;
  phone?: string;
}

export const ContactForm = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showMessageErrors, setShowMessageErrors] = useState(false);

  const onFinish = (values: IContactInformation) => {
    fetch(`${AppConfig.API_URL}contactForm`, {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      body: JSON.stringify(values),
    })
      .then((res) => {
        return res && res.json();
      })
      .then((data) => {
        if (data.success) setShowSuccessMessage(true);
      })
      .catch(() => {
        message.error('Something went wrong, please try again later.');
      });
  };

  const onFinishFailed = (errors: ValidateErrorEntity<IContactInformation>) => {
    setShowMessageErrors(errors.errorFields.find((e) => e.name[0] === 'message') !== undefined);
  };

  return (
    <>
      <h2 className={classes.formTitle}>Get in touch with us today!</h2>
      {!showSuccessMessage && (
        <Form
          name="contact_us"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          className={classes.contactForm}
        >
          <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true, message: 'Please enter a subject! For example Invoice, Booking, Price, etc.' }]}
          >
            <Input placeholder="For example Invoice, Booking, Price, etc." />
          </Form.Item>
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter your name!' }]}>
                <Input placeholder="Your name" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="Company" name="company" rules={[{ required: false, message: 'Company' }]}>
                <Input placeholder="Company" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Message"
                className={classes.messageItem}
                name="message"
                rules={[{ required: true, message: 'Please enter your message!' }]}
              >
                <TextArea
                  placeholder="Your message"
                  showCount
                  maxLength={1000}
                  rows={5}
                  style={{ borderColor: showMessageErrors ? '#ff4d4f' : '' }}
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please enter your email!' }]}>
                <Input placeholder="Your Email" type="email" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="Phone" name="phone" rules={[{ required: false, message: 'Phone' }]}>
                <Input placeholder="Phone" type="tel" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className={classes.contactFormButtonWrapper}>
            <Button
              htmlType="submit"
              size="large"
              className={`${componentClasses.buttonPrimary} ${classes.contactFormButton}`}
            >
              SEND MESSAGE
            </Button>
          </Form.Item>
        </Form>
      )}
      {showSuccessMessage && (
        <div>
          <p>Thank you for your message. We will be in touch!</p>
        </div>
      )}
    </>
  );
};
