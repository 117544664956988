import { DeleteOutlined, MenuOutlined } from '@ant-design/icons/lib/icons';
import { Typography, Button, InputNumber } from 'antd';
import { AppConfig } from '../../../../../../../../config';
import { paths } from '../../../../../../../../routes/paths';
import { IListProduct } from '../../Interfaces/IProductList';
import classes from './ProductListItem.module.scss';

interface IProductListItem {
  item: IListProduct;
  showNumberOfItems: boolean;
  itemId: number;
  itemIndex: number;
  onRemove: (id: number, name: string) => void;
  handleQuantityChange?: (value: number, id: number) => void;
}

const ProductListItem = (props: IProductListItem) => {
  const handleNumberChange = (value: number) => {
    props.handleQuantityChange && props.handleQuantityChange(value, props.itemId);
  };

  return (
    <div className={classes.root}>
      <MenuOutlined className={classes.dragAndDropIcon} />
      {props.item.imagePath ? (
        <img
          className={classes.image}
          alt={props.item.fileName}
          src={`${AppConfig.IMAGE_BLOB}${props.item.imagePath}`}
        />
      ) : (
        <div className={classes.image} />
      )}
      {props.showNumberOfItems && (
        <InputNumber
          className={classes.input}
          defaultValue={props.item.numberOfItems}
          min={0}
          onChange={(value) => handleNumberChange(value)}
        />
      )}
      <a className={classes.link} href={paths.adminProductBase + props.item.id}>
        <Typography.Link>{props.item.name}</Typography.Link>
      </a>
      <Button
        aria-label={`Remove ${props.item.name}`}
        onClick={() => props.onRemove(props.item.id, props.item.name)}
        type="link"
        danger
        icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
      />
    </div>
  );
};

export default ProductListItem;
