import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';
/* import { useCallback, useEffect, useState } from 'react'; */
import { AppConfig } from '../../../../../config';

export const PDFProductComponent = (props: any) => {
  /*   const [image, setImage] = useState<any>(); */

  const styles = StyleSheet.create({
    product: {
      paddingVertical: '2mm',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      fontSize: '8pt',
    },
    productNumber: {
      marginHorizontal: '5mm',
      width: '12%',
      textAlign: 'center',
    },
    productName: {
      paddingHorizontal: '5mm',
      display: 'flex',
      flexDirection: 'row',
      flex: '1',
      textOverflow: 'ellipsis',
      width: '70%',
      color: 'black',
      textDecoration: 'none',
    },
    image: {
      width: '10mm',
      height: '10mm',
    },
    quantity: {
      paddingHorizontal: '5mm',
      width: '3%',
    },
  });

  /*   const blobToBase64 = useCallback((blob: Blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(convert(reader.result));
      reader.readAsDataURL(blob);
    });
  }, []);

  const getBuffer = useCallback(async () => {
    const res = await fetch(`${AppConfig.IMAGE_BLOB}${props.product.imagePath}`, {
      method: 'GET',
      headers: {},
    });
    if (res.status !== 404) {
      blobToBase64(await res.blob());
    } else convert('');
  }, [blobToBase64, props.product.imagePath]);

  const convert = (imgStr: any) => {
    var canvas = document.createElement('canvas');
    canvas.width = 500;
    canvas.height = 500;
    var ctx = canvas.getContext('2d');
    const temp0 = canvas.toDataURL('image/png', 0.1);
    setImage(temp0);

    var img = new Image();
    img.src = imgStr;
    img.width = 500;
    img.height = 500;
    img.onload = () => {
      ctx?.drawImage(img, 0, 0, 500, 500);
      const temp = canvas.toDataURL('image/png', 0.1);
      setImage(temp);
    };
  };

  useEffect(() => {
    getBuffer();
  }, [getBuffer]); */

  return (
    <View style={styles.product} wrap={false}>
      <Text style={styles.productNumber}>{props.product.productNumber}</Text>
      {/* <Bild src={image ?? ''} style={styles.image}></Bild> */}
      <Link
        src={`${AppConfig.PDF_PRODUCT_LINK}${props.product.id}/productNumber/${props.product.productNumber}`}
        style={styles.productName}
      >
        {props.product.productName}
      </Link>
      <Text style={styles.productNumber}>{props.product.numberOfItems}</Text>
    </View>
  );
};
