import { DeleteOutlined, MenuOutlined } from '@ant-design/icons/lib/icons';
import { Button, Typography } from 'antd';
import classes from './DraggableCarouselImageItem.module.scss';
import { AppConfig } from '../../config';

interface ICarouselImage {
  id: number;
  image: string;
  link: string;
}

export interface IDraggableCarouselImageItem {
  item: ICarouselImage;
  itemId: number;
  itemIndex: number;
  currentIdAndRow: (data: number, index: number) => void;
  onRemove: (id: number) => void;
}

export const DraggableCarouselImageItem = (props: IDraggableCarouselImageItem) => {
  const handleDragItem = () => {
    props.currentIdAndRow(props.itemId, props.itemIndex);
  };

  return (
    <div onMouseDown={handleDragItem} className={classes.root}>
      <MenuOutlined className={classes.dragAndDropIcon} />
      {props.item.image && (
        <img className={classes.image} alt="" src={`${AppConfig.CAROUSEL_IMAGE_BLOB + props.item.image}`} />
      )}
      <Typography className={classes.link}>{props.item.link}</Typography>
      <Button
        aria-label={'Remove image?'}
        onClick={() => props.onRemove(props.item.id)}
        type="link"
        danger
        icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
      />
    </div>
  );
};
