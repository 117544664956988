import { Modal } from 'antd';

interface IConfirmRemoveModal {
  itemName: string;
  visible: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmRemoveModal = (props: IConfirmRemoveModal) => {
  return (
    <Modal
      title={`Remove ${props.itemName}`}
      visible={props.visible}
      onOk={() => props.onConfirm()}
      onCancel={() => props.onClose()}
      okText="Remove"
      okButtonProps={{ type: 'primary', danger: true }}
      closable={false}
    >
      <p>Are you sure you want to remove {props.itemName}?</p>
    </Modal>
  );
};
