import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useState } from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { useGet } from 'restful-react';
import { ConfirmRemoveModal } from '../../../Components/ConfirmRemoveModal/ConfirmRemoveModal';
import { DraggableCarouselImageItem } from '../../../Components/DraggableCarouselImageItem/DraggableCarouselImageItem';
import { LoadingState } from '../../../Components/LoadingState/LoadingState';
import { ConnectionError } from '../../Shop/Components/ConnectionError/ConnectionError';
import { AddCarouselImageModal } from './Components/AddCarouselImageModal';
import { ICarouselImageItem } from './ImageCarousel';
import classes from './ImageCarousel.module.scss';
import { moveCarouselItem, removeCarouselItem } from './ImageCarouselFunctions';

export const FilmEquipmentCarousel = () => {
  const PAGE: string = 'FilmEquipment';
  const clearState: ICarouselImageItem = {
    id: 0,
    link: '',
    image: '',
    itemIndex: 0,
  };

  const [confirmRemoveItem, setConfirmRemoveItem] = useState<ICarouselImageItem>(clearState);
  const [images, setImages] = useState<ICarouselImageItem[] | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const [currentRow, setCurrentRow] = useState<number | null>(null);

  const { loading, refetch } = useGet({
    path: '/carousel?page=FilmEquipment',
    resolve: (data) => {
      setImages(data.carouselImages);
    },
  });

  const onOpen = () => setIsModalVisible(true);

  const onClose = () => setIsModalVisible(false);

  const removeItem = (id: number) => {
    removeCarouselItem(id, refetch);
    setConfirmRemoveItem(clearState);
  };

  // Sets the row index and category id of the grabbed item
  const currentId = (data: number, index: number) => {
    setSelectedRowId(data);
    setCurrentRow(index);
  };

  // Handle the Drag and drop change, set the products-array to new order
  const handleRLDDChange = (newOrderdArray: ICarouselImageItem[]) => {
    setImages(newOrderdArray);
    let newRowPosition: any;

    newOrderdArray.forEach((item, index = 0) => {
      if (item.id === selectedRowId) {
        newRowPosition = index;
      }
    });

    if (currentRow !== null && currentRow >= 0 && images) {
      if (selectedRowId && currentRow < newRowPosition) {
        let afterId = images[newRowPosition].id;
        moveCarouselItem(selectedRowId, afterId, PAGE);
      }
      if (selectedRowId && currentRow > newRowPosition) {
        let afterId;
        if (newRowPosition === 0) {
          afterId = images[newRowPosition].id;
        } else {
          afterId = images[newRowPosition - 1].id;
        }
        moveCarouselItem(selectedRowId, afterId, PAGE);
      }
    }
  };

  return (
    <>
      <div className={classes.header}>
        <Title level={3}>Film Equipment Images</Title>
        <Button type="primary" icon={<PlusOutlined />} onClick={onOpen}>
          Add
        </Button>
      </div>
      {loading ? (
        <LoadingState text="Loading Film Equipment images..." />
      ) : images ? (
        <div className={classes.imageGridContainer}>
          {images.length > 0 ? (
            <RLDD
              items={images}
              onChange={handleRLDDChange}
              itemRenderer={(item: ICarouselImageItem, index: number) => {
                return (
                  <DraggableCarouselImageItem
                    item={item}
                    itemId={item.id}
                    itemIndex={index}
                    currentIdAndRow={currentId}
                    onRemove={(id) => setConfirmRemoveItem({ id, image: '', link: '', itemIndex: 0 })}
                  />
                );
              }}
            />
          ) : (
            <p className={classes.emptyText}>This carousel is empty</p>
          )}
        </div>
      ) : (
        <ConnectionError />
      )}
      <AddCarouselImageModal
        page={PAGE}
        visible={isModalVisible}
        refetch={refetch}
        showModal={setIsModalVisible}
        onCancel={onClose}
      />
      <ConfirmRemoveModal
        visible={confirmRemoveItem.id ? true : false}
        onConfirm={() => confirmRemoveItem.id && removeItem(confirmRemoveItem.id)}
        onClose={() => setConfirmRemoveItem(clearState)}
        itemName={'this image'}
      />
    </>
  );
};
