import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import classes from './FavouriteButton.module.scss';

interface IFavouriteButton {
  checked?: boolean;
  posRelative?: boolean;
  productId: number;
}

export const loadFavouritesFromLocalStorage = () => {
  let loadedStorage: string = localStorage.getItem('favourites') || '[]';
  if (
    loadedStorage.length < 2 ||
    loadedStorage.charAt(0) !== '[' ||
    loadedStorage.charAt(loadedStorage.length - 1) !== ']'
  )
    loadedStorage = '[]';

  return JSON.parse(loadedStorage);
};

export const FavouriteButton = (props: IFavouriteButton) => {
  let favourites = loadFavouritesFromLocalStorage();
  const [checked, setChecked] = useState(props.productId && favourites?.includes(props.productId));

  useEffect(() => {
    setChecked(props.productId && favourites?.includes(props.productId));
  }, [props.productId, favourites]);

  const favouriteButtonClicked = () => {
    favourites = loadFavouritesFromLocalStorage();
    if (!checked) {
      favourites.push(props.productId);
      setChecked(true);
    } else {
      setChecked(false);
      favourites = favourites?.filter((fav: number) => fav !== props.productId);
    }
    localStorage.setItem('favourites', JSON.stringify(favourites));
    window.dispatchEvent(new Event('favourite'));
  };

  const styleString: React.CSSProperties = props.posRelative
    ? { position: 'relative', fontSize: '17px' }
    : { position: 'absolute', top: '12px', left: '12px' };

  return checked ? (
    <HeartFilled
      style={{ ...styleString, color: '#F5222D' }}
      className={classes.heartButton}
      onClick={() => favouriteButtonClicked()}
    />
  ) : (
    <HeartOutlined style={styleString} className={classes.heartButton} onClick={() => favouriteButtonClicked()} />
  );
};
