import { DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Form, Input, Switch, Tag, Tooltip, TreeSelect, Typography } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import TextArea from 'antd/lib/input/TextArea';
import { SHOW_ALL } from 'rc-tree-select';
import { useState } from 'react';
import { IImageData, IImageFile } from '../../../../../Components/PicturesGrid/types';
import PicturesGrid from '../../../../../Components/PicturesGrid/PicturesGrid';
import { IProduct } from '../EditProduct';
import classes from './ProductTab.module.scss';

export interface ICategory {
  categoryText?: string;
  id: number;
  isPublished: boolean;
  name: string;
  parentId: number;
  products?: IProduct;
  sortOrder: number;
  subCategories: ICategory[];
}
export interface IProductTabInformation {
  images: IImageData[];
  categories?: ICategory[];
}
export interface IProductTab extends IProductTabInformation {
  form?: IProductTabForm;
  isCreatorMode: boolean;
  onFormChange: (formValues: any) => void;
  removeImage: (imageId: string) => void;
  checkedCategories: number[];
  onCheckChange: (checkKeys: number[]) => void;
  handleChange: ({ fileList }: { fileList: UploadFile[] }) => void;
  imageFileList: IImageFile[];
  updateTags: (tagsList: string[]) => void;
  errorState: IFormError[];
  searchTags: ISearchTag[];
  onUploadFile: (image: RcFile) => void;
}
export interface IProductTabForm {
  name: string;
  productNumber: string;
  referenceId: number;
  description: string;
  price: number;
  mainProduct: boolean;
  eMaxNumber: string;
  searchTags: ISearchTag[];
  isPublished: boolean;
}

export interface ISearchTag {
  id: number;
  tag: string;
  productId: number;
}

export interface IFormError {
  identifier: string;
  type: 'error' | 'warning' | '';
}

const ProductTab = (props: IProductTab) => {
  const { Title, Text } = Typography;

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [searchTags, setSearchTags] = useState(
    (props.searchTags &&
      props.searchTags.map((x) => {
        return x.tag;
      })) ??
      [],
  );

  const updateForm = (formValues: IProductTabForm) => {
    props.onFormChange(formValues);
  };

  const onChange = (keys: number[]) => {
    props.onCheckChange(keys);
  };

  const handleClose = (removedTag: any) => {
    const tags = searchTags.filter((tag) => tag !== removedTag);
    setSearchTags(tags);
    props.updateTags(tags);
  };

  const handleInputConfirm = () => {
    if (inputValue && searchTags.indexOf(inputValue) === -1) {
      setSearchTags([...searchTags, inputValue]);
      props.updateTags([...searchTags, inputValue]);
    }
    setInputValue('');
    setInputVisible(false);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...searchTags];
    newTags[editInputIndex] = editInputValue;

    setSearchTags(newTags);
    props.updateTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  return (
    <div className={classes.editProductForm}>
      <div className={classes.productImages}>
        <Title level={5}>Product images</Title>
        <Text type="secondary">
          Drag and drop to change the order of the images. The first image will be used as the thumbnail for the
          product.
        </Text>
        <div className={classes.imageGridContainer}>
          <PicturesGrid
            onChange={props.handleChange}
            removeImage={(imageId: string) => props.removeImage(imageId)}
            fileList={props.images}
            imageFileList={props.imageFileList}
            listType="picture-card"
            onUploadFile={props.onUploadFile}
          >
            <div className={classes.uploadSquare}>
              <UploadOutlined style={{ fontSize: '20px' }} />
              <div>Upload</div>
            </div>
          </PicturesGrid>
        </div>
      </div>
      <Form layout="vertical" onValuesChange={(_, formValues) => updateForm(formValues)}>
        <div className={classes.mainToggleComponent}>
          <Form.Item initialValue={props.form?.mainProduct} name="mainProduct">
            <Switch checked={props.form?.mainProduct} />
          </Form.Item>
          <Form.Item className={classes.mainToggleSwitch}>
            <Title level={5}>This product has its own product page</Title>
            <Text type="secondary">
              This means that the product will have a clickable link when listed as an Included or Optional item.
            </Text>
          </Form.Item>
        </div>
        <Form.Item label="Product Name" name="name" required initialValue={props.form?.name}>
          <Input
            allowClear
            status={props.errorState.find((es) => es.identifier === 'name')?.type}
            autoComplete="off"
            name="name"
            placeholder="Enter product name... "
          />
        </Form.Item>
        <div className="form-two-cols">
          <Form.Item
            label="Product Number"
            name="productNumber"
            required
            help="This number is visible on the website"
            initialValue={props.form?.productNumber}
          >
            <Input
              allowClear
              status={props.errorState.find((es) => es.identifier === 'productNumber')?.type}
              autoComplete="off"
              placeholder="Enter product number... "
            />
          </Form.Item>
          <Form.Item
            label="eMax Number"
            name="eMaxNumber"
            required
            help="This number is only visible in admin"
            initialValue={props.form?.eMaxNumber}
          >
            <Input allowClear autoComplete="off" placeholder="Enter eMax number... " name="eMaxNumber" />
          </Form.Item>
        </div>
        <Form.Item label="Description" name="description" initialValue={props.form?.description ?? ''}>
          <TextArea allowClear placeholder="Enter description... " rows={4} />
        </Form.Item>
        <Form.Item label="Categories" name="categories" initialValue={props.checkedCategories}>
          <TreeSelect
            allowClear
            treeData={props.categories ?? []}
            fieldNames={{ label: 'name', value: 'id', children: 'subCategories' }}
            placeholder="Select categories..."
            treeCheckable
            showArrow
            showCheckedStrategy={SHOW_ALL}
            onChange={onChange}
          />
        </Form.Item>
        <div className="form-two-cols">
          <Form.Item label="Price" name="price" initialValue={props.form?.price}>
            <Input autoComplete="off" name="price" suffix="kr" placeholder="Set price..." />
          </Form.Item>
          <Form.Item label="Search Tags" name="searchTags" initialValue={props.form?.searchTags}>
            {searchTags.map((tag, index) => {
              if (editInputIndex === index) {
                return (
                  <Input
                    key={tag}
                    className={classes.tagInput}
                    value={editInputValue}
                    onChange={(e) => setEditInputValue(e.target.value)}
                    onBlur={handleEditInputConfirm}
                    onPressEnter={handleEditInputConfirm}
                  />
                );
              }

              const isLongTag = tag.length > 20;

              const tagElem = (
                <Tag className={classes.editTag} key={tag} closable={index >= 0} onClose={() => handleClose(tag)}>
                  <span
                    onDoubleClick={(e) => {
                      if (index >= 0) {
                        setEditInputIndex(index);
                        setEditInputValue(tag);
                        e.preventDefault();
                      }
                    }}
                  >
                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                  </span>
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
            {inputVisible && (
              <Input
                autoFocus
                name="searchTags"
                type="text"
                className={classes.tagInput}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            )}
            {!inputVisible && (
              <Tag className={classes.siteTagPlus} onClick={() => setInputVisible(true)}>
                <PlusOutlined /> New Tag
              </Tag>
            )}
            <Tag
              className={classes.siteTagRemove}
              onClick={() => {
                setSearchTags([]);
                props.updateTags([]);
              }}
            >
              <DeleteOutlined /> Clear All Tags
            </Tag>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ProductTab;
