import { CheckOutlined, UnorderedListOutlined } from '@ant-design/icons';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Button, Col, Row, Spin, Tabs } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useGet } from 'restful-react';
import { useWindowDimensions } from '../../../../Utils/Hooks/useWindowDimensions';
import { DrawingAreaContext } from '../../../../Utils/Providers/DrawingAreaContextProvider';
import { ConnectionError } from '../../Components/ConnectionError/ConnectionError';
import { Container } from '../../Components/Container/Container';
import { FavouriteButton } from '../../Components/FavouriteButton/FavouriteButton';
import { GobackButton } from '../../Components/GobackButton/GobackButton';
import { AddToCart } from '../../Components/ProductList/ProductCard/AddToCart/AddToCart';
import { IPublishedProduct } from '../../Interfaces/IProduct';
import { IProductInCart } from '../Cart/CartComponent';
import { Documents } from './Documents/Documents';
import { Images } from './Images/Images';
import { IncludedProducts } from './IncludedProducts/IncludedProducts';
import { OptionalItems } from './OptionalItems/OptionalItems';
import classes from './ProductDetails.module.scss';
import { RelatedProducts } from './RelatedProducts/RelatedProducts';
import { TechnicalSpecifications } from './TechnicalSpecifications/TechnicalSpecifications';

export const ProductDetails = () => {
  let { id } = useParams<{ id: string }>();
  const [activeKey, setActiveKey] = useState('1');
  const listRef = useRef<HTMLUListElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();
  const [listItemStyle, setListItemStyle] = useState('');
  const [headerFlow, setHeaderFlow] = useState('column');
  const { state: drawingArea } = useContext(DrawingAreaContext);

  const { data: product, loading } = useGet<IPublishedProduct>({
    path: `/published-products/${id}`,
  });

  const { TabPane } = Tabs;

  const passToAddToCart: IProductInCart = {
    productName: product?.name ?? '',
    id: product?.id ?? 0,
    eMaxNumber: product?.eMaxNumber ?? '',
    productNumber: product?.productNumber ?? '',
    imagePath: product?.images[0]?.imagePath ?? '',
    uniqueProductId: Math.floor(Math.random() * 10000),
    isMainProduct: product?.mainProduct,
    numberOfItems: 0,
  };

  useEffect(() => {
    if (listRef.current?.clientWidth && listRef.current.clientWidth >= 1000) {
      setListItemStyle(classes.largeTabsList);
    } else if (listRef.current?.clientWidth && listRef.current.clientWidth >= 550) {
      setListItemStyle(classes.mediumTabsList);
    } else {
      setListItemStyle('');
    }
  }, [listRef, width, drawingArea, loading]);

  useEffect(() => {
    const headerWidth = headerRef.current ? headerRef.current.clientWidth : 0;
    const imageContainerWidth = imageContainerRef.current ? imageContainerRef.current.clientWidth : 0;
    const productDetailWidth = headerWidth - imageContainerWidth;
    setHeaderFlow(productDetailWidth > 500 ? 'row' : 'column');
  }, [drawingArea, width, loading]);

  useEffect(() => {
    setActiveKey('1');
  }, [id]);

  return (
    <>
      {product ? (
        <Container>
          <header className={classes.header} ref={headerRef}>
            <div className={classes.productTitleWrapper}>
              <h1 className={classes.productTitle}>
                <GobackButton />
              </h1>
              <h1 className={classes.productTitle}>{product.name}</h1>
            </div>
            <div
              className={classes.imageContainer}
              style={{ '--containerDirection': headerFlow } as React.CSSProperties}
            >
              {product.images.length > 0 && (
                <Images images={product.images} productName={product.name} ref={imageContainerRef} />
              )}

              <div className={classes.productInformation}>
                <p className={classes.modelText}>
                  <span className={classes.modelTextLabel}>Product No. </span>
                  <span className={classes.modelTextNumber}>{product.productNumber}</span>
                  <FavouriteButton posRelative productId={product.id} />
                </p>
                <p>{product.description && product.description !== 'null' ? product.description : null}</p>
                <div className={classes.addToCartContainer}>
                  <AddToCart product={passToAddToCart} isCurrentProduct={true} />
                </div>
                {product.addOnProducts?.length && headerFlow === 'row' ? (
                  <OptionalItems
                    key={product.id}
                    productNumber={product.productNumber}
                    products={product.addOnProducts ?? []}
                  />
                ) : null}
              </div>
            </div>
          </header>
          <Row gutter={24}>
            <Col md={24} style={{ width: '100%' }}>
              <Tabs defaultActiveKey="1" className={classes.tabs} activeKey={activeKey}>
                <ul className={classes.tabsList} ref={listRef}>
                  <li className={`${classes.tabsListItem} ${listItemStyle}`}>
                    <Button
                      onClick={() => setActiveKey('1')}
                      className={activeKey === '1' ? classes.activeButton : classes.inactiveButton}
                    >
                      <CheckOutlined />
                      Included items
                    </Button>
                  </li>
                  <li className={`${classes.tabsListItem} ${listItemStyle}`}>
                    <Button
                      onClick={() => setActiveKey('2')}
                      className={activeKey === '2' ? classes.activeButton : classes.inactiveButton}
                    >
                      <UnorderedListOutlined /> Specifications
                    </Button>
                  </li>
                  <li className={`${classes.tabsListItem} ${listItemStyle}`}>
                    <Button
                      onClick={() => setActiveKey('3')}
                      className={activeKey === '3' ? classes.activeButton : classes.inactiveButton}
                    >
                      <AttachFileIcon className={classes.icon} />
                      Downloads
                    </Button>
                  </li>
                </ul>
                <TabPane
                  tab={
                    <span>
                      <CheckOutlined />
                      Included items
                    </span>
                  }
                  key="1"
                >
                  <IncludedProducts products={product.includedProducts ?? []} />
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <UnorderedListOutlined /> Specifications
                    </span>
                  }
                  key="2"
                >
                  <TechnicalSpecifications attributes={product.attributes} />
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <AttachFileIcon className={classes.icon} />
                      Downloads
                    </span>
                  }
                  key="3"
                >
                  <Documents documents={product.documents} />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
          {product.addOnProducts?.length && headerFlow === 'column' ? (
            <OptionalItems
              key={product.id}
              productNumber={product.productNumber}
              products={product.addOnProducts ?? []}
            />
          ) : null}
          <RelatedProducts products={product.relatedProducts ?? []} />
        </Container>
      ) : loading ? (
        <div className={classes.spinnerWrapper}>
          <p>Loading Product...</p>
          <Spin />
        </div>
      ) : (
        <ConnectionError />
      )}
    </>
  );
};
