import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { IProduct } from '../../../Admin/Products/EditProduct/EditProduct';
import { ProductList } from '../../Components/ProductList/ProductList';
import { IFrontPageProduct } from '../../Interfaces/IProduct';
import { Result, Spin, Select } from 'antd';
import { useGet } from 'restful-react';
import classes from './SearchedProducts.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

const SearchedProducts = () => {
  const PAGE: string = 'search';
  const location = useLocation();
  const loadingIcon = <LoadingOutlined style={{ fontSize: 56 }} spin />;
  let searchTextString = decodeURI(location.search.slice(1));
  const [matchedProducts, setMatchedProducts] = useState<IFrontPageProduct[]>([]);
  const [showcaseProducts, setShowcaseProducts] = useState<IFrontPageProduct[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(20);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(20);

  const { data: products, loading } = useGet({
    path: 'publishedProducts',
    resolve: (data) => data.products,
  });

  const filterProducts = useCallback(
    (array: IProduct[]) => {
      let filteredArray = array.filter((product: IProduct) => {
        return (
          product.name.toLowerCase().includes(searchTextString.toLowerCase()) ||
          product.productNumber.toLowerCase().includes(searchTextString.toLowerCase()) ||
          product.searchTag?.some((searchTagItem: any) => {
            return searchTagItem.tag.toLowerCase().includes(searchTextString.toLowerCase());
          })
        );
      });
      filteredArray.sort((a, b) => a.name.localeCompare(b.name));
      setMatchedProducts(filteredArray);
      setShowcaseProducts(filteredArray);
    },
    [searchTextString],
  );

  const sortProducts = (value: any) => {
    let array = [...matchedProducts];
    if (value === 'Alphabetical, ascending') {
      array.sort((a, b) => a.name.localeCompare(b.name));
    } else if (value === 'Alphabetical, descending') {
      array.sort((a, b) => b.name.localeCompare(a.name));
    } else array = [...matchedProducts];
    setShowcaseProducts(array);
  };

  const getCurrentPageSettings = useCallback(() => {
    const sessionItemPage = sessionStorage.getItem('currentShopPage');
    const sessionItemSize = sessionStorage.getItem('currentShopPageSize');

    if (
      sessionItemPage !== null &&
      sessionItemSize !== null &&
      sessionItemPage.split(',')[2] === PAGE &&
      sessionItemSize.split(',')[2] === PAGE
    ) {
      let sessionPage = parseInt(sessionItemPage.split(',')[0]);
      let sessionSize = parseInt(sessionItemSize.split(',')[0]);

      setCurrentPage(sessionPage);
      setCurrentPageSize(sessionSize);
      setMinIndex((sessionPage - 1) * sessionSize);
      setMaxIndex(sessionPage * sessionSize);
    } else {
      setCurrentPage(1);
      setCurrentPageSize(20);
      setMinIndex(0);
      setMaxIndex(20);
    }
  }, []);

  useEffect(() => {
    !loading && filterProducts(products);
    getCurrentPageSettings();
  }, [products, filterProducts, loading, getCurrentPageSettings]);

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <h1>Search results for: {searchTextString}</h1>
        <Select placeholder="Sort products" allowClear onChange={sortProducts}>
          <Select.Option value="Alphabetical, ascending">A-Z, ascending</Select.Option>
          <Select.Option value="Alphabetical, descending">A-Z, descending</Select.Option>
        </Select>
      </div>
      {loading ? (
        <Spin tip="Searching..." className={classes.loadingContainer} indicator={loadingIcon} />
      ) : matchedProducts.length > 0 ? (
        <ProductList
          products={showcaseProducts}
          loading={loading}
          page={PAGE}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          setCurrentPage={setCurrentPage}
          setCurrentPageSize={setCurrentPageSize}
          minIndex={minIndex}
          maxIndex={maxIndex}
          setMinIndex={setMinIndex}
          setMaxIndex={setMaxIndex}
        />
      ) : (
        <Result status="warning" title={`There were no search results matching ${searchTextString}`} />
      )}
    </div>
  );
};

export default SearchedProducts;
