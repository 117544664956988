import {
  CheckOutlined,
  CloudOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  PlusOutlined,
} from '@ant-design/icons/lib/icons';
import { Button, Modal, Space, Table, Typography } from 'antd';
import { Key, useCallback, useEffect, useState } from 'react';
import { Link, Route, Routes as RouterSwitch, useNavigate } from 'react-router-dom';
import { useGet } from 'restful-react';
import FilterTable from '../../../Components/FilterTable/FilterTable';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import CreateProductPage from './CreateProductPage';
import EditProductPage from './EditProduct/EditProductPage';
import classes from './ProductOverview.module.scss';
import { useDebouncedCallback } from 'use-debounce';
import { IProduct } from './EditProduct/EditProduct';
import { AppConfig } from '../../../config';
import { AdminContainer } from '../Components/AdminContainer/AdminContainer';
import { paths } from '../../../routes/paths';
import { headers } from '../../../api';

const ProudctsOverview = () => {
  const [filteredProducts, setFilteredProducts] = useState<IProduct[]>();
  const [textToSearch, setTextToSearch] = useState<string>(() => {
    const t = sessionStorage.getItem('searchText');
    if (t) {
      return t;
    } else return '';
  });
  const [isPublishedFilter, setIsPublishedFilter] = useState<boolean | null>(() => {
    const x = sessionStorage.getItem('publishedFilter');
    if (x === 'is-published-true') {
      return true;
    } else if (x === 'is-published-false') {
      return false;
    } else return null;
  });
  const [categoriesListFilter, setCategoriesListFilter] = useState<number[]>(() => {
    const z = sessionStorage.getItem('categoriesFilter');
    if (z) {
      return JSON.parse(z);
    } else return [];
  });
  const [isMainProductFilter, setIsMainProductFilter] = useState<boolean | null>(() => {
    const y = sessionStorage.getItem('mainProductFilter');
    if (y === 'mainProduct-true') {
      return true;
    } else if (y === 'mainProduct-false') {
      return false;
    } else return null;
  });
  const [selectedRowData, setSelectedRowData] = useState<IProduct[]>([]);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState<Key[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(() => {
    const k = sessionStorage.getItem('currentPage');
    return k ? parseInt(k) : 1;
  });
  const [currentPageSize, setCurrentPageSize] = useState<number>(() => {
    const k = sessionStorage.getItem('currentPageSize');
    return k ? parseInt(k) : 10;
  });

  // let { path } = useRouteMatch();
  const { Paragraph } = Typography;

  const {
    data: products,
    loading,
    refetch,
  } = useGet<IProduct[]>({
    path: '/products',
    resolve: (data) => {
      if (
        isPublishedFilter === null &&
        textToSearch === '' &&
        categoriesListFilter.length <= 0 &&
        isMainProductFilter === null
      ) {
        setFilteredProducts(data.products);
      }
      return data.products;
    },
  });

  let navigate = useNavigate();
  const goToProductPage = (id: number) => {
    navigate(paths.adminProductBase + id);
  };

  const goToCreateProduct = () => {
    navigate(paths.adminCreateProduct);
  };

  const debounced = useDebouncedCallback((value) => {
    setFilteredProducts(value);
  }, 250);

  const columns = [
    {
      title: 'eMax Number',
      dataIndex: 'eMaxNumber',
      sorter: (firstEl: any, secondEl: any) =>
        firstEl.eMaxNumber.localeCompare(secondEl.eMaxNumber, undefined, { numeric: true }),
    },
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      sorter: (firstEl: any, secondEl: any) =>
        firstEl.productNumber.localeCompare(secondEl.productNumber, undefined, { numeric: true }),
    },
    {
      title: 'Image',
      render: (item: IProduct) =>
        item.imagePath && (
          <img src={AppConfig.IMAGE_BLOB + item.imagePath} alt={item.fileName} className={classes.imageColumn} />
        ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (firstEl: any, secondEl: any) => firstEl.name.localeCompare(secondEl.name),
      render: (text: string, item: IProduct) => <Link to={paths.adminProductBase + item.id}>{text}</Link>,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      align: 'right' as 'right',
      sorter: (firstEl: any, secondEl: any) => firstEl.price - secondEl.price,
      render: (text: string) => (
        <Paragraph style={{ margin: 0 }} ellipsis={{ suffix: ' kr' }}>
          {text}
        </Paragraph>
      ),
    },
    {
      title: 'Product type',
      dataIndex: 'mainProduct',
      sorter: (firstEl: any, secondEl: any) => firstEl.mainProduct - secondEl.mainProduct,
      render: (mainProduct: boolean) => <>{mainProduct ? 'Main product' : 'Add on product'}</>,
    },
    {
      title: 'Published',
      dataIndex: 'isPublished',
      sorter: (firstEl: any, secondEl: any) => firstEl.isPublished - secondEl.isPublished,
      render: (published: boolean) => (
        <div>
          {published ? (
            <CheckOutlined aria-label="This product is published" />
          ) : (
            <p className="sr-only">This product is not published</p>
          )}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'x',
      render: (id: number, item: IProduct) => (
        <Space size="small">
          <Button
            onClick={() => goToProductPage(id)}
            type="link"
            aria-label="Edit product"
            icon={<EditOutlined style={{ fontSize: '20px' }} />}
          />
          <Button
            type="link"
            icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
            aria-label="Delete product"
            onClick={(e) => {
              showModal(item);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // Modal
  const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [productItem, setProductItem] = useState<IProduct>();

  const bulkDeleteProduct = (ids: Key[]) => {
    let url = `${AppConfig.API_URL}products-bulk?`;

    ids.forEach((x) => {
      url = url.concat(`ids=${x}&`);
    });

    fetch(url, {
      method: 'DELETE',
      headers: headers,
      credentials: 'include',
    })
      .then((data) => {
        refetch();
      })
      .catch((error) => console.log(error));
  };

  const deleteProduct = (id: number | null) => {
    id !== null &&
      fetch(`${AppConfig.API_URL}products/${id}`, {
        method: 'DELETE',
        headers: headers,
        credentials: 'include',
      })
        .then((data) => {
          refetch();
        })
        .catch((error) => console.log(error));
  };

  const showModal = (productItem: IProduct) => {
    setIsModalVisible(true);
    setProductItem(productItem);
  };

  const handleOk = () => {
    deleteProduct(productItem?.id ?? null);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showBulkModal = () => {
    setIsBulkModalVisible(true);
  };

  const handleBulkOk = () => {
    bulkDeleteProduct(selectedRowKeysData);
    setIsBulkModalVisible(false);
  };

  const handleBulkCancel = () => {
    setIsBulkModalVisible(false);
  };

  const filterList = useCallback(
    (isMainProduct: boolean | null, categoriesList: number[], isPublished: boolean | null, textString: string) => {
      let initialData = products ?? [];

      if (isMainProduct !== null) {
        initialData = initialData.filter((x) => x.mainProduct === isMainProduct);
      }

      if (isPublished !== null) {
        initialData = initialData.filter((x) => x.isPublished === isPublished);
      }

      if (categoriesList.length > 0) {
        initialData = initialData.filter((product) => {
          return product.category.some((cat) => {
            return categoriesList.includes(cat.id);
          });
        });
      }

      if (textString !== '') {
        initialData = initialData.filter((product: IProduct) => {
          return (
            product.name.toLowerCase().includes(textString.toLowerCase()) ||
            product.productNumber.toLowerCase().includes(textString.toLowerCase()) ||
            product.eMaxNumber.toLowerCase().includes(textString.toLowerCase()) ||
            product.searchTag?.some((searchTagItem) => {
              return searchTagItem.tag.toLowerCase().includes(textString.toLowerCase());
            })
          );
        });
      }
      debounced(initialData);
    },
    [debounced, products],
  );

  const filterPublish = (isPublished: boolean | null, value: string) => {
    setIsPublishedFilter(isPublished);
    sessionStorage.setItem('publishedFilter', value);
    filterList(isMainProductFilter, categoriesListFilter, isPublished, textToSearch);
  };

  const filterCategories = (categories: number[]) => {
    setCategoriesListFilter(categories);
    sessionStorage.setItem('categoriesFilter', JSON.stringify(categories));
    filterList(isMainProductFilter, categories, isPublishedFilter, textToSearch);
  };

  const filterMainProduct = (isMainProduct: boolean | null, value: string) => {
    setIsMainProductFilter(isMainProduct);
    sessionStorage.setItem('mainProductFilter', value);
    filterList(isMainProduct, categoriesListFilter, isPublishedFilter, textToSearch);
  };

  const filterSearch = (searchText: string) => {
    setTextToSearch(searchText);
    sessionStorage.setItem('searchText', searchText);
    filterList(isMainProductFilter, categoriesListFilter, isPublishedFilter, searchText);
  };

  const handleSavePageChange = (page: number, pageSize: number | undefined) => {
    setCurrentPage(page);
    pageSize && setCurrentPageSize(pageSize);
    sessionStorage.setItem('currentPage', page.toString());
    pageSize && sessionStorage.setItem('currentPageSize', pageSize.toString());
  };

  useEffect(() => {
    !loading && filterList(isMainProductFilter, categoriesListFilter, isPublishedFilter, textToSearch);
  }, [loading, filterList, isMainProductFilter, categoriesListFilter, isPublishedFilter, textToSearch]);

  return (
    <AdminContainer>
      <PageHeader title="Products" search onSearch={filterSearch} searchText={textToSearch} />
      <div className={classes.container}>
        <RouterSwitch>
          <Route
            path={'/'}
            element={
              <>
                <div className="above-table padding-x padding-top">
                  <FilterTable
                    setPublish={filterPublish}
                    setCategories={filterCategories}
                    setIsMain={filterMainProduct}
                    defaultPublish={isPublishedFilter}
                  />
                  <Button onClick={() => goToCreateProduct()} type="primary" icon={<PlusOutlined />}>
                    Add new product
                  </Button>
                </div>
                <div className="padding-x padding-bottom-small">
                  <label style={{ display: 'block' }}>Bulk action</label>
                  <Button
                    disabled={selectedRowData.length <= 0}
                    onClick={showBulkModal}
                    type="link"
                    icon={<DeleteOutlined />}
                  >
                    Delete
                  </Button>
                  <Button type="link" icon={<EditOutlined />} disabled>
                    Edit
                  </Button>
                  <Button type="link" icon={<LinkOutlined />} disabled>
                    Handle connection
                  </Button>
                  <Button type="link" icon={<CloudOutlined />} disabled>
                    Published
                  </Button>
                </div>
                <Table
                  rowKey="id"
                  rowSelection={{
                    type: 'checkbox',
                    onChange: (selectedRowKeys, selectedRows: IProduct[]) => {
                      setSelectedRowData(selectedRows);
                      setSelectedRowKeysData(selectedRowKeys);
                    },
                  }}
                  columns={columns}
                  dataSource={filteredProducts}
                  className="padding-x"
                  style={{ cursor: 'pointer' }}
                  loading={loading}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        goToProductPage(record.id);
                      },
                    };
                  }}
                  pagination={{ current: currentPage, onChange: handleSavePageChange, pageSize: currentPageSize }}
                />
                <Modal
                  title="Delete Product"
                  visible={isModalVisible}
                  onOk={handleOk}
                  okButtonProps={{ danger: true }}
                  okText={'Yes, Delete Product'}
                  onCancel={handleCancel}
                >
                  Do you want to delete product:
                  <p>
                    {productItem?.name} <br />
                    Reference number: {productItem?.eMaxNumber}
                  </p>
                </Modal>
                <Modal
                  title="Delete Products"
                  visible={isBulkModalVisible}
                  onOk={handleBulkOk}
                  okText={'Yes, delete these products'}
                  okButtonProps={{ danger: true }}
                  onCancel={handleBulkCancel}
                >
                  <p> Do you want to delete these products: </p>
                  {selectedRowData?.map((x: IProduct) => (
                    <>
                      <p>
                        Product name: {x.name}
                        <br />
                        Reference number: {x.eMaxNumber}
                      </p>
                    </>
                  ))}
                </Modal>
              </>
            }
          />
          <Route path={`/create-product`} element={<CreateProductPage />} />
          <Route path={`/product/:id`} element={<EditProductPage />} />
        </RouterSwitch>
      </div>
    </AdminContainer>
  );
};

export default ProudctsOverview;
