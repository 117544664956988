import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { Logo } from '../Assets/Logo';
import image from '../Assets/bild.png';

export const PDFHeader = () => {
  const styles = StyleSheet.create({
    headerContainer: {
      padding: '0px 24px 10px 24px',
      marginBottom: '16px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '8pt',
      borderBottom: '1pt solid #D9D9D9',
    },
    addressContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    imageStyle: {
      height: '18pt',
    },
  });
  return (
    <View style={styles.headerContainer} fixed>
      <Logo />
      <Image src={image} style={styles.imageStyle} />
      <View style={styles.addressContainer}>
        <Text>Flöjelbergsgatan 8</Text>
        <Text>431 35 MÖLNDAL</Text>
      </View>
      <Text>+46 31 - 21 21 21</Text>
      <Text>booking@hyrmax.se</Text>
      <Text>www.hyrmax.se</Text>
    </View>
  );
};
