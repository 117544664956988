import { PlusOutlined } from '@ant-design/icons/lib/icons';
import { Button, Input, AutoComplete } from 'antd';
import { useState } from 'react';
import { IListProduct } from '../../Interfaces/IProductList';
import classes from './ProductListWrapper.module.scss';
import { IProduct } from '../../../../EditProduct';
import { useGet } from 'restful-react';
import { AppConfig } from '../../../../../../../../config';
import { OptionData, OptionGroupData } from './oldRcSelectTypesAndInterfaces';
import { headers } from '../../../../../../../../api';

interface IProductListWrapper {
  onAddProducts: () => void;
  addToList: (products: IListProduct) => void;
}

export const ProductListWrapper: React.FC<IProductListWrapper> = (props) => {
  const { Search } = Input;
  const [value, setValue] = useState<string>('');

  const { data: productList, refetch } = useGet<IProduct[]>({
    path: '/products',
    lazy: true,
    resolve: (data) => data.products,
  });

  const parseOptions = (list: IProduct[]) => {
    let newArray = list.map((x: IProduct) => {
      return { value: `${x.productNumber} : ${x.name}`, key: x.id };
    });
    return newArray;
  };

  const filterOptions = (inputValue: string, option: OptionData | OptionGroupData | undefined) => {
    return option!.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
  };

  const onSelectProduct = (value: string, option: OptionData | OptionGroupData | undefined) => {
    if (option && typeof option.key === 'number') {
      fetch(`${AppConfig.API_URL}product/${option.key}`, { 
        headers: headers, 
        credentials: 'include' 
      })
        .then((res) => res.json())
        .then((data) => {
          let parsedObject: IListProduct = {
            id: parseInt(data.id),
            name: value,
            numberOfItems: 1,
            imagePath: data.image[0]?.imagePath ?? '',
            fileName: data.image[0]?.fileName ?? '',
          };
          props.addToList(parsedObject);
          setValue('');
        });
    }
  };

  return (
    <div className="padding-x">
      <div className={classes.actionbar}>
        <AutoComplete
          className={classes.searchbar}
          notFoundContent="No product found"
          onChange={(e: any) => setValue(e)}
          options={productList ? parseOptions(productList) : undefined}
          filterOption={(inputValue: string, option: any) => filterOptions(inputValue, option)}
          onSelect={(value: string, option: any) => onSelectProduct(value, option)}
          value={value}
          onFocus={!productList ? () => refetch() : undefined}
        >
          <Search allowClear placeholder="Search" />
        </AutoComplete>
        <Button onClick={() => props.onAddProducts()} icon={<PlusOutlined />} type="primary">
          Add products
        </Button>
      </div>
      {props.children}
    </div>
  );
};
