import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { PDFProductComponent } from './PDFProdctComponent';

export const PDFEquipmentListGroup = (props: any) => {
  const styles = StyleSheet.create({
    root: {},
    title: {
      padding: '3mm',
      fontSize: '10pt',
      fontWeight: 'semibold',
      backgroundColor: '#F5F5F5',
    },
    itemList: {
      paddingVertical: '2mm',
    },
  });
  return (
    <View style={styles.root}>
      <Text style={styles.title}>{props.cartGroup.cartGroupName}</Text>
      <View style={styles.itemList}>
        {props.cartGroup.products.map((product: any) => (
          <PDFProductComponent key={product.id} product={product} />
        ))}
      </View>
    </View>
  );
};
