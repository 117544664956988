import { List, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { paths } from '../../../../../routes/paths';
import { AddToCart } from '../../../Components/ProductList/ProductCard/AddToCart/AddToCart';
import { IConnectProduct } from '../../../Interfaces/IProduct';
import { IProductInCart } from '../../Cart/CartComponent';
import classes from './OptionalItems.module.scss';

interface IOptionalItems {
  products: IConnectProduct[];
  productNumber: string;
}

export const OptionalItems = (props: IOptionalItems) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setShowMore(false);
  }, [location.pathname]);

  const products: IProductInCart[] = [
    ...props.products.map((product) => {
      return {
        id: product.id,
        eMaxNumber: product.eMaxNumber,
        isMainProduct: product.mainProduct,
        productName: product.name,
        productNumber: product.productNumber,
        imagePath: product.imagePath ? product.imagePath : '',
        uniqueProductId: Math.floor(Math.random() * 10000),
        addedFromProduct: props.productNumber,
        numberOfItems: 0,
      };
    }),
  ];

  return (
    <div className={classes.root}>
      <Typography.Title level={2} className={classes.title}>
        Optional Items
      </Typography.Title>
      <div className={classes.listContainer}>
        <List
          size="large"
          className={classes.list}
          bordered
          dataSource={showMore ? products : products.slice(0, 4)}
          locale={{
            emptyText: 'No optional items',
          }}
          renderItem={(item, index) => (
            <>
              <List.Item actions={[<AddToCart product={item} padding isInCard />]}>
                <span>
                  {item.isMainProduct ? (
                    <Link
                      to={`${paths.productBase}${item.id}/productNumber/${item.productNumber}`}
                      className={classes.link}
                    >
                      {item.productName}
                    </Link>
                  ) : (
                    <Typography>{item.productName}</Typography>
                  )}
                </span>
              </List.Item>
              {props.products.length > 4 &&
                ((showMore && index === props.products.length - 1) || (!showMore && index === 3)) && (
                  <div key="showMore" className={classes.showMore}>
                    <span className={classes.showMoreLink} onClick={() => setShowMore(!showMore)}>
                      Show {showMore ? 'less' : `more (${props.products.length - 4})`}
                    </span>
                  </div>
                )}
            </>
          )}
        />
      </div>
    </div>
  );
};
