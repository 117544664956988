import { message } from 'antd';
import { ICart, ICartGroup } from '../CartComponent';

export const getCartWithUpdatedGroup = (name: string, cartGroupId: number, cart: ICart) => {
  // If the name of the group already exits, return and show a error message
  if (cart.cartGroups.some((x) => x.cartGroupName === name)) {
    message.error('This name already exits as a group');
    return;
  }

  // If the user is trying to submit a group without a name
  if (name === '' || name === undefined) {
    message.error('Please add at least one character');
    return;
  }

   // Get the products in the group that will be removed
   const updatedCartGroup: ICartGroup | undefined = cart.cartGroups.find((x) => x.id === cartGroupId);

   if (updatedCartGroup){
    updatedCartGroup.cartGroupName = name
   }

   // Get cart
   let copyOfCart: ICart = cart;

   // Remove the group
   const cartGroupsWithoutRemovedGroup = copyOfCart.cartGroups.filter((x) => x.id !== cartGroupId);
 
   // Return the whole cart without the group
   const cartWithoutRemovedGroup: ICart = {
     ...cart,
     cartGroups: updatedCartGroup ? [...cartGroupsWithoutRemovedGroup, updatedCartGroup] : cart.cartGroups,
   };
 
   return cartWithoutRemovedGroup;
};
