import { AdminContainer } from '../../Components/AdminContainer/AdminContainer';
import EditProduct from './EditProduct';

const EditProductPage = () => {
  return (
    <AdminContainer>
      <EditProduct subHeaderTitle="Edit Product" createMode={false} />
    </AdminContainer>
  );
};

export default EditProductPage;
