import { FileOutlined } from '@ant-design/icons';
import { List } from 'antd';
import { AppConfig } from '../../../../../config';
import { IDocument } from '../../../Interfaces/IProduct';
import classes from './Document.module.scss';

interface IDocuments {
  documents: IDocument[];
}

export const Documents = (props: IDocuments) => {
  return (
    <List
      className={classes.root}
      size="large"
      bordered
      dataSource={props.documents}
      locale={{
        emptyText: 'No documents',
      }}
      renderItem={(item) => (
        <List.Item>
          <a rel="noreferrer" href={`${AppConfig.DOCUMENT_BLOB}${item.documentLink}`} className={classes.link}>
            <FileOutlined className={classes.icon} />
            {item.name}
          </a>
        </List.Item>
      )}
    />
  );
};
