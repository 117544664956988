import { DownOutlined } from '@ant-design/icons';
import { Key, useContext, useEffect, useState } from 'react';
import { StyledTree } from '../../../../../Components/StyledTree/StyledTree';
import { DataNode } from 'antd/lib/tree';
import { useNavigate } from 'react-router';
import { paths } from '../../../../../routes/paths';
import { DrawingAreaContext, DrawingAreaTypes } from '../../../../../Utils/Providers/DrawingAreaContextProvider';

interface ISubTree {
  dataNodes: DataNode[];
  showSelf: boolean;
  avSubtree: boolean;
  setShowParent: (show: boolean) => void;
  isUnderBreakpoint: boolean;
}

export const SubTree = (props: ISubTree) => {
  const { state: drawingArea, dispatch } = useContext(DrawingAreaContext);
  const [selectedKeys, setSelectedKeys] = useState(
    props.avSubtree ? drawingArea.av.selectedKeys : drawingArea.film.selectedKeys,
  );
  const [expandedKeys, setExpandedKeys] = useState<Key[]>(
    props.avSubtree ? drawingArea.av.expandedKeys : drawingArea.film.expandedKeys,
  );
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedKeys(props.avSubtree ? drawingArea.av.selectedKeys : drawingArea.film.selectedKeys);
    setExpandedKeys(props.avSubtree ? drawingArea.av.expandedKeys : drawingArea.film.expandedKeys);
  }, [drawingArea, props.avSubtree]);

  const handleMenuItemClick = (keys: Key[]) => {
    if (keys[0] !== undefined) {
      setSelectedKeys(expandedKeys);
      setExpandedKeys(keys);
      dispatch({
        payload: { selectedKeys: keys, expandedKeys: keys },
        type: props.avSubtree ? DrawingAreaTypes.UpdateAVKeys : DrawingAreaTypes.UpdateFilmKeys,
      });
      if (props.isUnderBreakpoint) props.setShowParent(false);
      navigate(`${paths.categoryBase}${keys[0]}`);
    }
  };

  const handleMenuExpand = (keys: Key[]) => {
    setExpandedKeys(keys);
    dispatch({
      payload: {
        selectedKeys: selectedKeys,
        expandedKeys: keys,
      },
      type: props.avSubtree ? DrawingAreaTypes.UpdateAVKeys : DrawingAreaTypes.UpdateFilmKeys,
    });
  };

  return props.showSelf ? (
    <StyledTree
      data={props.dataNodes}
      icon={<DownOutlined />}
      onSelect={(keys) => handleMenuItemClick(keys)}
      onExpand={(keys) => handleMenuExpand(keys)}
      selectedKeys={selectedKeys}
      expandedKeys={expandedKeys}
    />
  ) : null;
};
