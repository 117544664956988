import { AppConfig } from '../config';

export interface IGeneralDocument {
  id: number;
  documentLink: string;
  name: string;
  fileType: string;
}

export interface IGeneralDocumentsList {
  documents: IGeneralDocument[];
}

export const getDocumentLink = (documentName: string, documentsList: IGeneralDocumentsList) => {
  let filePath: IGeneralDocument | undefined = documentsList.documents.find(
    (document: IGeneralDocument) => document.name === documentName,
  );
  return `${AppConfig.GENERAL_DOCUMENT_BLOB}${filePath?.documentLink}`;
};

export const documentsObject = {CLIENT_APPLICATION_FORM: 'Client Application Form', RENTAL_TERMS_SWE : 'Rental Terms Swedish' }