import { Col, Row } from 'antd';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import { AdminContainer } from '../Components/AdminContainer/AdminContainer';
import { AudioVisualCarousel } from './AudioVisualCarousel';
import { FilmEquipmentCarousel } from './FilmEquipmentCarousel';
import classes from './ImageCarousel.module.scss';
export interface ICarouselImageItem {
  id: number;
  image: string;
  link: string;
  itemIndex: number;
}

export const ImageCarousel = () => {
  return (
    <AdminContainer>
      <PageHeader title="Image Carousels" />
      <Row className={classes.root}>
        <Col span={12} className={classes.filmContainer}>
          <FilmEquipmentCarousel />
        </Col>
        <Col span={12} className={classes.avContainer}>
          <AudioVisualCarousel />
        </Col>
      </Row>
    </AdminContainer>
  );
};
