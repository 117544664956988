import { GlobalOutlined, OrderedListOutlined, ShopOutlined, TagOutlined } from '@ant-design/icons/lib/icons';
import { Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CurrentWidth from '../CurrentWidth/CurrentWidth';
import PageInfo from '../PageInfo/PageInfo';
import { paths } from '../../routes/paths';
import { ViewCarousel } from '@mui/icons-material';

const SideMenuAdmin = () => {
  let page = PageInfo().pathname;
  let currentWidth = CurrentWidth();
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    window.innerWidth < 1200 ? setCollapsed(true) : setCollapsed(false);
  }, []);

  return (
    <Sider
      width={300}
      className="site-layout-background"
      collapsible
      collapsed={currentWidth < 1200 ? true : false || collapsed}
      theme="light"
      onCollapse={toggle}
    >
      <Menu
        mode="inline"
        style={{ height: '100%', borderRight: 0 }}
        selectedKeys={[page]}
        inlineCollapsed={currentWidth < 1200 ? true : false || collapsed}
        theme="light"
      >
        <Menu.Item icon={<GlobalOutlined />} key={paths.adminProductsOverview}>
          <Link to={{ pathname: paths.adminProductsOverview }}>Products</Link>
        </Menu.Item>
        <SubMenu icon={<ShopOutlined />} title="Product pages">
          <Menu.Item key={paths.adminFrontPageProducts}>
            <Link to={{ pathname: paths.adminFrontPageProducts }}>Front Page Products</Link>
          </Menu.Item>
          <Menu.Item key={paths.adminFilmEquipmentProducts}>
            <Link to={{ pathname: paths.adminFilmEquipmentProducts }}>Film Equipment Products</Link>
          </Menu.Item>
          <Menu.Item key={paths.adminAudiovisualProducts}>
            <Link to={{ pathname: paths.adminAudiovisualProducts }}>Audiovisual Products</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item icon={<TagOutlined />} key={paths.adminCategoriesBase}>
          <Link to={{ pathname: paths.adminCategoriesBase }}>Categories</Link>
        </Menu.Item>
        <Menu.Item icon={<ViewCarousel />} key={paths.adminImageCarousel}>
          <Link to={{ pathname: paths.adminImageCarousel }}>Image Carousels</Link>
        </Menu.Item>
        <Menu.Item icon={<OrderedListOutlined />} key={paths.adminAttributes}>
          <Link to={{ pathname: paths.adminAttributes }}>Attribute</Link>
        </Menu.Item>
        <Menu.Item icon={<OrderedListOutlined />} key={paths.adminFaq}>
          <Link to={{ pathname: paths.adminFaq }}>FAQ</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideMenuAdmin;
