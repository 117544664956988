import { BlobProvider, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { CreatePDFComponent } from './CreatePDFComponent';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classes from './CreatePDFLink.module.scss';
import moment from 'moment';
import { BrowserView, MobileView } from 'react-device-detect';

export const CreatePDFLink = () => {
  const cartData = localStorage.getItem('cartData');
  const tempData = localStorage.getItem('orderFormData');

  const todaysDate = moment().format('DD-MM-YYYY-HH:mm:ss');
  const fileName = `hyrmax-order-${todaysDate}.pdf`;

  const pdfDocument = (
    <CreatePDFComponent pdfData={tempData ? JSON.parse(tempData) : []} cart={cartData ? JSON.parse(cartData) : []} />
  );

  return (
    <div className={classes.root}>
      <BlobProvider document={pdfDocument}>
        {({ blob, url, loading, error }) => {
          return loading ? (
            <Spin
              className={classes.spinner}
              indicator={<LoadingOutlined style={{ fontSize: '40px' }} />}
              tip={'Generating PDF...'}
            />
          ) : null;
        }}
      </BlobProvider>
      {window.innerWidth > 844 ? (
        <BrowserView style={{ width: '100%', height: '100%' }}>
          <PDFViewer height={'100%'} width={'100%'}>
            {pdfDocument}
          </PDFViewer>
        </BrowserView>
      ) : (
        <MobileView className={classes.downloadContainer}>
          <h3>Click button to download PDF</h3>
          <Button type="primary">
            <PDFDownloadLink document={pdfDocument} fileName={fileName}>
              {({ blob, url, loading, error }) => (loading ? '' : 'Download PDF')}
            </PDFDownloadLink>
          </Button>
        </MobileView>
      )}
    </div>
  );
};
