import { headers } from '../../../api';
import { onLogout } from '../../../Components/Header/Header';
import { AppConfig } from '../../../config';
import fetchSendError from '../../../Utils/fetchSendError';
import { INewFaq } from './AddFaq/AddFaq';
import { IFaq } from './FaqPage';

export interface IConfirmRemove {
  name: string;
  id: number;
}

export const updateFaqList = (afterId: number, selectedId: number) => {
  const description = 'update the FAQ list';
  fetch(`${AppConfig.API_URL}faq-move?id=${selectedId}&afterId=${afterId}`, {
    method: 'PUT',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const removeFaq = (id: number) => {
  const description = 'delete a FAQ';
  fetch(`${AppConfig.API_URL}faqs/${id}`, {
    method: 'DELETE',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const createNewFaq = (newFaq: INewFaq, refreshFunction: () => void) => {
  let fetchURL = `${AppConfig.API_URL}faqs/?Question=${newFaq.question}&Answer=${newFaq.answer}&Category=${newFaq.category}`;
  const description = 'create a new FAQ';
  fetch(fetchURL, {
    method: 'POST',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      } else return res.json();
    })
    .then((data) => refreshFunction())
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const editFaq = (faq: IFaq, refreshFunction: () => void) => {
  let fetchURL = `${AppConfig.API_URL}faqs/${faq.id}?Question=${faq.question}&Answer=${faq.answer}&Category=${faq.category}`;
  const description = 'edit the FAQ';
  fetch(fetchURL, {
    method: 'PUT',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      } else return res.json();
    })
    .then((data) => refreshFunction())
    .catch((error) => {
      fetchSendError(`trying to ${description}`, error);
    });
};
