import { Select, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ConnectionError } from '../../Components/ConnectionError/ConnectionError';
import { ProductList } from '../../Components/ProductList/ProductList';
import { IFrontPageProduct } from '../../Interfaces/IProduct';
import classes from './Categories.module.scss';
import { LandingPage } from './LandingPages/LandingPage';

export interface ICategoryProducts {
  id: number;
  name: string;
  parentId: number;
  categoryText: string | null;
  subCategories: ICategoryProducts[];
  products: IFrontPageProduct[];
}

interface ICategoriesPage {
  data: ICategoryProducts[];
  loading: boolean;
}

export const Categories = (props: ICategoriesPage) => {
  const PAGE: string = 'category';
  let { id } = useParams<{ id: string }>();
  const idNum = id ? parseInt(id) : -1; //TODO handle id undefined
  const isLandingPage = [50, 1].includes(idNum);

  const [categoryTitle, setCategoryTitle] = useState<string>('');
  const [products, setProducts] = useState<IFrontPageProduct[]>([]);
  const [showcaseProducts, setShowcaseProducts] = useState<IFrontPageProduct[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(20);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(20);

  const getAllProducts = useCallback((list: ICategoryProducts[]) => {
    return list.reduce<IFrontPageProduct[]>((result, element) => {
      result.push(...element.products);
      if (element.subCategories) {
        result = result.concat(getAllProducts(element.subCategories));
      }
      return result;
    }, []);
  }, []);

  const getCurrentPageSettings = useCallback(() => {
    const sessionItemPage = sessionStorage.getItem('currentShopPage');
    const sessionItemSize = sessionStorage.getItem('currentShopPageSize');

    if (
      sessionItemPage !== null &&
      sessionItemSize !== null &&
      sessionItemPage.split(',')[1] === idNum.toString() &&
      sessionItemSize.split(',')[1] === idNum.toString()
    ) {
      let sessionPage = parseInt(sessionItemPage.split(',')[0]);
      let sessionSize = parseInt(sessionItemSize.split(',')[0]);

      setCurrentPage(sessionPage);
      setCurrentPageSize(sessionSize);
      setMinIndex((sessionPage - 1) * sessionSize);
      setMaxIndex(sessionPage * sessionSize);
    } else {
      setCurrentPage(1);
      setCurrentPageSize(20);
      setMinIndex(0);
      setMaxIndex(20);
    }
  }, [idNum]);

  const getProductsFromCategory = useCallback(
    (category: ICategoryProducts[]) => {
      if (!props.loading && category) {
        category.forEach((element: ICategoryProducts) => {
          if (element.id === idNum) {
            setCategoryTitle(element.name);
            let flattenProducts = getAllProducts([element]);
            flattenProducts.sort((a, b) => a.name.localeCompare(b.name));

            const uniqueIds: number[] = [];
            let filteredArray = flattenProducts.filter((product) => {
              const isDuplicate = uniqueIds.includes(product.id);
              if (!isDuplicate) {
                uniqueIds.push(product.id);
                return true;
              }
              return false;
            });

            setProducts(filteredArray);
            setShowcaseProducts(filteredArray);
            return true;
          } else if (element.subCategories.length > 0) {
            getProductsFromCategory(element.subCategories);
          }
          return false;
        });
      }
    },
    [props.loading, idNum, getAllProducts],
  );

  const sortProducts = (value: any) => {
    let array = [...products];
    if (value === 'Alphabetical, ascending') {
      array.sort((a, b) => a.name.localeCompare(b.name));
    } else if (value === 'Alphabetical, descending') {
      array.sort((a, b) => b.name.localeCompare(a.name));
    } else array = [...products];
    setShowcaseProducts(array);
  };

  useEffect(() => {
    getProductsFromCategory(props.data);
    getCurrentPageSettings();
  }, [getProductsFromCategory, props.data, id, getCurrentPageSettings]);

  return (
    <div className={classes.root} key={id}>
      {props.loading ? (
        <div className={classes.spinnerWrapper}>
          <p>Loading category...</p>
          <Spin />
        </div>
      ) : !props.data ? (
        <ConnectionError />
      ) : isLandingPage ? (
        <LandingPage title={categoryTitle} />
      ) : (
        <>
          <div className={classes.titleContainer}>
            <h1>{categoryTitle}</h1>
            <Select placeholder="Sort products" allowClear onChange={sortProducts}>
              <Select.Option value="Alphabetical, ascending">A-Z, ascending</Select.Option>
              <Select.Option value="Alphabetical, descending">A-Z, descending</Select.Option>
            </Select>
          </div>
          <ProductList
            products={showcaseProducts}
            loading={props.loading}
            pageId={idNum.toString()}
            page={PAGE}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPage={setCurrentPage}
            setCurrentPageSize={setCurrentPageSize}
            minIndex={minIndex}
            maxIndex={maxIndex}
            setMinIndex={setMinIndex}
            setMaxIndex={setMaxIndex}
          />
        </>
      )}
    </div>
  );
};
