import Header from '../../../../Components/Header/Header';
import SideMenuAdmin from '../../../../Components/SideMenuAdmin/SideMenuAdmin';

export const AdminContainer = (props: any) => {
  return (
    <>
      <Header />
      <div className="content">
        <div className="left-side-menu">
          <SideMenuAdmin />
        </div>
        <main className="page">{props.children}</main>
      </div>
    </>
  );
};
