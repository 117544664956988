import { useState } from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { useGet } from 'restful-react';
import {
  updateAttributeList,
  createNewAttribute,
  editAttribute,
  removeAttribute,
  IConfirmRemove,
} from './AttributesFunctions';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import AttributesListItem from './AttributesListItem/AttributesListItem';
import { AddAttribute, INewAttribute } from './AddAttribute/AddAttribute';
import { ConfirmRemoveModal } from '../../../Components/ConfirmRemoveModal/ConfirmRemoveModal';
import { AdminContainer } from '../Components/AdminContainer/AdminContainer';
import { LoadingState } from '../../../Components/LoadingState/LoadingState';
import { ConnectionError } from '../../Shop/Components/ConnectionError/ConnectionError';

export interface IAttribute {
  name: string;
  id: number;
  unit: string;
  value?: string;
}

const AttributesPage = () => {
  const [currentRow, setCurrentRow] = useState<number | null>(null);
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const [confirmRemoveData, setConfirmRemoveData] = useState<IConfirmRemove | null>(null);
  const [attributes, setAttributes] = useState<IAttribute[]>([]);

  const { loading, refetch } = useGet({
    path: '/attributes',
    resolve: (data) => {
      setAttributes(data.attributes);
    },
  });

  const currentId = (data: number, index: number) => {
    setSelectedRowId(data);
    setCurrentRow(index);
  };

  const handleRLDDChange = (newOrderdArray: Array<IAttribute>) => {
    setAttributes(newOrderdArray);
    let newRowPosition: any;

    newOrderdArray.forEach((item, index = 0) => {
      if (item.id === selectedRowId) {
        newRowPosition = index;
      }
    });

    if (currentRow !== null && currentRow >= 0) {
      if (selectedRowId && currentRow < newRowPosition) {
        let afterId = attributes[newRowPosition].id;
        updateAttributeList(afterId, selectedRowId);
      }
      if (selectedRowId && currentRow > newRowPosition) {
        let afterId;
        if (newRowPosition === 0) {
          afterId = attributes[newRowPosition].id;
        } else {
          afterId = attributes[newRowPosition - 1].id;
        }
        updateAttributeList(afterId, selectedRowId);
      }
    }
  };

  const confirmRemove = () => {
    if (confirmRemoveData) {
      removeAttribute(confirmRemoveData.id);
      if (attributes) {
        const updatedData: IAttribute[] = attributes.filter((x: IAttribute) => x.id !== confirmRemoveData?.id);
        setAttributes(updatedData);
      }
    }
    setConfirmRemoveData(null);
  };

  const handleAddNewAttribute = (attribute: INewAttribute) => {
    createNewAttribute(attribute, refetch);
  };

  return (
    <AdminContainer>
      <PageHeader title="Attributes" />
      <AddAttribute onCreateAttribute={(attribute: INewAttribute) => handleAddNewAttribute(attribute)} />
      {loading ? (
        <LoadingState text="Loading Attributes..." />
      ) : attributes && attributes.length > 0 ? (
        <RLDD
          items={attributes ?? []}
          itemRenderer={(item: IAttribute, index: number) => {
            return (
              <AttributesListItem
                item={item}
                currentIdAndRow={currentId}
                itemId={item.id}
                itemIndex={index}
                onRemove={(id, name) => setConfirmRemoveData({ id, name })}
                onEditAttribute={(attribute: IAttribute) => editAttribute(attribute, refetch)}
              />
            );
          }}
          onChange={handleRLDDChange}
        />
      ) : (
        <ConnectionError />
      )}
      <ConfirmRemoveModal
        itemName={confirmRemoveData?.name ?? ''}
        visible={confirmRemoveData ? true : false}
        onConfirm={() => confirmRemove()}
        onClose={() => setConfirmRemoveData(null)}
      />
    </AdminContainer>
  );
};

export default AttributesPage;
