import { Button, Input } from 'antd';
import classes from './CartGroup.module.scss';
import componentClasses from '../../../../../styles/Components.module.scss';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
//import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';

interface ICartGroupTitle {
  title: string;
  onDelete?: () => void;
  onEditName?: (newName: string) => void;
  asideTitleCss?: string;
}

export const CartGroup: React.FC<ICartGroupTitle> = (props) => {
  const [showEdit, setShowEdit] = useState(false);
  const [newName, setNewName] = useState(props.title);

  const saveName = () => {
    setShowEdit(false);
    if (newName !== props.title) {
      props.onEditName && props.onEditName(newName);
      setNewName(props.title);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      saveName();
    }
  };

  // const cancelEditName = () => {
  //   setShowEdit(false);
  //   setNewName(props.title);
  // };

  useEffect(() => {
    setNewName(props.title);
  }, [props.title]);

  return (
    <div className={classes.root}>
      <div className={`${classes.header} ${props.asideTitleCss && props.asideTitleCss}`}>
        {props.onEditName && showEdit ? (
          <Input
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            onBlur={() => saveName()}
            autoFocus
          />
        ) : (
          <h2 className={classes.title}>{props.title}</h2>
        )}
        {props.onEditName &&
          (showEdit ? (
            <Button
              className={`${componentClasses.button} ${componentClasses.buttonSecondary} ${classes.button}`}
              onClick={saveName}
              icon={<CheckIcon />}
            ></Button>
          ) : (
            <Button
              className={`${componentClasses.button} ${componentClasses.buttonSecondary} ${classes.button}`}
              onClick={() => props.onEditName && setShowEdit(!showEdit)}
              icon={<EditIcon />}
            ></Button>
          ))}
        {props.onDelete && ( //showEdit ? (
          // <Button
          //   className={`${componentClasses.button} ${componentClasses.buttonSecondary} ${classes.button}`}
          //   onClick={cancelEditName}
          //   icon={<CloseIcon />}
          // ></Button>
          // ) : (
          <Button
            className={`${componentClasses.button} ${componentClasses.buttonSecondary} ${classes.button}`}
            onClick={() => props.onDelete && props.onDelete()}
            icon={<DeleteIcon />}
          ></Button>
        )}
      </div>
      {props.children && <ul className={classes.list}>{props.children}</ul>}
    </div>
  );
};
