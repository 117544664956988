import { Pagination } from 'antd';
import { IFrontPageProduct } from '../../Interfaces/IProduct';
import { ProductCard } from './ProductCard/ProductCard';
import classes from './ProductList.module.scss';

interface IProductList {
  products: IFrontPageProduct[];
  loading?: boolean;
  page?: string;
  pageId?: string;
  currentPage?: number;
  currentPageSize?: number;
  setCurrentPage?: (page: number) => void;
  setCurrentPageSize?: (pageSize: number) => void;
  minIndex?: number;
  maxIndex?: number;
  setMinIndex?: (index: number) => void;
  setMaxIndex?: (index: number) => void;
}

export const ProductList = (props: IProductList) => {
  const handlePageChange = (page: number, pageSize: number) => {
    props.setCurrentPage && props.setCurrentPage(page);
    pageSize && props.setCurrentPageSize && props.setCurrentPageSize(pageSize);
    sessionStorage.setItem('currentShopPage', `${page.toString()},${props.pageId},${props.page}`);
    pageSize && sessionStorage.setItem('currentShopPageSize', `${pageSize.toString()},${props.pageId},${props.page}`);
    props.setMinIndex && props.setMinIndex((page - 1) * pageSize);
    props.setMaxIndex && props.setMaxIndex(page * pageSize);
    /* Scroll to top */
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <div>
      <ul className={classes.container} aria-label="Products">
        {!props.loading &&
          props.products.map((product: IFrontPageProduct, index: number) => {
            if (index >= (props.minIndex ?? 0) && index < (props.maxIndex ?? 20)) {
              return (
                <li key={product.id} className={classes.item}>
                  <ProductCard product={product} />
                </li>
              );
            } else return null;
          })}
      </ul>
      {props.products?.length > 20 && (
        <Pagination
          defaultCurrent={1}
          defaultPageSize={props.currentPageSize}
          showSizeChanger
          current={props.currentPage}
          total={props.products && props.products.length}
          onChange={handlePageChange}
        />
      )}
    </div>
  );
};
