import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { IFrontPageProduct } from '../../Interfaces/IProduct';
import { ProductCard } from '../ProductList/ProductCard/ProductCard';
import classes from './ProductCarousel.module.scss';
import componentClasses from '../../../../styles/Components.module.scss';
import { useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../../Utils/Hooks/useWindowDimensions';
import breakPoints from '../../../../Utils/breakPoints';

interface IProductCarousel {
  products: IFrontPageProduct[];
  loading?: boolean;
  productsShown: number;
  animationTimer?: number;
  emptyText?: string;
}

export const ProductCarousel = (props: IProductCarousel) => {
  const [swipePositionStart, setSwipePositionStart] = useState(0);
  const [swipePosition, setSwipePosition] = useState(0);
  const dimensions = useWindowDimensions();
  const minIndex = 0;
  const [maxIndex, setMaxIndex] = useState(props.productsShown + 2);
  const style = {
    '--nbr-of-cards': props.productsShown,
    '--width-hidden-cards': props.productsShown + 2,
    '--gap': '0.5rem',
    '--animation-timer': `${props.animationTimer ? props.animationTimer : 350}ms`,
  } as React.CSSProperties;

  const [productCards, setProductCards] = useState(props.products);
  const [animation, setAnimation] = useState<'forward' | 'backward' | 'special' | ''>('');

  const dummyProduct: IFrontPageProduct = {
    id: -1,
    eMaxNumber: '-1',
    name: 'Placeholder',
    productNumber: '-1',
    mainProduct: false,
  };

  useEffect(() => {
    /* These need custom media queries to account for the space outside the container */
    if (dimensions.width < 450) setMaxIndex(Math.min(props.productsShown + 2, 4)); // sceen xs
    else if (dimensions.width < 840) setMaxIndex(Math.min(props.productsShown + 2, 5)); // screen sm
    else if (dimensions.width < 1060) setMaxIndex(Math.min(props.productsShown + 2, 6)); // screen md
    else if (dimensions.width < 1600) setMaxIndex(Math.min(props.productsShown + 2, 7)); // screen lg
    else setMaxIndex(props.productsShown + 2);
  }, [dimensions, props.productsShown]);

  useEffect(() => {
    if (!props.loading) {
      const copiedProducts = [...props.products];
      const last = copiedProducts.pop();
      if (last) copiedProducts.unshift(last);
      setProductCards(copiedProducts);
    }
  }, [props.loading, props.products]);

  const backwards = () => {
    setAnimation('');
    const copiedProducts = [...productCards];
    const last = copiedProducts.pop();
    if (last) copiedProducts.unshift(last);
    setProductCards(copiedProducts);
  };

  const forwards = () => {
    setAnimation('');
    const copiedProducts = [...productCards];
    const first = copiedProducts.shift();
    if (first) copiedProducts.push(first);
    setProductCards(copiedProducts);
  };

  const animatedForwards = () => {
    if (!animation) {
      /* This solves the special case of having a total of one more items than what we want to show */
      if (props.products.length === maxIndex - 1) {
        const copiedProducts = [...productCards];
        const first = copiedProducts.shift();
        if (first && copiedProducts) {
          setAnimation('special');
          setProductCards([dummyProduct, ...copiedProducts, first]);

          /* This needs to be here to make sure the new cards loads the special CSS class
             before it gets the forward one, otherwise it won't animate*/
          setTimeout(() => {
            setAnimation('forward');
          }, 1);

          setTimeout(() => {
            setProductCards([...copiedProducts, first]);
            setAnimation('');
          }, props.animationTimer);
        }
      } else {
        setAnimation('forward');
        setTimeout(forwards, props.animationTimer);
      }
    }
  };

  const animatedBackwards = () => {
    if (!animation) {
      setAnimation('backward');
      setTimeout(backwards, props.animationTimer);
    }
  };

  const addButtons = () => {
    if (props.products.length > maxIndex - 2) {
      return (
        <>
          <Button
            type="text"
            className={`${dimensions.width > breakPoints.sm && componentClasses.buttonSecondary} ${classes.button} ${
              classes.buttonBackwards
            }`}
            aria-label="Backwards"
            icon={<LeftOutlined />}
            onClick={animatedBackwards}
          />
          <Button
            type="text"
            className={`${dimensions.width > breakPoints.sm && componentClasses.buttonSecondary} ${classes.button} ${
              classes.buttonForwards
            }`}
            aria-label="Forwards"
            icon={<RightOutlined />}
            onClick={animatedForwards}
          />
        </>
      );
    }
  };

  const handleTouchStart = (touchStartEvent: React.TouchEvent<HTMLDivElement>) => {
    setSwipePositionStart(touchStartEvent.touches[0].clientX);
    setSwipePosition(touchStartEvent.touches[0].clientX);
  };

  const handleTouchMove = (touchMoveEvent: React.TouchEvent<HTMLDivElement>) => {
    setSwipePosition(touchMoveEvent.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (Math.abs(swipePosition - swipePositionStart) > 20) {
      if (swipePosition > swipePositionStart) {
        // Swipe Left
        animatedBackwards();
      } else {
        // Swipe Right
        animatedForwards();
      }
    }
  };

  return (
    <div
      className={classes.container}
      style={style}
      onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
      onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
      onTouchEnd={() => handleTouchEnd()}
    >
      {props.loading && <Spin />}
      <div className={classes.row}>
        <ul className={classes.list} aria-label="Products">
          {!props.loading &&
            productCards.map((product: IFrontPageProduct, index: number) => {
              if (index >= minIndex && index < maxIndex) {
                return (
                  <li
                    key={product.id}
                    className={`${classes.item}  ${animation === 'special' && classes.itemSpecialAnimation} ${
                      animation === 'forward' && classes.itemForward
                    }  ${(animation === 'backward' || props.products.length < maxIndex - 1) && classes.itemBackward}`}
                  >
                    <ProductCard product={product} />
                  </li>
                );
              } else return null;
            })}
        </ul>
      </div>
      {addButtons()}
      {props.products.length < 1 && (
        <div className={classes.emptyContainer}>
          <p className={classes.emptyText}>{props.emptyText || 'There are no related products to this item'}</p>
        </div>
      )}
    </div>
  );
};
