import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Route, Routes as RouterSwitch, useMatch } from 'react-router';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import { AdminContainer } from '../Components/AdminContainer/AdminContainer';
import { MainCategories } from './MainCategories/MainCategories';
import { SubCategories } from './SubCategories/SubCategories';

export interface ICategory {
  categoryText: string;
  id: number;
  isPublished: boolean;
  name: string;
  parentId: number;
  sortOrder: number;
  subCategories: ICategory[];
}

const CategoriesPage = () => {
  const location = useLocation();
  const basepath = location.pathname.substring(0, location.pathname.lastIndexOf('/') + 1);
  const parentCategoryPath = basepath.replace('/admin/categories/', '');

  const match = useMatch(`${basepath}:id`);
  const [categoryId, setCategoryId] = useState<number>();

  const categoryIdCallback = (id: number) => {
    setCategoryId(id);
  };

  useEffect(() => {
    if (categoryId) categoryIdCallback(categoryId);
  }, [categoryId]);

  return (
    <AdminContainer>
      <PageHeader title="Categories" />
      <RouterSwitch>
        <Route path={'/'} element={<MainCategories />} />
        <Route path={`${parentCategoryPath}${match?.params.id}`} element={<SubCategories />} />
      </RouterSwitch>
    </AdminContainer>
  );
};

export default CategoriesPage;
