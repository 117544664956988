import { Button, Modal, Select, message, Input, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { documentsObject } from '../../../../Utils/generalDocumentFetchers';
import classes from './FaqGenerateLink.module.scss';

export const FaqGenerateLink = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [linkText, setLinkText] = useState('');
  const [document, setDocument] = useState<string | undefined>(undefined);
  const [targetUrl, setTargetUrl] = useState<string | undefined>(undefined);
  const linkObject: { [key: string]: string } = {
    document: 'Document',
    url: 'URL',
  };
  const [radioValue, setRadioValue] = useState(linkObject.document);
  const { Option } = Select;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (radioValue === linkObject.document && document) navigator.clipboard.writeText(`[${linkText}]{${document}}`);
    else navigator.clipboard.writeText(`[${linkText}]{${targetUrl}}`);
    message.success('Link copied to clipboard');
    setDocument(undefined);
    setLinkText('');
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setDocument(undefined);
    setLinkText('');
    setIsModalVisible(false);
  };

  const createOptions = () => {
    return Object.values(documentsObject).map((value, index) => {
      return (
        <Option value={value} key={index}>
          {value}
        </Option>
      );
    });
  };

  const handleChange = (value: string) => {
    setDocument(value);
  };

  useEffect(() => {
    console.log(radioValue);
  }, [radioValue]);

  const enterLinkTarget = () => {
    switch (radioValue) {
      case linkObject.url:
        return (
          <Input
            placeholder="Enter URL"
            value={targetUrl}
            onChange={(event) => setTargetUrl(event.target.value)}
            className={classes.input}
          />
        );
      case linkObject.document:
      default:
        return (
          <Select onChange={handleChange} placeholder="Choose Document" value={document} className={classes.select}>
            {createOptions()}
          </Select>
        );
    }
  };

  return (
    <>
      <Button type="default" onClick={showModal}>
        Generate Link
      </Button>
      <Modal
        title="Generate Link"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key="ok"
            disabled={
              !(
                linkText &&
                ((radioValue === linkObject.document && document) || (radioValue === linkObject.url && targetUrl))
              )
            }
            type="primary"
            onClick={handleOk}
          >
            Copy Link
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <p>Please select what type of link you wish to generate:</p>
        <Radio.Group onChange={(e) => setRadioValue(e.target.value)} value={radioValue} className={classes.radio}>
          {Object.keys(linkObject).map((key) => {
            return (
              <Radio value={linkObject[key]} key={key}>
                {linkObject[key]}
              </Radio>
            );
          })}
        </Radio.Group>
        <div className={classes.wrapper}>
          <Input
            placeholder="Link text"
            value={linkText}
            onChange={(event) => setLinkText(event.target.value)}
            className={classes.input}
          />
          {enterLinkTarget()}
        </div>
        {linkText &&
        ((radioValue === linkObject.document && document) || (radioValue === linkObject.url && targetUrl)) ? (
          <p>
            <em>{`[${linkText}]{${radioValue === linkObject.document ? document : targetUrl}}`}</em>
          </p>
        ) : (
          <p></p>
        )}
      </Modal>
    </>
  );
};
