import classes from './LoginPage.module.scss';
import { Form, Input, Button, Checkbox, Typography, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { headers } from '../../../api';
import { onLogout } from '../../../Components/Header/Header';
import { AppConfig } from '../../../config';

interface ILoginInformation {
  name: string;
  password: string;
}

const LoginPage = () => {
  const { Title } = Typography;

  const onFinish = (values: ILoginInformation) => {
    fetch(`${AppConfig.API_URL}login`, {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      body: JSON.stringify(values),
    }).then((res) => {
      messageUiFunction(res.status);
      if (res.status === 401) {
        return onLogout();
      }
    });
  };

  // Temporär funktion för att UI ska få en bekräftelse på om login lyckades eller ej
  const messageUiFunction = (status: number) => {
    if (status === 200) {
      localStorage.setItem('authenticated', 'signed-in');
      window.location.pathname = '/admin/products-overview';
      return message.success('Login successful');
    } else if (status === 401) {
      return message.error('Wrong credentials');
    } else return message.info('Check network-tab for info');
  };

  return (
    <div className={classes.pageBodyCenter}>
      <div className={classes.cardContainer}>
        <Title>Log in</Title>
        <Title level={5}>Hyrmax - Admin</Title>
        <Form name="normal_login" initialValues={{ remember: true }} onFinish={onFinish} layout="vertical">
          <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your Email!' }]}>
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className={classes.loginFormButton}>
              Log in
            </Button>
          </Form.Item>
          <Form.Item>
            <Button href="#" disabled className={classes.loginFormButton}>
              Create an account
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
