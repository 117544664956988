export const paths = {
  admin: '/admin',
  adminCategories: '/admin/categories/*',
  adminCategoriesBase: '/admin/categories',
  adminFrontPageProducts: '/admin/front-page-products',
  adminFilmEquipmentProducts: '/admin/film-equipment-page-products',
  adminAudiovisualProducts: '/admin/audiovisual-page-products',
  adminAttributes: '/admin/attributes',
  adminFaq: '/admin/faq',
  adminProductBase: '/admin/products-overview/product/',
  adminUsers: '/admin/users',
  adminImageCarousel: '/admin/carousel-images',
  adminProductsOverview: '/admin/products-overview',
  adminCreateProduct: '/admin/products-overview/create-product/',
  adminProductId: '/admin/products-overview/product/:id',
  adminRedirect: '/admin/*',

  home: '/',
  categories: '/category/:id',
  categoryBase: '/category/',
  search: '/search',
  products: '/products/:id/productNumber/:productNumber',
  productBase: '/products/',
  cart: '/checkout',
  viewCart: '/view-cart/:carturl',
  contact: '/contact',
  favourites: '/favourites',
  faq: '/faq',
  cookieInformation: '/cookie-information',
  error: '/*',
};
