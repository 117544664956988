import { Input } from 'antd';
import Form from 'antd/lib/form';
import { useGet } from 'restful-react';

interface ITechnicalSpecificationTab {
  attributes: IAttribute[];
  onFormChange: (formValues: any) => void;
}

export interface IAttribute {
  id: number;
  name: string;
  value?: string;
  unit?: string;
}

export interface IAttributeBulk {
  attributeId: number;
  value?: string;
}

interface IAttributeData {
  attributes: IAttribute[];
}

const TechnicalSpecificationsTab = (props: ITechnicalSpecificationTab) => {
  const { data, refetch } = useGet<IAttributeData>({
    path: '/attributes',
  });

  const updateAttributes = (formValues: any) => {
    const values = Object.values(formValues);
    const updatedAttributes =
      data &&
      data.attributes.map((x: IAttribute, index: number) => {
        if (values[index] === undefined) {
          return undefined;
        } else {
          return {
            id: x.id,
            name: x.name,
            unit: x.unit,
            value: values[index] as string,
          };
        }
      });
    props.onFormChange(updatedAttributes);
    refetch();
  };

  return (
    <div className="padding-x">
      <div className="technical-specification">
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          labelAlign="left"
          onValuesChange={(_, formValues) => updateAttributes(formValues)}
        >
          {data &&
            data.attributes.map((attribute: IAttribute) => {
              const name = attribute.id + attribute.name;
              const initialValue = props.attributes.find((x: IAttribute) => x.id === attribute.id)?.value ?? '';

              return (
                <Form.Item initialValue={initialValue} key={name} label={attribute.name} name={attribute.name}>
                  {typeof attribute.value === 'number' ? (
                    <Input type="number" suffix={attribute.unit ? attribute.unit : ''} />
                  ) : (
                    <Input suffix={attribute.unit ? attribute.unit : ''} />
                  )}
                </Form.Item>
              );
            })}
        </Form>
      </div>
    </div>
  );
};

export default TechnicalSpecificationsTab;
