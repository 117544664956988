import { Container } from '../../Components/Container/Container';
import { OrderForm } from './OrderForm/OrderForm';
import { CartComponent } from './CartComponent';
import classes from './Cart.module.scss';
import { GobackButton } from '../../Components/GobackButton/GobackButton';
import { useLocation } from 'react-router-dom';

export const Cart = () => {
  const location = useLocation();
  return (
    <Container>
      <div className={classes.root}>
        <div className={classes.left}>
          <h1 className={classes.title}>
            <GobackButton state={location.state} />
            Cart
          </h1>
          <p>
            Here you can review and organise your cart in groups. You can share your cart with others as a link, or
            export as a PDF before sending your request.
          </p>
          <CartComponent />
        </div>
        <div className={classes.right}>
          <OrderForm />
        </div>
      </div>
    </Container>
  );
};
