import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useState } from 'react';
import classes from './AddAttribute.module.scss';

interface IAddAttribute {
  onCreateAttribute: (attribute: INewAttribute) => void;
}

export interface INewAttribute {
  name: string;
  unit: string;
}

export const AddAttribute = (props: IAddAttribute) => {
  const [showForm, setShowForm] = useState(false);

  const onFinishForm = async (newAttribute: INewAttribute) => {
    props.onCreateAttribute(newAttribute);
    setShowForm(false);
  };

  return (
    <div className={classes.root}>
      {!showForm ? (
        <div className="display-flex justify-right padding-y padding-x">
          <Button onClick={() => setShowForm(!showForm)} type="primary" icon={<PlusOutlined />}>
            Add New Attribute
          </Button>
        </div>
      ) : (
        <div>
          <Form layout="vertical" onFinish={onFinishForm}>
            <div className={classes.addAttributeForm}>
              <div className={classes.inputs}>
                <Form.Item
                  label="Attribute name"
                  name="name"
                  rules={[{ required: true, message: 'Please enter a name' }]}
                >
                  <Input autoFocus autoComplete="off" />
                </Form.Item>
                <Form.Item label="Attribute unit" name="unit">
                  <Input autoComplete="off" />
                </Form.Item>
              </div>
              <Form.Item>
                <Button onClick={() => setShowForm(!showForm)} type="link">
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Add
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};
