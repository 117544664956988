import { Col, Row } from 'antd';
import classes from './CookieInformation.module.scss';

export const CookieInformation = () => {
  return (
    <>
      <Row className={classes.info}>
        <Col span={20} offset={2} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }} className={classes.layout}>
          <h1>Information about cookies</h1>
          <p>
            This website uses cookies and local storage in your browser for strictly nessecary reasons for the site to
            function.
          </p>
          <h3>Cookies</h3>
          <ul>
            <li>
              Cart: For the cart function to work we save information about things like items placed in cart, creation
              date, cart name, id etc.
            </li>
            <li>
              Login: This website has an admin interface with a login and uses cookies for the authentication state.
            </li>
            <li>
              Favourite: This website has a favourite-function (the heart icon on items) and for that to work we use
              cookies to store the id of the favourited item.
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};
