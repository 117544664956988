import { SearchOutlined } from '@ant-design/icons/lib/icons';
import { Input } from 'antd';
import classes from './PageHeader.module.scss';

interface IPageHeader {
  title: string;
  onSearch?: (event: string) => void;
  search?: boolean;
  searchText?: string;
}

const PageHeader = (props: IPageHeader) => {
  return (
    <div className={classes.root}>
      <h2>{props.title}</h2>
      {props.search && (
        <div className={classes.searchInput}>
          <Input
            allowClear
            suffix={<SearchOutlined />}
            placeholder={`Search for ${props.title.toLowerCase()}...`}
            onChange={(event) => props.onSearch && props.onSearch(event.target.value)}
            defaultValue={props.searchText}
          />
        </div>
      )}
    </div>
  );
};

export default PageHeader;
