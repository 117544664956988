import { useCallback, useEffect, useState } from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { useNavigate, useLocation } from 'react-router';
import { headers } from '../../../../api';
import { ConfirmRemoveModal } from '../../../../Components/ConfirmRemoveModal/ConfirmRemoveModal';
import { onLogout } from '../../../../Components/Header/Header';
import PageSubHeader from '../../../../Components/PageSubHeader/SubPageHeader';
import { LoadingState } from '../../../../Components/LoadingState/LoadingState';
import { AppConfig } from '../../../../config';
import {
  addSubCategory,
  updateList,
  IConfirmRemove,
  publish,
  removeCategory,
  saveNewName,
} from '../CategoriesFunctions';
import { AddCategory } from '../Components/AddCategory/AddCategory';
import { CategoryListItem } from '../Components/CategoryListItem/CategoryListItem';
import { ConfirmPublishModal } from '../Components/ConfirmPublishModal/ConfirmPublishModal';
import { ConnectionError } from '../../../Shop/Components/ConnectionError/ConnectionError';
export interface ICategory {
  id: number;
  name: string;
  parentId: number;
  categoryText: string;
  isPublished: boolean;
  sortOrder: number;
  subCategories: ICategory[];
}

export const SubCategories = () => {
  const [categoryData, setCategoryData] = useState<ICategory>();
  const [confirmRemoveData, setConfirmRemoveData] = useState<IConfirmRemove | null>(null);
  const [confirmPublish, setConfirmPublish] = useState<ICategory | null>(null);
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const [currentRow, setCurrentRow] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Get category id
  function getCategoryId() {
    const route = window.location.pathname;
    return parseInt(route.substring(route.lastIndexOf('/') + 1, route.length));
  }

  // Fetch data
  const getCategoryData = useCallback(() => {
    const categoryId = getCategoryId();

    fetch(AppConfig.API_URL + 'categories/' + categoryId, {
      headers: headers,
      credentials: 'include',
    })
      .then((res) => {
        if (res.status === 401) {
          return onLogout();
        }
        return res.json();
      })
      .then((data) => {
        setCategoryData(data.category[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  // Add sub category and refresh data
  const addSubCategoryToList = (title: string, parentId: number) => {
    addSubCategory(title, parentId);

    // TODO: Remove this when useGet is implemented
    setTimeout(function () {
      getCategoryData();
    }, 500);
  };

  // Confirm removing a category with modal
  const confirmRemove = () => {
    removeCategory(confirmRemoveData?.id);
    if (categoryData) {
      const updatedData: ICategory = {
        ...categoryData,
        subCategories: categoryData.subCategories.filter((x: ICategory) => x.id !== confirmRemoveData?.id),
      };
      setCategoryData(updatedData);
    }
    setConfirmRemoveData(null);
  };

  // Change route to go to next category
  const goToCategory = (id: number) => {
    navigate(`${location.pathname}/${id}`);
  };

  // Edit name
  const editName = (name: string) => {
    if (categoryData) {
      saveNewName(name, getCategoryId(), categoryData.parentId);
      setCategoryData({ ...categoryData, name: name });
    }
  };

  // Publish category (confirm in modal)
  const publishCategory = () => {
    if (confirmPublish) {
      publish(confirmPublish);
    }
    if (categoryData) {
      setCategoryData({
        ...categoryData,
        subCategories: categoryData.subCategories.map((x: ICategory) => {
          if (x.id === confirmPublish?.id) {
            return {
              ...x,
              isPublished: !confirmPublish.isPublished,
            };
          } else {
            return { ...x };
          }
        }),
      });
    }
    setConfirmPublish(null);
  };

  // Set row index and category id of the grabbed item.
  const currentId = (data: number, index: number) => {
    setSelectedRowId(data);
    setCurrentRow(index);
  };

  // Reorganize the drag-drop list and post changes directly
  const handleRLDDChange = (newOrderdArray: Array<ICategory>) => {
    let newRowPosition: any;

    newOrderdArray.forEach((item, index = 0) => {
      if (item.id === selectedRowId) {
        newRowPosition = index;
      }
    });

    if (categoryData && currentRow !== null && currentRow >= 0) {
      if (categoryData && selectedRowId && currentRow < newRowPosition) {
        let afterId = categoryData?.subCategories[newRowPosition].id;
        updateList(afterId, selectedRowId);
      }
      if (categoryData && selectedRowId && currentRow > newRowPosition) {
        let afterId;
        if (newRowPosition === 0) {
          afterId = categoryData?.subCategories[newRowPosition].id;
        } else {
          afterId = categoryData?.subCategories[newRowPosition - 1].id;
        }
        updateList(afterId, selectedRowId);
      }
      const updatedData: ICategory = {
        ...categoryData,
        subCategories: newOrderdArray,
      };
      setCategoryData(updatedData);
    }
  };

  // Fetch new data everytime the route changes
  useEffect(() => {
    getCategoryData();
  }, [getCategoryData, location.pathname]);

  return (
    <>
      {loading && <LoadingState text="Loading Subcategories..." />}
      {categoryData ? (
        <>
          <PageSubHeader title={categoryData.name} editName={(name: string) => editName(name)} />
          <AddCategory
            buttonText="Add category"
            onEditName={(title: string) => addSubCategoryToList(title, categoryData.id)}
          />
          <div>
            {!loading && categoryData && categoryData.subCategories.length && (
              <RLDD
                items={categoryData.subCategories}
                onChange={handleRLDDChange}
                itemRenderer={(categoryDataItem: ICategory, index: number) => {
                  return (
                    <CategoryListItem
                      item={categoryDataItem}
                      itemId={categoryDataItem.id}
                      itemIndex={index}
                      currentIdAndRow={currentId}
                      onRemove={(id, name) => setConfirmRemoveData({ id, name })}
                      onRouteTo={(id) => goToCategory(id)}
                      onPublish={(item) => setConfirmPublish(item)}
                    />
                  );
                }}
              />
            )}
          </div>
        </>
      ) : !loading ? (
        <ConnectionError />
      ) : null}
      <ConfirmRemoveModal
        itemName={confirmRemoveData?.name ?? ''}
        visible={confirmRemoveData ? true : false}
        onConfirm={() => confirmRemove()}
        onClose={() => setConfirmRemoveData(null)}
      />
      <ConfirmPublishModal
        visible={confirmPublish ? true : false}
        name={confirmPublish?.name}
        onConfirm={() => publishCategory()}
        onCancel={() => setConfirmPublish(null)}
        unpublish={confirmPublish?.isPublished}
      />
    </>
  );
};
