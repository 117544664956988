import { Button, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AppConfig } from '../../../../../config';
import { IFrontPageProduct } from '../../../Interfaces/IProduct';
import { IProductInCart } from '../../../Pages/Cart/CartComponent';
import { FavouriteButton } from '../../FavouriteButton/FavouriteButton';
import { QuickAddButton } from './QuickAddButton/QuickAddButton';
import classes from './ProductCard.module.scss';

interface IProductCard {
  product: IFrontPageProduct;
}

export const ProductCard = (props: IProductCard) => {
  const { Text } = Typography;
  const navigate = useNavigate();

  const passToAddToCart: IProductInCart = {
    productName: props.product.name,
    id: props.product.id,
    eMaxNumber: props.product.eMaxNumber,
    productNumber: props.product.productNumber,
    imagePath: props.product.imagePath,
    uniqueProductId: Math.floor(Math.random() * 1000),
    isMainProduct: props.product.mainProduct,
    numberOfItems: 1,
  };

  return (
    <>
      <div className={classes.linkWrapper}>
        <FavouriteButton productId={props.product.id} />
        <Link
          className={classes.link}
          to={`/products/${props.product.id}/productNumber/${props.product.productNumber}`}
        >
          {props.product.imagePath ? (
            <img
              src={AppConfig.IMAGE_BLOB + props.product.imagePath}
              alt={props.product.name}
              className={classes.image}
            />
          ) : (
            <div className={`${classes.noImage} ${classes.image}`} />
          )}
          <Text className={classes.title}>{props.product.name}</Text>
        </Link>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.moreInfoButton}
          onClick={() => navigate(`/products/${props.product.id}/productNumber/${props.product.productNumber}`)}
        >
          Info
        </Button>
        <QuickAddButton product={passToAddToCart} isInCard />
      </div>
    </>
  );
};
