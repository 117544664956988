import { headers } from '../../../api';
import { onLogout } from '../../../Components/Header/Header';
import { AppConfig } from '../../../config';
import fetchSendError from '../../../Utils/fetchSendError';
import { INewAttribute } from './AddAttribute/AddAttribute';
import { IAttribute } from './AttributesPage';

export interface IConfirmRemove {
  name: string;
  id: number;
}

export const updateAttributeList = (afterId: number, selectedId: number) => {
  const description = 'update the attribute list';
  fetch(`${AppConfig.API_URL}attribute-move?id=${selectedId}&afterId=${afterId}`, {
    method: 'PUT',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const removeAttribute = (id: number) => {
  const description = 'removing an attribute ';
  fetch(`${AppConfig.API_URL}attributes/${id}`, {
    method: 'DELETE',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      }
      res.json();
    })
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const createNewAttribute = (newAttribute: INewAttribute, refreshFunction: () => void) => {
  const description = 'creating a new attribute ';
  let fetchURL = newAttribute.unit
    ? `${AppConfig.API_URL}attributes/?Name=${newAttribute.name}&Unit=${newAttribute.unit}`
    : `${AppConfig.API_URL}attributes/?Name=${newAttribute.name}`;

  fetch(fetchURL, {
    method: 'POST',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      } else return res.json();
    })
    .then((data) => refreshFunction())
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};

export const editAttribute = (attribute: IAttribute, refreshFunction: () => void) => {
  const description = 'editing an attribute ';
  let fetchURL = attribute.unit
    ? `${AppConfig.API_URL}attributes/${attribute.id}?Name=${attribute.name}&Unit=${attribute.unit}`
    : `${AppConfig.API_URL}attributes/${attribute.id}?Name=${attribute.name}`;

  fetch(fetchURL, {
    method: 'PUT',
    headers: headers,
    credentials: 'include',
  })
    .then((res) => {
      if (res.status >= 400) {
        fetchSendError(`trying to ${description}`, `${res.status}: ${res.statusText}`);
      }
      if (res.status === 401) {
        return onLogout();
      } else return res.json();
    })
    .then((data) => refreshFunction())
    .catch((error) => fetchSendError(`trying to ${description}`, error));
};
