import { AutoComplete, Input } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useGet } from 'restful-react';
import { IProduct } from '../../../Admin/Products/EditProduct/EditProduct';
import classes from './SearchBar.module.scss';

export const SearchBar = () => {
  const navigate = useNavigate();

  const { data: products } = useGet({
    path: 'publishedProducts',
    resolve: (data) => data.products,
  });

  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [searchString, setSearchString] = useState('');

  const onSearch = (searchText: string) => {
    setSearchString('');
    setOptions([]);
    searchText.trim() && navigate(`/search?${searchText}`);
  };

  const onAutoComplete = (searchText: string) => {
    setSearchString(searchText);
    if (searchText.length > 2) {
      setOptions(
        products
          .filter((product: IProduct) => {
            return (
              product.name.toLowerCase().includes(searchText.toLowerCase()) ||
              product.productNumber.toLowerCase().includes(searchText.toLowerCase()) ||
              product.searchTag?.some((searchTagItem: any) => {
                return searchTagItem.tag.toLowerCase().includes(searchText.toLowerCase());
              })
            );
          })
          .map((product: IProduct) => ({ value: product.id, label: product.name })),
      );
    } else {
      setOptions([]);
    }
  };

  const onDropdownSelect = (value: string) => {
    setSearchString('');
    setOptions([]);
    navigate(`/products/${value}/productNumber/undefined`);
  };

  return (
    <AutoComplete
      dropdownMatchSelectWidth={true}
      options={options}
      onSelect={onDropdownSelect}
      className={classes.wrapper}
      dropdownClassName={`${classes.dropdownGray} ${options.length > 8 && classes.showDropdownScroll}`}
      value={searchString}
      autoFocus
    >
      <Input.Search
        allowClear
        placeholder={`Search products...`}
        onSearch={(searchText) => onSearch(searchText)}
        onChange={(event) => onAutoComplete(event.target.value)}
        className={classes.searchBar}
      />
    </AutoComplete>
  );
};
