import { Button } from 'antd';
import { ReactNode } from 'react';
import classes from './IconButton.module.scss';

interface IIconButton {
  onClick?: () => void;
  icon: ReactNode;
  children?: JSX.Element | string;
  href?: string;
  colour?: 'white' | 'black' | 'primary';
  className?: string;
  disabled?: boolean;
  counter?: number | false;
}

export const IconButton = (props: IIconButton) => {
  const addColourClasses = () => {
    switch (props.colour) {
      case 'black':
        return classes.black;
      case 'primary':
        return classes.primary;
      case 'white':
      default:
        return classes.white;
    }
  };

  const showCounter = () => {
    if (props.counter && props.counter > 0) return <div className={classes.counter}>{props.counter}</div>;
  };

  return (
    <Button
      href={props.href}
      target="_blank"
      className={`${classes.root} ${addColourClasses()} ${props.className ? props.className : ''}`}
      type="text"
      icon={props.icon}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
      {showCounter()}
    </Button>
  );
};
