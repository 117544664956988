import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { PDFEquipmentListGroup } from './PDFEquipmentListGroup';

export const PDFEquipmentList = (props: any) => {
  const styles = StyleSheet.create({
    root: {
      padding: '16px 24px 16px 24px',
    },
    title: {
      fontSize: '15pt',
      marginBottom: '10pt',
      fontWeight: 'bold',
    },
  });
  return (
    <View style={styles.root}>
      <Text style={styles.title}>Equipment List</Text>
      {props.cart.cartGroups.map((cartGroup: any) =>
        cartGroup.products.length > 0 ? <PDFEquipmentListGroup key={cartGroup.cartId} cartGroup={cartGroup} /> : <></>,
      )}
    </View>
  );
};
