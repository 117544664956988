import { ICart, ICartGroup, IProductInCart} from '../CartComponent';

export const getCartWithUpdatedProductValueForGrouped = (
  cartGroupId: number,
  cart: ICart,
  currentValue: number,
  uniqueProductId: number,
) => {
  // Updating the value of the changed product by maping a new product array
  const updatedProducts = (products: IProductInCart[], groupId: number) => {
    if (groupId === cartGroupId) {
      const updatedProducts: IProductInCart[] = products.map((product) => {
        return {
          ...product,
          numberOfItems: uniqueProductId === product.uniqueProductId ? currentValue : product.numberOfItems,
        };
      });
      return updatedProducts;
    } else {
      // If the passed group id do not match with the group id, return the products unchanged
      return products;
    }
  };

  // Creating new cart groups with correct value
  let cartGroups: ICartGroup[] = cart.cartGroups.map((x) => {
    return {
      id: x.id,
      cartId: x.cartId,
      cartGroupName: x.cartGroupName,
      products: updatedProducts(x.products, x.id),
    };
  });

  const cartWithUpdatedProductValue: ICart = {
    ...cart,
    cartGroups: cartGroups,
  };

  return cartWithUpdatedProductValue;
};

export const getCartWithUpdatedProductValueForUngrouped = (
  cart: ICart,
  currentValue: number,
  uniqueProductId: number,
) => {
  const updatedValuesOfProducts: IProductInCart[] = cart.ungroupedProducts.map((product) => {
    return {
      ...product,
      numberOfItems: uniqueProductId === product.uniqueProductId ? currentValue : product.numberOfItems,
    };
  });

  const cartWithUpdatedProductValue: ICart = {
    ...cart,
    ungroupedProducts: updatedValuesOfProducts,
  };

  return cartWithUpdatedProductValue;
};
