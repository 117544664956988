import { Form, Select, TreeSelect } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { useState } from 'react';
import { useGet } from 'restful-react';
import { ICategory } from '../../Pages/Admin/Products/EditProduct/Tabs/ProductTab';
import classes from './FilterTable.module.scss';
interface IFilterTable {
  setPublish: (isPublished: boolean | null, value: string) => void;
  setCategories: (categories: number[]) => void;
  setIsMain: (isMainProduct: boolean | null, value: string) => void;
  defaultPublish: boolean | null;
}

const FilterTable = (props: IFilterTable) => {
  const { Option } = Select;
  const [categoriesList, setCategoriesList] = useState<ICategory[]>([]);

  useGet({
    path: 'categories',
    resolve: (data) => {
      setCategoriesList(data.categories);
    },
  });

  const onPublishedStatusChange = (value: string) => {
    value === 'is-published-true' && props.setPublish(true, value);
    value === 'is-published-false' && props.setPublish(false, value);
    value === undefined && props.setPublish(null, value);
  };

  const onProductTypeChange = (value: string) => {
    value === 'mainProduct-true' && props.setIsMain(true, value);
    value === 'mainProduct-false' && props.setIsMain(false, value);
    value === undefined && props.setIsMain(null, value);
  };

  const onCategoriesListChange = (values: number[]) => {
    props.setCategories(values);
  };

  function getDataNode(c: ICategory[]): DataNode[] {
    const dataNode = c?.map((x: ICategory) => {
      return {
        key: x.id,
        value: x.id,
        title: x.name,
        children: getDataNode(x.subCategories ?? []),
      };
    });
    return dataNode;
  }

  const setDefaultPublishedFilter = () => {
    const x = sessionStorage.getItem('publishedFilter');
    if (x === 'is-published-true' || x === 'is-published-false') {
      return x;
    } else return undefined;
  };

  const setDefaultMainProductFilter = () => {
    const y = sessionStorage.getItem('mainProductFilter');
    if (y === 'mainProduct-true' || y === 'mainProduct-false') {
      return y;
    } else return undefined;
  };

  const setDefaultCategoriesFilter = () => {
    const z = sessionStorage.getItem('categoriesFilter');
    if (z) {
      return JSON.parse(z);
    } else return undefined;
  };

  return (
    <div className={classes.root}>
      <Form layout="vertical">
        <Form.Item label="Product Type">
          <Select
            allowClear
            placeholder="Please select"
            onChange={onProductTypeChange}
            defaultValue={setDefaultMainProductFilter()}
          >
            <Option value="mainProduct-true" key="main-product">
              Main Product
            </Option>
            <Option value="mainProduct-false" key="addon-product">
              Add-On Product
            </Option>
          </Select>
        </Form.Item>
        <Form.Item label="Category">
          <TreeSelect
            allowClear
            placeholder="Please select"
            onChange={onCategoriesListChange}
            treeData={getDataNode(categoriesList)}
            multiple
            defaultValue={setDefaultCategoriesFilter()}
          />
        </Form.Item>
        <Form.Item label="Published Status">
          <Select
            allowClear
            placeholder="Please select"
            onChange={onPublishedStatusChange}
            defaultValue={setDefaultPublishedFilter()}
          >
            <Option value="is-published-true" key="is-published-true">
              Published
            </Option>
            <Option value="is-published-false" key="is-published-false">
              Not Published
            </Option>
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FilterTable;
