import { useGet } from 'restful-react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { useState } from 'react';
import { IListProduct } from '../Products/EditProduct/Tabs/ProductLists/Interfaces/IProductList';
import DraggableProductItem from '../../../Components/DraggableProductItem/DraggableProductItem';
import { AddProductsModal } from '../../../Components/AddProductsModal/AddProductsModal';
import { ConfirmRemoveModal } from '../../../Components/ConfirmRemoveModal/ConfirmRemoveModal';
import { ProductListWrapper } from '../Products/EditProduct/Tabs/ProductLists/Components/ProductListWrapper/ProductListWrapper';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import * as utils from '../../../Utils/SelectableProductsFunctions';
import { Button } from 'antd';
import classes from './FrontPageProducts.module.scss';
import { AdminContainer } from '../Components/AdminContainer/AdminContainer';
import { LoadingState } from '../../../Components/LoadingState/LoadingState';
import { ConnectionError } from '../../Shop/Components/ConnectionError/ConnectionError';

const FrontPageProducts = (props: any) => {
  const FRONT_PAGE = 'FrontPage';
  const { loading, refetch } = useGet({
    path: 'frontPageProduct?page=FrontPage',
    resolve: (data) => {
      setFrontPageProducts(data.products);
      setInitialProducts(data.products);
    },
  });

  const clearState: IListProduct = {
    name: '',
    id: 0,
  };

  const [confirmRemoveItem, setConfirmRemoveItem] = useState<IListProduct>(clearState);
  const [addProductsModalOpen, setAddProductsModalOpen] = useState(false);
  const [frontPageProducts, setFrontPageProducts] = useState<IListProduct[]>([]);
  const [initialProducts, setInitialProducts] = useState<IListProduct[]>([]);
  const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false);

  // Removes the confirmed item from the list display
  const removeItem = (id: number) => {
    removeFrontPageProduct(id);
    setConfirmRemoveItem(clearState);
    setHasMadeChanges(true);
  };

  const onUpdateItems = (items: IListProduct[]) => {
    items.forEach((element) => {
      setFrontPageProducts([...frontPageProducts, element]);
    });
    setAddProductsModalOpen(false);
    setHasMadeChanges(true);
  };

  // Handle the Drag and drop change, set the products-array to new order
  const handleRLDDChange = (newOrderdArray: IListProduct[]) => {
    setFrontPageProducts(newOrderdArray);
    setHasMadeChanges(true);
  };

  // Filter products-state to remove the confirmed item
  const removeFrontPageProduct = (id: number) => {
    let newArray = frontPageProducts?.filter(function (item: IListProduct) {
      return item.id !== id;
    });
    setFrontPageProducts(newArray);
  };

  const addFrontPageProduct = (product: IListProduct) => {
    if (!frontPageProducts.find((element) => element.productId === product.id)) {
      setFrontPageProducts([...frontPageProducts, product]);
      setHasMadeChanges(true);
    } else return;
  };

  const saveOrder = () => {
    utils.updateProducts(
      FRONT_PAGE,
      frontPageProducts.map((p) => p.productId ?? p.id),
      () => {
        refetch();
        setHasMadeChanges(false);
      },
    );
  };

  return (
    <AdminContainer>
      <PageHeader title="Front Page Products" />
      <div className={classes.root}>
        <AddProductsModal
          visible={addProductsModalOpen}
          onClose={() => setAddProductsModalOpen(false)}
          onConfirm={(items: IListProduct[]) => onUpdateItems(items)}
          selectedProducts={props.products ?? []}
        />
        <ProductListWrapper
          onAddProducts={() => setAddProductsModalOpen(true)}
          addToList={(items: IListProduct) => addFrontPageProduct(items)}
        >
          {loading ? (
            <LoadingState text="Loading Front Page Products..." />
          ) : frontPageProducts && frontPageProducts.length > 0 ? (
            <RLDD
              items={frontPageProducts}
              onChange={handleRLDDChange}
              itemRenderer={(item: IListProduct, index: number) => {
                return (
                  <DraggableProductItem
                    item={item}
                    itemId={item.id}
                    itemIndex={index}
                    onRemove={(id, name) => setConfirmRemoveItem({ id, name })}
                  />
                );
              }}
            />
          ) : (
            <ConnectionError />
          )}
        </ProductListWrapper>
        <ConfirmRemoveModal
          visible={confirmRemoveItem.id ? true : false}
          onConfirm={() => confirmRemoveItem.id && removeItem(confirmRemoveItem.id)}
          onClose={() => setConfirmRemoveItem(clearState)}
          itemName={confirmRemoveItem.name}
        />
        <div className={classes.sticky}>
          <Button
            onClick={() => {
              setFrontPageProducts(initialProducts);
              setHasMadeChanges(false);
            }}
            type="link"
            danger
            disabled={!hasMadeChanges}
          >
            Discard changes
          </Button>
          <Button type="primary" onClick={() => saveOrder()} disabled={!hasMadeChanges}>
            Save products
          </Button>
        </div>
      </div>
    </AdminContainer>
  );
};
export default FrontPageProducts;
