import classes from './AboutArea.module.scss';

export const AboutArea = (props: any) => {
  return (
    <footer className={classes.root}>
      <h1 className={classes.aboutRubrik}>ABOUT US</h1>
      <div className={classes.infoTextWrapper}>
        <div className={classes.infoText}>
          <h2>WE ARE</h2>
          <p>
            a Gothenburg based rental house delivering equipment to clients working with stills, light, camera, grip,
            audio and video. Put simply, we are The Professional’s Supplier.
          </p>
        </div>
        <div className={classes.infoText}>
          <h2>OUR MISSION</h2>
          <p>
            is to be customer focused, solution driven and always provide an unforgettable rental experience for each
            project that requires professional equipment.
          </p>
        </div>
        <div className={classes.infoText}>
          <h2>WE KNOW</h2>
          <p>
            that in our word-of-mouth industry, great solutions, reliable logistics and dedicated customer service is
            crucial.
          </p>
        </div>
      </div>
    </footer>
  );
};
