import { message, Button } from 'antd';
import { useMutate } from 'restful-react';
import { ICart } from '../CartComponent';
import { cartKeyWords } from '../Other/keywords';

interface IShareCart {
  cart: ICart;
  className: string;
  icon?: React.ReactNode;
}

export interface IGenerateCart {
  cartName: string;
  cartGroups: IGenerateCartGroups[];
  id: number;
  cartId: number;
}

interface IGenerateCartGroups {
  cartGroupName: string;
  products: IGenerateCartProducts[];
  id: number;
  cartId: number;
}

export interface IGenerateCartProducts {
  productId: number;
  eMaxNumber: string;
  productNumber: string;
  numberOfItems: number;
  image?: string;
  productName: string;
  cartGroupId?: number;
  mainProduct?: boolean;
}

export const ShareCart = (props: IShareCart) => {
  const { mutate: generateLink } = useMutate({
    verb: 'POST',
    path: `/shoppingcarts`,
  });

  const generateLinkToCart = async () => {
    // Convert cart to same type as backend

    // Adding ungroup to a cart group
    const cartGroupForUngrouped: IGenerateCartGroups = {
      cartGroupName: cartKeyWords.ungrouped,
      cartId: props.cart.id ?? 0,
      id: props.cart.id ?? 0,
      products: props.cart.ungroupedProducts.map((x) => {
        return {
          productId: x.id,
          eMaxNumber: x.eMaxNumber,
          image: x.imagePath,
          productNumber: x.productNumber,
          numberOfItems: x.numberOfItems ?? 0,
          productName: x.productName,
          cartGroupId: x.cartGroupId,
          isMainProduct: x.isMainProduct,
        };
      }),
    };

    // Creating a new object of cart groups as the cart group should
    // be in backend
    const cartGroups: IGenerateCartGroups[] = props.cart.cartGroups.map((x) => {
      return {
        cartGroupName: x.cartGroupName,
        id: x.id,
        cartId: x.cartId,
        products: x.products.map((x) => {
          return {
            productId: x.id,
            eMaxNumber: x.eMaxNumber,
            image: x.imagePath,
            productNumber: x.productNumber,
            numberOfItems: x.numberOfItems ?? 0,
            productName: x.productName,
            cartGroupId: x.cartGroupId,
          };
        }),
      };
    });

    // Creating a new cart object for backend,
    // Generating missing id:s
    const cart: IGenerateCart = {
      ...props.cart,
      cartName: `${Math.floor(Math.random() * 1000).toString()}`,
      cartGroups: [...cartGroups, cartGroupForUngrouped],
      cartId: props.cart.id ?? Math.floor(Math.random() * 1000),
      id: props.cart.id ?? Math.floor(Math.random() * 1000),
    };

    await generateLink(cart)
      .catch((error) => {
        console.error(error);
        message.error(
          "Could not share cart, please check that you don't have multiple items with same name in the same group.",
          10,
        );
      })
      .then(async (res) => {
        const cartUrl = `${window.location.origin}/view-cart/${res.url}`;
        message.success(
          <span>A link to this cart has been copied to your clipboard: {<a href={cartUrl}>{cartUrl}</a>}</span>,
        );
        await navigator.clipboard.writeText(cartUrl).catch((error) => {
          console.error(error);
          message.error('The link could not be copied to clipboard, please copy the link above.');
        });
      });
  };

  return (
    <Button className={props.className} onClick={() => generateLinkToCart()} icon={props.icon}>
      Share cart
    </Button>
  );
};
