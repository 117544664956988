import { Button } from 'antd';
import classes from './ContactInfoBox.module.scss';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';

export const ContactInfoBox = () => {
  return (
    <div className={classes.root}>
      <p>If you have any questions about renting or about the equpiment, don't hesitate to contact us!</p>
      <div className={classes.buttonGroup}>
        <Button
          href="tel:+4631212121"
          type="text"
          icon={<PhoneIcon fontSize="small" />}
          className={classes.contactButton}
        >
          031 - 21 21 21
        </Button>
        <Button
          href="mailto:booking@hyrmax.se"
          type="text"
          icon={<MailIcon fontSize="small" />}
          className={classes.contactButton}
        >
          booking@hyrmax.se
        </Button>
      </div>
    </div>
  );
};
