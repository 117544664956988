import { Button, Form, Input } from 'antd';
import { useState } from 'react';
import classes from './AddCategory.module.scss';
import { PlusOutlined } from '@ant-design/icons/lib/icons';

interface IAddCategory {
  buttonText: string;
  onEditName: (text: string) => void;
  isDisabled?: boolean;
}

interface IName {
  categoryName: string;
}

export const AddCategory = (props: IAddCategory) => {
  const [showForm, setShowForm] = useState(false);

  const updateName = (e: IName) => {
    setShowForm(false);
    props.onEditName(e.categoryName);
  };

  return (
    <div className={classes.root}>
      {!showForm ? (
        <div className="display-flex justify-right padding-y padding-x">
          <Button
            disabled={props.isDisabled}
            onClick={() => setShowForm(!showForm)}
            type="primary"
            icon={<PlusOutlined />}
          >
            {props.buttonText}
          </Button>
        </div>
      ) : (
        <div>
          <Form
            layout="vertical"
            className="padding-x "
            style={{
              borderBottom: '1px solid #efefef',
              paddingBottom: '12px',
            }}
            onFinish={(e: IName) => updateName(e)}
          >
            <div className={classes.addCategoryForm}>
              <Form.Item
                label="Category name"
                name="categoryName"
                rules={[{ required: true, message: 'Please enter a name' }]}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item>
                <Button onClick={() => setShowForm(!showForm)} type="link">
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};
