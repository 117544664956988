import { BrowserRouter, Routes as Switch, Route } from 'react-router-dom';
import { useGet } from 'restful-react';
import AttributesPage from '../Pages/Admin/Attributes/AttributesPage';
import CategoriesPage from '../Pages/Admin/Categories/CategoriesPage';
import FrontPageProducts from '../Pages/Admin/FrontPageProducts/FrontPageProducts';
import LoginPage from '../Pages/Admin/Login/LoginPage';
import CreateProductPage from '../Pages/Admin/Products/CreateProductPage';
import EditProductPage from '../Pages/Admin/Products/EditProduct/EditProductPage';
import ProductsOverview from '../Pages/Admin/Products/ProductsOverview';
import Users from '../Pages/Admin/Users/Users';
import { FourOFour } from '../Pages/Shop/Pages/FourOFour/FourOFour';
import { Cart } from '../Pages/Shop/Pages/Cart/Cart';
import { emptyCart } from '../Pages/Shop/Pages/Cart/Other/empty-cart';
import { cartKeyWords } from '../Pages/Shop/Pages/Cart/Other/keywords';
import { Categories } from '../Pages/Shop/Pages/Categories/Categories';
import { Contact } from '../Pages/Shop/Pages/Contact/Contact';
import { Home } from '../Pages/Shop/Pages/Home/Home';
import { ProductDetails } from '../Pages/Shop/Pages/ProductDetails/ProductDetails';
import SearchedProducts from '../Pages/Shop/Pages/SearchedProducts/SearchedProducts';
import { ViewCart } from '../Pages/Shop/Pages/ViewCart/ViewCart';
import { paths } from './paths';
import { ShopContainer } from '../Pages/Shop/Components/ShopContainer/ShopContainer';
import AudiovisualProducts from '../Pages/Admin/AudiovisualProducts/AudiovisualProducts';
import FilmEquipmentProducts from '../Pages/Admin/FilmEquipmentProducts/FilmEquipmentProducts';
import { CreatePDFLink } from '../Pages/Shop/Components/CreatePDF/CreatePDFLink';
import { Favourites } from '../Pages/Shop/Pages/Favourites/Favourites';
import { CookieInformation } from '../Pages/Shop/Pages/CookieInformation/CookieInformation';
import FaqPage from '../Pages/Admin/Faqs/FaqPage';
import { Faq } from '../Pages/Shop/Pages/Faqs/Faq';
import { ImageCarousel } from '../Pages/Admin/ImageCarousel/ImageCarousel';
import { setDynamicAttr } from '../Utils/setDynamicAttr';

export const Routes = () => {
  const loggedIn = localStorage.getItem('authenticated');

  // Create a cart in localStorage if no one exits
  if (!localStorage.getItem('cart')) {
    localStorage.setItem(cartKeyWords.localStorageKey, JSON.stringify(emptyCart));
  }
  const { data: categoriesWithProducts, loading } = useGet({
    path: '/published-categories-with-products',
    resolve: (data) => {
      return setDynamicAttr(data.categoriesWithProductsResult, 'products', 'mainProduct', true);
    },
  });

  return (
    <BrowserRouter>
      <Switch>
        <>
          {loggedIn ? (
            <>
              <Route path={paths.adminCategories} element={<CategoriesPage />} />
              <Route path={paths.adminFrontPageProducts} element={<FrontPageProducts />} />
              <Route path={paths.adminFilmEquipmentProducts} element={<FilmEquipmentProducts />} />
              <Route path={paths.adminAudiovisualProducts} element={<AudiovisualProducts />} />
              <Route path={paths.adminAttributes} element={<AttributesPage />} />
              <Route path={paths.adminFaq} element={<FaqPage />} />
              <Route path={paths.adminUsers} element={<Users />} />
              <Route path={paths.adminImageCarousel} element={<ImageCarousel />} />
              <Route path={paths.adminProductsOverview} element={<ProductsOverview />} />
              <Route path={paths.adminCreateProduct} element={<CreateProductPage />} />
              <Route path={paths.adminProductId} element={<EditProductPage />} />
              <Route path={paths.adminRedirect} element={<ProductsOverview />} />
            </>
          ) : (
            <Route path={paths.adminRedirect} element={<LoginPage />} />
          )}
        </>
        <Route path={'/pdf'} element={<CreatePDFLink />} />
        <Route
          path={'/*'}
          element={
            <ShopContainer data={categoriesWithProducts} loading={loading}>
              <Switch>
                <Route path={paths.home} element={<Home data={categoriesWithProducts} loading={loading} />} />
                <Route
                  path={paths.categories}
                  element={<Categories data={categoriesWithProducts} loading={loading} />}
                />
                <Route path={paths.search} element={<SearchedProducts />} />
                <Route path={paths.products} element={<ProductDetails />} />
                <Route path={paths.cart} element={<Cart />} />
                <Route path={paths.viewCart} element={<ViewCart />} />
                <Route path={paths.contact} element={<Contact />} />
                <Route path={paths.faq} element={<Faq />} />
                <Route path={paths.favourites} element={<Favourites />} />
                <Route path={paths.cookieInformation} element={<CookieInformation />} />
                <Route path={paths.error} element={<FourOFour />} />
                <Route path={paths.home} element={<Home data={categoriesWithProducts} loading={loading} />} />
                <Route
                  path={paths.categories}
                  element={<Categories data={categoriesWithProducts} loading={loading} />}
                />
                <Route path={paths.search} element={<SearchedProducts />} />
                <Route path={paths.products} element={<ProductDetails />} />
                <Route path={paths.cart} element={<Cart />} />
                <Route path={paths.viewCart} element={<ViewCart />} />
                <Route path={paths.contact} element={<Contact />} />
                <Route path={paths.faq} element={<Faq />} />
                <Route path={paths.favourites} element={<Favourites />} />
                <Route path={paths.cookieInformation} element={<CookieInformation />} />
                <Route path={paths.error} element={<FourOFour />} />
              </Switch>
            </ShopContainer>
          }
        />
      </Switch>
    </BrowserRouter>
  );
};
