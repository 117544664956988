import Tree, { DataNode } from 'antd/lib/tree';
import React, { Key } from 'react';
import classes from './StyledTree.module.scss';

export interface ITree {
  data?: DataNode[];
  onSelect: (key: any) => void;
  onExpand: (key: any) => void;
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  selectedKeys: Key[];
  expandedKeys?: Key[];
}

export const StyledTree = (props: ITree) => {
  return (
    <Tree
      treeData={props.data}
      onSelect={props.onSelect}
      onExpand={props.onExpand}
      expandedKeys={props.expandedKeys}
      switcherIcon={props.icon}
      blockNode
      className={classes.tree}
      selectedKeys={props.selectedKeys}
      defaultSelectedKeys={props.selectedKeys}
      autoExpandParent={true}
      defaultExpandedKeys={props.selectedKeys}
      defaultExpandParent={true}
    />
  );
};
